import { useCallback } from 'react';
import { useHttp } from './httpHelpers';
import useSesionVMStore from '../Contexts/SesionVM/sesionVM';
import useSocketEventStore from '../Contexts/SocketEvent/socketEvent';
import { config } from '../Config/config';

function useCancelarSesion(navigate) {

    const http = useHttp();
    const { setSessionID } = useSesionVMStore();
    const { setMessage, setSale } = useSocketEventStore();

    const cancelar = useCallback(async () => {
        try {
            await http.POST(config.get('baseURL') + 'vm/cancelar');
            setSessionID();
            setMessage();
            setSale();
        } catch (error) {
            console.log("error cancelando sesión con VM",error)
            navigate('/error');
        }
    }, [navigate]);

    return cancelar;
}

export default useCancelarSesion;