import {  useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import iconoBilleteraPlomo from '../Componentes/iconoBilleteraPlomo.svg'
import useNavbarStore from '../Contexts/NavBar/navbar';
import useSesionVMStore from '../Contexts/SesionVM/sesionVM';
import { useInfoUsuario } from '../Hooks/InfoUsuario';
import { useCerrarSesion } from '../Hooks/cerrarSesion';

function CuentaUsuario() {

    const { authorization } = useSesionVMStore();
    const { setNavBarType } = useNavbarStore();
    const navigate = useNavigate();
    const { cerrarSesion }  = useCerrarSesion();
    const { data: infoUsuario } = useInfoUsuario();

    useEffect(() => {
        setNavBarType(1)
        if(!authorization){
            navigate('/');
        }
    }, [])

    return (
        <div className="CuentaUsuario">
            <div className='mx-4 pt-5'>
                {/* <div className='contenedor-img'>
                    <div className='img-cuenta'>
                        <div className='editar-img-cuenta'><img src={pen} className='img-pen'/></div>
                    </div>
                </div> */}
                <h1 className='nombre-cuenta'>{infoUsuario ? infoUsuario.data.names+' '+infoUsuario.data.lastname:null}</h1>
                <h5 className='editar-cuenta' onClick={() => navigate('/editar-cuenta')}>Editar cuenta</h5>
                <div className='menu-cuenta mx-3'>
                    {/* <div className='elemento-cuenta' onClick={() => navigate('/historial-pago')}>
                        <div className='row'>
                            <img src={iconoBilleteraPlomo} className='col-3 icono-cuenta' />
                            <span className='col-9' id='span-menu-cuenta'>Tus Pedidos</span>
                        </div>
                    </div> */}
                    <div className='elemento-cuenta' onClick={() => navigate('/metodos-pago')}>
                        <div className='row'>
                            <img src={iconoBilleteraPlomo} className='col-3 icono-cuenta' />
                            <span className='col-9' id='span-menu-cuenta'>Metodos de Pago</span>
                        </div>
                    </div>
                    <div className='elemento-cuenta' onClick={() => navigate('/lista-ayuda')}>
                        <div className='row'>
                            <img src={iconoBilleteraPlomo} className='col-3 icono-cuenta' />
                            <span className='col-9' id='span-menu-cuenta'>Ayuda</span>
                        </div>
                    </div>
                    <div className='elemento-cuenta' onClick={() => cerrarSesion()}>
                        <div className='row'>
                            <img src={iconoBilleteraPlomo} className='col-3 icono-cuenta'/>
                            <span className='col-9' id='span-menu-cuenta'>Cerrar Sesión</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CuentaUsuario;