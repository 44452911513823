import '../App.css';
import iconoOk from '../Componentes/iconoOk.svg'

function RestablecerContraseña2() {
    return (
        <div className="RestablecerContraseña2 background-color">
            <div className='container'>
                <div className='row mt-5 pt-5'>
                    <img src={iconoOk} alt="correcto" width="100" height="100" className='col-12'/>
                    <div className='text-center mt-4 col-12'>
                        <h1 className='titulo-inicio-Sesion'>¡Enviado!</h1>
                    </div>
                    <div className='text-center col-12'>
                        <h2 className='pregunta'>Revisa tu correo electrónico</h2>
                    </div>
                    <div className='text-center col-12'>
                        <p className='enviado-texto'>Te hemos enviado un link para restablecer tu contraseña</p>
                    </div>
                    <div className='text-center col-12 mt-5 pt-5'>
                        <span className='correo-no-recibido'>¿No has recibido el correo?</span>
                    </div>
                    <div className='text-center col-12 mb-5'>
                        <span className='correo-no-recibido underline'>Reintentar</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RestablecerContraseña2;