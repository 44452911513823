import { MicrosoftLoginButton } from 'react-social-login-buttons';
import { config } from '../../Config/config';

function MicrosoftLoginComponent() {

    const clientId = config.get('microsoftClientId');
    const callBack = config.get('microsoftCallback');
    const hostBackend = config.get('hostBackend');
    
    const loginMicrosoft = () => {
        window.location.href = `
        https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
        client_id=${clientId}
        &response_type=code
        &redirect_uri=${encodeURIComponent(hostBackend + callBack)}
        &response_mode=query
        &scope=openid,profile,email,Mail.Read,User.Read
        `;
    }

    return (
        <>
            <MicrosoftLoginButton onClick={() => loginMicrosoft()} className='text-center'>
                <span>Cuenta de Microsoft</span>
            </MicrosoftLoginButton>
        </>
    );
}

export default MicrosoftLoginComponent;