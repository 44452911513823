import '../App.css';
import { useEffect } from 'react';
import flecha from '../Componentes/flecha.svg'
import NavbarSvcGris from '../Componentes/navbarSvcGris';
import iconoOK from '../Componentes/iconoOk.svg'
import { Link } from 'react-router-dom';
import useNavbarStore from '../Contexts/NavBar/navbar';

function CalificacionEnviada() {

    const { setNavBarType } = useNavbarStore();
    useEffect(() => {
        setNavBarType(4)
    },[])

    return (
        <>
            <NavbarSvcGris />
            <div className="CalificacionEnviada">
                <div className='container text-center'>
                    <img src={iconoOK} className='mt-3' />
                    <h1 className='titulo-calificacion-enviada text-center mt-4'>¡Enviado!</h1>
                    <h2 className='calificar-experiencia text-center mt-4'>
                        Muchas gracias <br /> por tu opinión
                    </h2>
                    <h3 className='subtitulo-califica-experiencia text-center my-3'>
                        Seguiremos trabajando <br /> para mejorar tu experiencia <br /> de compra
                    </h3>
                </div>
                <div className='footer-calificar2'>
                    <div className='row pt-4 ms-1 me-1 mb-4 text-center'>
                        <div className='col-12'>
                            <Link to="/" className='text-decoration-none text-white'>
                                <button type="button" className="btn volver-comprar-calificacion">
                                    Volver a comprar
                                </button>
                            </Link>
                        </div>
                        <div className='col-12'>
                            <Link to="/" className='text-decoration-none text-white'>
                                <button type="submit" className="btn volver-inicio-calificacion">
                                    <img src={flecha} className='pe-2 pb-1' />Volver al inicio
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CalificacionEnviada;
