import React, { useState, useRef, useEffect } from "react";
import useSesionVMStore from "../../Contexts/SesionVM/sesionVM";
import useErrorStore from "../../Contexts/Error/error";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useHttp } from "../../Hooks/httpHelpers";
import { useInfoUsuario } from "../../Hooks/InfoUsuario";
import { config } from "../../Config/config";

import "../../App.css";
import iconoX from "../iconoX.svg";

const ChargeMoney = ({ isOpen, toggle, entryData, responseData }) => {
    const { authorization, setInfoUsuario } = useSesionVMStore();
    const { setError } = useErrorStore();
    const http = useHttp();
    const userInfoResponse = useInfoUsuario();
    const infoUser = userInfoResponse?.data?.data;

    const navigate = useNavigate();
    
    const { register: registerDeposit, handleSubmit: submitDeposit, formState: { errors: errorsDeposit }, watch, getValues, setValue } = useForm();

    const [partialDepositForm, setPartialDepositForm] = useState({ methodId: null, cardId: null});
    
    const inputRef = useRef(null);
    
    const [statusModal, setStatusModal] = useState({
        loading: false,
        buttonSubmit: "Ir a pagar",
        error: undefined,
        isLoading: false
    });
    useEffect(() => {
        if(isOpen) {
            inputRef.current.click();
            findUserWallet();
        }
    }, [isOpen]);

    const handleInputDeposit = (event) => {
        const [name, value] = event.target;
        setPartialDepositForm({...partialDepositForm, [name]: value });
        setValue(name, value);
    }

    const changePartialDepositForm = (methodId, cardId) => {
        setPartialDepositForm({ methodId, cardId});
        setValue("paymentMethodId", methodId);
        setValue("cardId", cardId);    
    };

    if((entryData || {}).amount && !getValues('amount')) setValue('amount', entryData.amount);

    const [wallet, setWallet] = useState({ state: false, error: false, balance: null });
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [cards, setCards] = useState([]);

    const currencyFormat = (value) => {
        if (typeof value !== "number") return "$0";

        return new Intl.NumberFormat("es-CL", {
            style: "currency",
            currency: "CLP",
            minimumFractionDigits: 0,
        }).format(value);
    };

    const getUserInfo = async () => {
        try {
            const token = localStorage.getItem('authorization');
            if(!token) throw "Error Sesion";
            const { status, data } = await http.GET(config.get('baseURL') + 'usuarios/info');
            if (status !== 200) throw { message: "Servicio no disponible" };
            setInfoUsuario(data);
            return data;
        } catch (error) {
            setWallet({ state: false, error: true, balance: null });
            return undefined;
        } 
    };

    const findUserWallet = async () => {
        try {
            setStatusModal({ ...statusModal, loading: true});

            let user; 
            if(!(infoUser || {}).id) { 
                user = await getUserInfo();

            } else {
                user = infoUser;
            }

            const { status: statusFindWallet, data: dataFindWallet } = await http.POST(config.get("baseURL") + "wallet/exist", { userId: user.id });
            if (statusFindWallet !== 200) throw "Servicio no disponible";

            if (typeof (dataFindWallet || {}).walletId === 'number') {

                const { status: statusPaymentMethods, data: dataPaymentMethods } = await http.POST(config.get("baseURL") + "wallet/payment-methods", { userWalletId: dataFindWallet?.walletId });
                if (statusPaymentMethods !== 200) throw "Servicio no disponible";

                setPaymentMethods((((dataPaymentMethods || {}).data || {}).data || {}).paymentMethods || []);
                setCards((((dataPaymentMethods || {}).data || {}).data || {}).cards || []);
            }
            setWallet({
                state: (dataFindWallet || {}).walletId ? true : false,
                walletId: dataFindWallet?.walletId,
                error: false,
                balance: dataFindWallet?.balance,
            });
        } catch (error) {
            setWallet({ state: false, error: true, balance: null });
            responseData(error);
        } finally {
            setStatusModal({...statusModal, loading: false});
        }
    };

    const initDeposit = async () => {
        try {
            setStatusModal({...statusModal, loading: true});
            const params = getValues();
            const { status, data } =  await http.POST(config.get('baseURL') + 'wallet/init-deposit', 
            {
                "amount": params.amount,
                "methodId": params.paymentMethodId,
                "paymentCardId": params.cardId,
                "userId": infoUser.id,
                "metadata": {
                    backURL: entryData.returnURL || '',
                    buttonMsg: "Volver a pagos"
                }
            });
            toggle();
            if (status !== 200) throw "Servicio no disponible";
            return window.location.href = data?.redirect;
        } catch (error) {
            responseData(error);
        } finally {
            setStatusModal({...statusModal, loading: false, buttonSubmit: "Pagando...", isLoading: true});
        }
    };

    const changeAmount = (amount) => setValue("amount", amount);

    return (
        <>
            <button type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#exampleModal" ref={inputRef}>
                Open deposit wallet modal
            </button>

            <div className="modal fade" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title titulo-modal" id="depositLabel">
                                Cargar Saldo Wallet
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={toggle}></button>
                        </div>
                        <div className="modal-body">
                            {
                                statusModal.loading ? ( 
                                    <div class="my-5 d-flex justify-content-center">
                                        <div class="spinner-border" role="status" style={{width: "3rem", height: "3rem", color: "#FE5E41"}}>
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                ) : (
                                    statusModal.error ? (
                                        <>
                                            <img
                                                src={iconoX}
                                                width="50"
                                                alt=""
                                                className="my-5 mx-auto d-block"
                                            />
                                            <p
                                                className="font-svc-third"
                                                style={{ textAlign: "justify" }}
                                            >
                                                {statusModal.error}
                                            </p>
                                        </>
                                    ) : (
                                        <form onSubmit={submitDeposit(initDeposit)}>
                                    <div className="mb-4">
                                        <label htmlFor="amount" className="form-label label-svc font-medium-svc">
                                            Monto a cargar
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control input-svc font-medium-svc"
                                            id="amount"
                                            aria-describedby="amount"
                                            placeholder="Ingrese monto"
                                            name="amount"
                                            {...registerDeposit("amount", {
                                                required: "Campo requerido.",
                                                pattern: {
                                                    value: /^([1-9]\d{3,}|\d{4,})$/,
                                                    message: "Monto minimo $1000.",
                                                },
                                            })}
                                        />
                                        {errorsDeposit.amount && <small className="form-input-error"> {errorsDeposit.amount.message} </small> }
                                    </div>
                                    <div className="mb-4 d-flex justify-content-around ">
                                        <button
                                            type="button"
                                            className="btn-svc-primary btn-svc-small"
                                            onClick={() => changeAmount(3000)}
                                        >
                                            {currencyFormat(3000)}
                                        </button>
                                        <button
                                            type="button"
                                            className="btn-svc-primary btn-svc-small"
                                            onClick={() => changeAmount(5000)}
                                        >
                                            {currencyFormat(5000)}
                                        </button>
                                        <button
                                            type="button"
                                            className="btn-svc-primary btn-svc-small"
                                            onClick={() => changeAmount(10000)}
                                        >
                                            {currencyFormat(10000)}
                                        </button>
                                    </div>
                                    <div className="mb-4">
                                        <label
                                            htmlFor="amount"
                                            className="form-label label-svc font-medium-svc"
                                        >
                                            Metódo de pago
                                        </label>
                                        {paymentMethods.length === 0 && (
                                            <div className="mb-3 d-flex flex-column justify-content-center px-2 py-auto radio-svc" style={{backgroundColor: "#F8F8F8"}}>
                                                <div className="form-check d-flex aligns-items-center justify-content-between px-3">
                                                    <div className="d-flex justify-content-center align-items-center gap-3">
                                                        <img src={iconoX}/>
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="opcion1"
                                                            style={{
                                                                fontSize: "18px",
                                                                lineHeight: "30px",
                                                                fontWeight: "700",
                                                                color: "#5E5E5E",
                                                            }}
                                                        >
                                                            No disponible
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {paymentMethods.map((method, index) => {
                                            if((method.name).toLowerCase() === 'oneclick') {
                                                return (
                                                    <div key={index}>
                                                        {cards.map((card, index) => {
                                                                return (
                                                                    <div key={index}>
                                                                        <div className={`mb-3 d-flex flex-column justify-content-center px-2 py-auto radio-svc ${partialDepositForm.cardId == card.id && partialDepositForm.methodId == method.id ? "radio-svc-checked" : "" }`} onClick={() => changePartialDepositForm(method.id, card.id)}>
                                                                            <div className="form-check d-flex aligns-items-center justify-content-between px-3">
                                                                                <div className="d-flex justify-content-center align-items-center gap-3">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none" className="icon-radio">
                                                                                        <path d="M19.8 4.44444V2.22222C19.8 0.996667 18.8133 0 17.6 0H3.3C1.4806 0 0 1.49556 0 3.33333V16.6667C0 19.1122 1.9734 20 3.3 20H19.8C21.0133 20 22 19.0033 22 17.7778V6.66667C22 5.44111 21.0133 4.44444 19.8 4.44444ZM17.6 14.4444H15.4V10H17.6V14.4444ZM3.3 4.44444C3.01677 4.43165 2.74935 4.30899 2.5534 4.10202C2.35746 3.89505 2.24811 3.61971 2.24811 3.33333C2.24811 3.04696 2.35746 2.77162 2.5534 2.56465C2.74935 2.35767 3.01677 2.23502 3.3 2.22222H17.6V4.44444H3.3Z" fill="#999999"/>
                                                                                    </svg>
                                                                                    <label
                                                                                        className="form-check-label"
                                                                                        htmlFor="opcion1"
                                                                                        style={{
                                                                                            fontSize: "18px",
                                                                                            lineHeight: "30px",
                                                                                            fontWeight: "700",
                                                                                            color: "#5E5E5E",
                                                                                        }}
                                                                                    >
                                                                                        {card.payment_type} {(card.card_info).slice(-6)}
                                                                                    </label>
                                                                                </div>
                                                                                <input
                                                                                    className="form-check-input my-auto"
                                                                                    type="radio"
                                                                                    name="cardId"
                                                                                    value={card.id}  
                                                                                    checked={partialDepositForm.cardId == card.id && partialDepositForm.methodId == method.id}
                                                                                    onChange={handleInputDeposit}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )

                                                        })}
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div key={index}>
                                                        <div className={`mb-3 d-flex flex-column justify-content-center px-2 py-auto radio-svc ${partialDepositForm.methodId == method.id && partialDepositForm.cardId == null ? "radio-svc-checked" : "" }`} onClick={() => changePartialDepositForm(method.id, null)}>
                                                            <div className="form-check d-flex aligns-items-center justify-content-between px-3">
                                                                <div className="d-flex justify-content-center align-items-center gap-3">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none" className="icon-radio">
                                                                        <path d="M19.8 4.44444V2.22222C19.8 0.996667 18.8133 0 17.6 0H3.3C1.4806 0 0 1.49556 0 3.33333V16.6667C0 19.1122 1.9734 20 3.3 20H19.8C21.0133 20 22 19.0033 22 17.7778V6.66667C22 5.44111 21.0133 4.44444 19.8 4.44444ZM17.6 14.4444H15.4V10H17.6V14.4444ZM3.3 4.44444C3.01677 4.43165 2.74935 4.30899 2.5534 4.10202C2.35746 3.89505 2.24811 3.61971 2.24811 3.33333C2.24811 3.04696 2.35746 2.77162 2.5534 2.56465C2.74935 2.35767 3.01677 2.23502 3.3 2.22222H17.6V4.44444H3.3Z" fill="#999999"/>
                                                                    </svg>
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="opcion1"
                                                                        style={{
                                                                            fontSize: "18px",
                                                                            lineHeight: "30px",
                                                                            fontWeight: "700",
                                                                            color: "#5E5E5E",
                                                                        }}
                                                                    >
                                                                        {method.name}
                                                                    </label>
                                                                </div>
                                                                <input
                                                                    className="input-svc-payments form-check-input my-auto"
                                                                    type="radio"
                                                                    name="paymentMethodId"
                                                                    value={method.id}  
                                                                    checked={partialDepositForm.methodId == method.id && partialDepositForm.cardId == null}
                                                                    onChange={handleInputDeposit}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}
                                                                        
                                        <input
                                            type="text"
                                            aria-hidden="true"
                                            className="form-control input-svc font-medium-svc"
                                            style={{ display: "none"}}
                                            aria-describedby="paymentMethodId"
                                            
                                            name="paymentMethodId"
                                            {...registerDeposit("paymentMethodId", {
                                                required: "Seleccione un metódo de pago"
                                            })}
                                        />
                                        {errorsDeposit.paymentMethodId && <small className="form-input-error"> {errorsDeposit.paymentMethodId.message} </small> }

                                        <input
                                            type="text"
                                            aria-hidden="true"
                                            style={{ display: "none"}}
                                            className="form-control input-svc font-medium-svc"
                                            aria-describedby="cardId"
                                            name="cardId"
                                            {...registerDeposit("cardId", {
                                                required: false
                                            })}
                                        />
                                    </div>
                                    <div className="mt-5 d-flex justify-content-end gap-4">
                                        <button type="reset" className="btn-font btn-svc-secondary" data-bs-dismiss="modal" onClick={toggle}>
                                            Volver
                                        </button>
                                        <input type="submit" className="btn-font btn-svc-primary boton-naranja" disabled={paymentMethods.length === 0 || statusModal?.isLoading} value={statusModal?.buttonSubmit || "Ir a pagar"}/>
                                    </div>
                                </form>
                                    )
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChargeMoney;