import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import iconoLupa from '../Componentes/iconoLupa.svg'
import iconoBilleteraPlomo from '../Componentes/iconoBilleteraPlomo.svg'
import useNavbarStore from '../Contexts/NavBar/navbar';
import useInfoAyudaStore from '../Contexts/InfoAyuda/infoAyuda';
import { useHttp } from '../Hooks/httpHelpers';
import { config } from '../Config/config';

function Ayuda() {

    const { setInfoAyuda } = useInfoAyudaStore();
    const { setNavBarType } = useNavbarStore();
    const http = useHttp();
    const [buscar, setBuscar] = useState("")
    const buscador = (e) => {
        setBuscar(e.target.value)
    }


    const [ayuda, setAyuda] = useState();

    let resultados = ayuda?.map((temas) => {
        return {
            ...temas,
            temasAyuda: temas.temasAyuda.filter((tema) => tema.nombreTema.toLowerCase().includes(buscar.toLocaleLowerCase()) || tema.contenidoTema.toLowerCase().includes(buscar.toLocaleLowerCase()))
        }
    }).filter(tema => tema.temasAyuda.length>0)

    function agruparPorCategoria(data) {
        const obj = {};
        for (const { category, title, message} of data) {
          if (!obj[category]) {
            obj[category] = { categoriaAyuda: category, temasAyuda: []};
          }
          obj[category].temasAyuda.push({nombreTema: title, contenidoTema: message});
        }
        return Object.values(obj);
    }

    async function obtenerInfoAyuda() {
        let infoAyuda = await http.GET(config.get('baseURL') + 'comentarios/mensajes-ayuda');
        if(infoAyuda.data.success === true){
            let agruparInfo = agruparPorCategoria(infoAyuda.data.body);
            setAyuda(agruparInfo);
        } else {
            console.log(infoAyuda);
        }
    }

    useEffect(() => {
        setNavBarType(1)
        obtenerInfoAyuda();
    },[])

    return (
        <div className="Ayuda">
            <div className='mx-4'>
                <h1 className='titulo-ayuda pt-4'>Ayuda</h1>
                <div className="input-group mt-3 mb-4" id='container-buscador'>
                    <span className="input-group-text border-0" id="span-input-ayuda"><img src={iconoLupa} /></span>
                    <input type="text" value={buscar} onChange={buscador} className="form-control border-0" id='input-ayuda' placeholder="¿En qué te podemos ayudar?" aria-label="buscador" aria-describedby="input-ayuda" />
                </div>
                <div className='resultados-busqueda'>
                    {!buscar ? '' : resultados?.map((resultado, index) => {
                        return (
                            resultado.temasAyuda.map((tema, index) => {
                                return (
                                    <div key={index} onClick={() => setInfoAyuda(tema)}>
                                        <Link to="/info-ayuda" className='text-decoration-none text-white'>
                                            <h2 className='my-2 buscar-por-tema text-decoration-underline' key={index} >
                                                {tema.nombreTema}
                                            </h2>
                                        </Link>
                                    </div>
                                )
                            })
                        )
                    })}
                </div>
                {resultados && resultados.length==0 && buscar ? <span className='span-input-ayuda'>Lo sentimos, no hubo resultados relacionados a su búsqueda.</span> : '' }
                <h2 className='buscar-por-tema mt-4'>Busca por tema </h2>
                {!ayuda ? <div className='buscar-por-tema fs-6 mt-4 text-center'>No su fue posible obtener información de ayuda</div>: null}
                <div className='acordion-ayuda'>
                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        {ayuda ? ayuda.map((info, index) => {
                            return (
                                <div className="accordion-item " key={index}>
                                    <h2 className="accordion-header" id={"flush-headingOne" + index}>
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapseOne" + index} aria-expanded="false" aria-controls={"flush-collapseOne" + index} id='boton-acordeon'>
                                            <img src={iconoBilleteraPlomo} /> <span id='span-ayuda'>{info.categoriaAyuda}</span>
                                        </button>
                                    </h2>
                                    <div id={"flush-collapseOne" + index} className="accordion-collapse collapse" aria-labelledby={"flush-headingOne" + index} data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body py-0">
                                            <ol className='listado-temas'>
                                                {info.temasAyuda.map((tema, index) => {
                                                    return (
                                                        <li key={index} className='my-3' onClick={() => setInfoAyuda(tema)}>
                                                            <Link to="/info-ayuda" className='text-decoration-none text-secondary'>
                                                                {tema.nombreTema}
                                                            </Link>
                                                        </li>
                                                    )
                                                })}
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            )
                        }): null}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Ayuda;