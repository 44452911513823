const convertirFecha = (fecha) => {
    if (!fecha) return '';
  
    try {
      const date = new Date(fecha);
  
      if (isNaN(date.getTime())) {
        throw new Error('Fecha no válida');
      }
  
      date.setUTCMinutes(date.getUTCMinutes() - 180);
  
      const dia = date.getUTCDate().toString().padStart(2, '0');
      const mes = (date.getUTCMonth() + 1).toString().padStart(2, '0');
      const anio = date.getUTCFullYear().toString();
  
      return `${dia}-${mes}-${anio}`;
    } catch (error) {
      console.error(error);
      return '';
    }
  };

const compararObjetos = (FormatoSession, ObjetoRecibido) => {
    return !Object.keys(FormatoSession).some(key => {
        const receivedKeys = Object.keys(ObjetoRecibido).map(k => k.toLowerCase())
        return !receivedKeys.includes(key.toLowerCase())
    });
}

export { 
    convertirFecha,
    compararObjetos
};