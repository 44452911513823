import { useEffect } from 'react';
import '../App.css';
import iconoOk from '../Componentes/iconoOk.svg'
import Spinner from '../Componentes/spinner';
import { useNavigate } from 'react-router-dom'
import useNavbarStore from '../Contexts/NavBar/navbar';
import useCurrentStateStore from '../Contexts/CurrentState/currentState';
import { useHttp } from '../Hooks/httpHelpers';
import useErrorStore from '../Contexts/Error/error';
import { config } from '../Config/config';

function ValidarEmail() {
    const { setNavBarType } = useNavbarStore();
    const { ok, setOk } = useCurrentStateStore();
    const { setError } = useErrorStore();
    const navigate = useNavigate();
    const http = useHttp();

    async function obtenerDatosEmail() {
        try {
            let url = window.location.href
            if(url.includes('tk=') && url.includes('ui=')) {
                let ui = url.split('?').pop().split('&').find(param => param.startsWith('ui=')).split('=').pop();
                let tk = url.split('?').pop().split('&').find(param => param.startsWith('tk=')).split('=').pop();
                console.log({tk: tk,
                ui: ui})
                let validarEmail = await http.GET(config.get('baseURL') + `usuarios/validaremail?tk=${tk}&ui=${ui}`);
                console.log(validarEmail)
                if (validarEmail.status===200){
                    setOk(true);
                    setTimeout(function() {navigate('/')}, 3000);
                } else {
                    setError(validarEmail.response.data);
                    navigate('/error')
                }
            } else {
                navigate('/')
            }
        } catch (error) {
            navigate('/error')
        }
    }

    useEffect(() => {
        setNavBarType(2);
        obtenerDatosEmail();
    }, [])

    return (
        <div className={`${ok ? "background-white" : "background-naranja"}`}>
            <div className='container text-center'>
                <div className={`col-12 contenedor-img-ok ${ok ? "" : "d-none"}`}>
                    <img src={iconoOk} width="100" height="100" className={`${ok ? "" : "d-none"}`} />
                </div>
                <h1 className={`titulo-conectando mx-auto ${ok ? "transicion-gris" : "transicion-naranja"}`}>
                    {ok ? 'Email Verificado' : 'Cargando'}
                </h1>
                <h2 className={`subtitulo-conectando ${ok ? "d-none" : ""}`}>
                    Espere un momento...
                </h2>
                <div className={`pt-4 loader-container ${ok ? "d-none" : ""}`}>
                    <Spinner />
                </div>
                <div className='container botones-conectando'>
                    <div className={`row ${ok ? "d-none" : ""}`}>
                        <div className='col-6'>
                            <button type="button" className="btn btn-light" id='font-conectando' onClick={() => navigate('/reportar-error')}>Reportar error</button>
                        </div>
                        <div className='col-6'>
                            <button type="button" className="btn text-white ms-2 p-0" id='font-conectando2'>Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default ValidarEmail;