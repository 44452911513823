import '../App.css';
import { useEffect, useState } from 'react';
import flechaNaranja from '../Componentes/flechaNaranja.svg'
import { useForm } from "react-hook-form";
import NavbarSvcGris from '../Componentes/navbarSvcGris';
import { useNavigate, Link } from 'react-router-dom'
import iconoX from '../Componentes/iconoX.svg'
import ReCaptchaComponent from '../Componentes/recaptcha';
import useNavbarStore from '../Contexts/NavBar/navbar';
import { useHttp } from '../Hooks/httpHelpers';
import useSesionVMStore from '../Contexts/SesionVM/sesionVM';
import useReCaptchaStore from '../Contexts/reCaptcha/reCaptcha';
import { config } from '../Config/config';

function CalificarExperiencia() {

    const { sessionID, authorization, infoUsuario } = useSesionVMStore();
    const { token } = useReCaptchaStore();
    const http = useHttp();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { setNavBarType } = useNavbarStore();
    async function processSubmit(data) {
        let enviarCalificacion = await http.POST(config.get('baseURL') + 'comentarios/calificar-experiencia', infoCalificacion(data));
        if(enviarCalificacion.data.status === true) {
            navigate('/calificacion-enviada');
        } else {
            navigate('/')
        }
    }

    const [calificacion, setCalificacion] = useState({
        seleccionado: false,
        notaCalificacion: [
            {
                calificacion: 1
            },
            {
                calificacion: 2
            },
            {
                calificacion: 3
            },
            {
                calificacion: 4
            }
        ]
    })

    const infoCalificacion = (data) => {
        let calificacion = {infoUser: {}}
        calificacion.recaptcha = token;
        calificacion.score = data.score;
        calificacion.sessionId = sessionID.sessionId
        if(infoUsuario) {
            calificacion.userId = infoUsuario.id;
            calificacion.infoUser.names = infoUsuario.names;
            calificacion.infoUser.lastname = infoUsuario.lastname;
            calificacion.infoUser.email = infoUsuario.email;
            calificacion.infoUser.phone = infoUsuario.phone;
        }

        return calificacion
    }

    function cambiarSeleccionado(index) {
        setCalificacion({ ...calificacion, seleccionado: calificacion.notaCalificacion[index] })
    }

    async function verificarSesionExperience() {
        let estadoSesion = await http.POST(config.get('baseURL') + 'vm/get-session')
        if (estadoSesion.data.session.state.status === 'ended' && estadoSesion.data.experience === true){
            navigate('/')
        }
    }

    
    useEffect(() => {
        setNavBarType(4)
        verificarSesionExperience();
    },[])

    return (
        <>
            <NavbarSvcGris />
            <div className="CalificarExperiencia">
                <ReCaptchaComponent/>
                <form onSubmit={handleSubmit(processSubmit)}>
                    <div className='container'>
                        <h1 className='titulo-califica-experiencia text-center mt-2'>Califica tu <br /> experiencia</h1>
                        <h3 className='subtitulo-califica-experiencia text-center my-3'>Tu opinión es muy importante <br />para nosotros</h3>
                        <div className='form-califiacion'>
                            {!authorization ? <div className='row mx-3 mt-4'>
                                <span className='input-nueva-contraseña p-0 col-12'>Correo Electrónico</span>
                                <div className='input-group input-con-borde bg-white col-12'>
                                    <input type="text" className="input-con-borde2 border-0 col-11" placeholder='ejemplo@correo.com' {...register("email", { required: true, pattern: /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/ })} />
                                    {errors.email?.type === "required" && (
                                        <span className="input-group-text p-0 m-auto col-1 border-0 bg-white"><img src={iconoX} alt='icono error' className='bg-white' /> </span>
                                    )}
                                </div>
                                {errors.email?.type === "required" && (
                                    <span className='input-nueva-contraseña2 p-0 col-12'>Correo electrónico es requerido</span>
                                )}
                                {errors.email?.type === "pattern" && (
                                    <span className='input-nueva-contraseña2 p-0 col-12'>Correo electrónico no valido</span>
                                )}
                            </div> : null}
                            <h2 className='calificar-experiencia text-center mt-4'>
                                Del 1 al 4,<br /> ¿Cómo calificarías tu experiencia de compra?
                            </h2>
                            <div className='row mx-auto mt-4'>
                                {calificacion.notaCalificacion.map((nota, index) => {
                                    return (
                                        <div className="form-check col-3 px-2" key={index}>
                                            <input type="radio" className="btn-check" id={index} value={nota.calificacion} autoComplete="off" name="radioCalificacion" {...register("score", { required: true })} />
                                            <label className={`btn calificacion py-3 ${calificacion.notaCalificacion[index] === calificacion.seleccionado ? 'calificacion-seleccionada' : 'calificaion'}`} htmlFor={index} onClick={() => cambiarSeleccionado(index)}>
                                                {nota.calificacion}
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="mb-3 mx-3 p-0 mt-4">
                                <label htmlFor="razon-calificacion" className="form-label label-razon-calificacion">Motivo</label>
                                <textarea className="form-control" id="razon-calificacion" rows="4" {...register("razon", { required: false })} placeholder='Explícanos brevemente el motivo de tu calificación...'></textarea>
                            </div>
                        </div>
                    </div>
                    <div className='footer-calificar'>
                        <p className='text-center mt-5'>
                            ¿Tuviste un problema?
                            <br />
                            <span onClick={() => navigate('/reportar-error')}>Reportar un error</span>
                        </p>
                        <div className='row pt-4 ms-1 me-1 mb-2 text-center'>
                            <div className='col-6'>
                                <Link to="/" className='text-decoration-none text-white'>
                                    <button type="button" className="btn volver-inicio-calificar">
                                        <img src={flechaNaranja} className='pe-2' /> Volver
                                    </button>
                                </Link>
                            </div>
                            <div className='col-6'>
                                <button type="submit" className="btn enviar-calificacion">
                                    Enviar
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default CalificarExperiencia;
