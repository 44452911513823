import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import iconoX from '../Componentes/iconoX.svg'
import iconoOk from '../Componentes/iconoOk.svg'
import iconoPwd from '../Componentes/iconoPwd.svg'
import useNavbarStore from '../Contexts/NavBar/navbar';
import { useHttp } from '../Hooks/httpHelpers';
import { useQueryClient } from '@tanstack/react-query';
import { config } from '../Config/config';

function EditarCuenta2() {

    const queryClient = useQueryClient();
    const { setNavBarType } = useNavbarStore();
    const { register, handleSubmit, formState: { errors }, watch } = useForm();
    const [cambioExitoso, setCambioExitoso] = useState();
    const [cargando, setCargando] = useState();
    const location = useLocation();
    const navigate = useNavigate();
    const http = useHttp();
    const hoy = new Date().toISOString().split('T')[0];

    async function processSubmit(data) {
        try {
            //TODO: validar modificacion de clases necesarias para moostrar modal programaticamente
            setCargando(true)
            document.getElementById('abrir-modal').click();
            let endpoint = location.state.sup !== 'Contraseña' ? 'usuarios/editarinfo' : 'usuarios/editarpwd';
            let actualizarUsuario = await http.PUT(config.get('baseURL') + endpoint, data);
            if (actualizarUsuario.data.success === true) {
                setCargando(false);
                setCambioExitoso(true)
                queryClient.refetchQueries(['infoUsuario']);
            } else {
                setCargando(false);
                setCambioExitoso(false);
            }
        } catch (error) {
            navigate('/error')
        }
    }

    const validacionInput = (inputField) => {
        //TODO: permitir caracteres especiales tipicos (#$@.,-_)
        let validacion = {required: true}
        if(inputField==='email') {
          validacion.pattern = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/
          return validacion
        }
        if(inputField === 'pwd' || inputField === 'newpwd') {
            validacion.pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
        }
        if(inputField === 'confirmPassword'){
            validacion.validate = (val) => {
                if (watch('newpwd') !== val) {
                    return "Las contraseñas no coinciden";
                }
            }
        }
        return validacion
    }
    
    useEffect(() => {
        setNavBarType(1)
    }, [])

    return (
        <div className="EditarCuenta2">
            <form onSubmit={handleSubmit(processSubmit)}>
            <div className='mx-4 pt-4'>
                <h5 className='editar-cuenta3'>Editar cuenta</h5>
                <div className='informacion-cuenta vstack gap-3'>
                    <div className='elemento-info-cuenta mx-3'>
                        <div className='row m-2'>
                            <div className='col-2 contenedor-icono-usuario'>
                                <img src={location.state.icon} className='img-user me-2' />
                            </div>
                            <div className='columna-info col-10 vstack'>
                                <span className='etiqueta-info'>
                                    {location.state.sup === 'Contraseña' ? 'Contraseña actual' : location.state.sup}
                                </span>
                                <span className='etiqueta-info-cuenta'>
                                    {location.state.sup === 'Fecha de nacimiento'
                                        ? <input type='date' max={hoy} className='input-cambio-info' placeholder="dd-mm-yyyy" {...register(location.state.edit, validacionInput(location.state.edit))}/>
                                        : <input type={location.state.sup === 'Contraseña' ? 'password' : 'text'} className='input-cambio-info' placeholder={`${location.state.inf === null ? '' : location.state.inf}`} {...register(location.state.edit, validacionInput(location.state.edit))}/>
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                    {errors[`${location.state.edit}`] && <span className='input-nueva-contraseña2 p-0 col-12 mx-4 fs-6 d-flex'><img src={iconoX} alt='icono error' className='bg-white align-self-center me-2' />{`${location.state.sup} es requerido`}</span>}
                    {errors.email?.type === 'pattern' && <span className='input-nueva-contraseña2 p-0 col-12 mx-4 fs-6 d-flex'><img src={iconoX} alt='icono error' className='bg-white align-self-center me-2' />Correo electrónico no valido</span>}
                    {errors.newpwd?.type === 'pattern' && <span className='input-nueva-contraseña2 p-0 col-12 mx-4 fs-6 d-flex'><img src={iconoX} alt='icono error' className='bg-white align-self-center me-2' />La contraseña debe contener mínimo 8 caracteres con al menos una letra minúscula, una mayúscula y un número.</span>}
                </div>
                {location.state.edit === 'pwd' ? 
                <>
                <div className='informacion-cuenta vstack gap-3 mt-3'>
                <div className='elemento-info-cuenta mx-3'>
                    <div className='row m-2'>
                        <div className='col-2 contenedor-icono-usuario'>
                            <img src={iconoPwd} className='img-user me-2' />
                        </div>
                        <div className='columna-info col-10 vstack'>
                            <span className='etiqueta-info'>
                                Nueva contraseña
                            </span>
                            <span className='etiqueta-info-cuenta'>
                                <input type='password' className='input-cambio-info' placeholder={`${location.state.inf === null ? '' : location.state.inf}`} {...register('newpwd', validacionInput('newpwd'))}/>
                            </span>
                        </div>
                    </div>
                </div>
                {errors.newpwd && <span className='input-nueva-contraseña2 p-0 col-12 mx-4 fs-6 d-flex'><img src={iconoX} alt='icono error' className='bg-white align-self-center me-2' />Nueva contraseña es requerida</span>}
                {errors.newpwd?.type === 'pattern' && <span className='input-nueva-contraseña2 p-0 col-12 mx-4 fs-6 d-flex'><img src={iconoX} alt='icono error' className='bg-white align-self-center me-2' />La contraseña debe contener mínimo 8 caracteres con al menos una letra minúscula, una mayúscula y un número.</span>}
                </div>
                <div className='informacion-cuenta vstack gap-3 mt-3'>
                <div className='elemento-info-cuenta mx-3'>
                    <div className='row m-2'>
                        <div className='col-2 contenedor-icono-usuario'>
                            <img src={iconoPwd} className='img-user me-2' />
                        </div>
                        <div className='columna-info col-10 vstack'>
                            <span className='etiqueta-info'>
                                Repetir nueva contraseña
                            </span>
                            <span className='etiqueta-info-cuenta'>
                                <input type='password' className='input-cambio-info' placeholder={`${location.state.inf === null ? '' : location.state.inf}`} {...register('confirmPassword', validacionInput('confirmPassword'))}/>
                            </span>
                        </div>
                    </div>
                </div>
                {errors.confirmPassword?.type === "required" && <span className='input-nueva-contraseña2 p-0 col-12 mx-4 fs-6 d-flex'><img src={iconoX} alt='icono error' className='bg-white align-self-center me-2' />Repita su contraseña</span>}
                {errors.confirmPassword?.type === 'validate' && <span className='input-nueva-contraseña2 p-0 col-12 mx-4 fs-6 d-flex'><img src={iconoX} alt='icono error' className='bg-white align-self-center me-2' />{errors.confirmPassword?.message}</span>}
                </div>
                </>
                : null
                }
                <div className='row mx-3 mt-5'>
                    <div className='text-center mb-5 v-stack'>
                        <button type="submit" className="btn text-white px-5 py-3" id='boton-rojo2'>
                            <span className=''>Guardar Cambios</span>
                        </button>
                        <button type="button" className="btn px-5 py-3 mt-2" id='btn-volver-editar' onClick={() => navigate('/editar-cuenta')}>
                            <span className=''>Volver</span>
                        </button>
                    </div>
                </div>
            </div>
            </form>
            <button type='button' className='btn' data-bs-toggle="modal" data-bs-target="#modalCambioProducto" id='abrir-modal'></button>
            <div className="modal" id="modalCambioProducto" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content mx-auto" id='contenedor-modal-pago'>
                        <div className="modal-body modal-body-color">
                            {cargando ? <div className='row text-center'>
                                <div className='mensaje-modal-cargando mx-auto col-12'>
                                    Cargando...
                                </div>
                                <div className='col-12 mt-2'>
                                    <div className="spinner-grow color-spinner-grow" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div> : null}
                            {cambioExitoso && !cargando ? <div className='row text-center'>
                                <div className='row g-0'>
                                    <div className='icono-modal col-12'>
                                        <img src={iconoOk} width="40" height="40"/>
                                    </div>
                                    <div className='mensaje-registro-modal my-3 mx-auto col-12'>
                                        Cambios registrados exitosamente
                                    </div>
                                    <div className='botones-modal col-12'>
                                        <button type="button" className="btn boton-home-modal" data-bs-dismiss="modal" onClick={() => navigate('/cuenta')}>Volver</button>
                                        <button type="button" className="btn boton-iniciar-modal" data-bs-dismiss="modal" onClick={() => navigate('/cuenta')}>Ok</button>
                                    </div>
                                </div>
                            </div> : null}
                            {!cambioExitoso && !cargando ? <div className='row text-center'>
                                <div className='row g-0'>
                                    <div className='icono-modal col-12'>
                                        <img src={iconoX} width="40" height="40"/>
                                    </div>
                                    <div className='mensaje-registro-modal my-3 mx-auto col-12'>
                                        No fue posible registrar sus cambios
                                    </div>
                                    <div className='botones-modal col-12'>
                                        <button type="button" className="btn boton-home-modal" data-bs-dismiss="modal" onClick={() => navigate('/cuenta')}>Cancelar</button>
                                        <button type="button" className="btn boton-iniciar-modal" data-bs-dismiss="modal" onClick={() => {setCargando(true); setCambioExitoso()}}>Reintentar</button>
                                    </div>
                                </div>
                            </div>: null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditarCuenta2;