import { create } from 'zustand';

const useNavbarStore = create((set, get) => ({
    navBarType: 1,
    setNavBarType: (type) => {
      const { navBarType } = get();
      if (navBarType===type) return
      set({ navBarType: type })
    }
  }));
  
export default useNavbarStore;