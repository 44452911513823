import '../App.css';
import { Link } from 'react-router-dom';
import { useInfoUsuario } from '../Hooks/InfoUsuario';

function ContenedorSuperior() {

    const { data: infoUsuario } = useInfoUsuario();

    const obtenerPrimerNombre = (nombreCompleto) => {
        if (!nombreCompleto || typeof nombreCompleto !== 'string') {
            return <span>Compra <span className='fst-italic'>fácil</span> con SVC</span>;
        }
        const nombres = nombreCompleto.split(' ');
        const primerNombre = nombres[0];
        return `¡Hola ${primerNombre}!`;
    };

    return (
        <div className="ContenedorSuperior">
            <div className='container text-white m-2 mt-0'>
            <h1 className='text-start me-4 pt-4' id='texto-titulo'>
                {!infoUsuario || !infoUsuario.data || !infoUsuario.data.names ? <span>Compra <span className='fst-italic'>fácil</span> con SVC</span> : obtenerPrimerNombre(infoUsuario.data.names)}
            </h1>
                <p className='me-5 pt-3 text-start' id='texto-inferior-titulo'>
                    Para comenzar, escanea el
                    código QR de la máquina
                    Vending para realizar la compra.
                </p>
                <Link to="/lector-qr" className='text-decoration-none text-white'>
                    <button type="button" className="btn buttonColor text-white" id='boton-inferior-titulo'>
                        Escanea aquí
                    </button>
                </Link>
            </div>

        </div >
    );
}

export default ContenedorSuperior;