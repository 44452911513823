
export const config = {
    env: 'production',
    development: {
        version: '1.1.0',
        baseURL: 'http://localhost:8100/',
        managerURL: 'https://localhost:3000/',
        socketURL: 'wss://localhost:8100/',
        svcURL: 'https://svc.cl',
        wallet: {
            policesPDF: 'https://bucket-svc-public-assets.s3.amazonaws.com/production/Terminos_condiciones_Wallet.pdf'
        },
        recaptchaKey: "6Lf1TU8jAAAAAL3a44BNhXFsfV5GNdMhzo6EWdaI",
        facebookClientId: "1065617621491040",
        facebookCallback: "usuarios/facebook",
        googleClientId: "1034242160510-u4k5ckpj68rgpbv29o9ekpus98m0ct91.apps.googleusercontent.com",
        googleCallback: "usuarios/google",
        googleScope: "https://www.googleapis.com/auth/userinfo.profile",
        microsoftClientId: "6f4afa15-0441-44ad-9c1e-206402aa64ee",
        microsoftCallback: "usuarios/microsoft",
        hostBackend: "https://localhost:3000/",
        svcUrl: "http://svc.cl",
        infoUserRefresh: 5 * 60 * 1000,
        minAmountCharge: 100
    },
    local: {
        version: '1.1.0',
        baseURL: 'https://localhost:3000/',
        managerURL: 'https://localhost:3000/',
        socketURL: 'wss://localhost:3000/',
        svcURL: 'https://localhost:3000/',
        wallet: {
            policesPDF: 'https://bucket-svc-public-assets.s3.amazonaws.com/production/Terminos_condiciones_Wallet.pdf'
        },
        recaptchaKey: "6Lf1TU8jAAAAAL3a44BNhXFsfV5GNdMhzo6EWdaI",
        facebookClientId: "1065617621491040",
        facebookCallback: "usuarios/facebook",
        googleClientId: "1034242160510-u4k5ckpj68rgpbv29o9ekpus98m0ct91.apps.googleusercontent.com",
        googleCallback: "usuarios/google",
        googleScope: "https://www.googleapis.com/auth/userinfo.profile",
        microsoftClientId: "6f4afa15-0441-44ad-9c1e-206402aa64ee",
        microsoftCallback: "usuarios/microsoft",
        hostBackend: "https://apiservice.svc.cl:8101/",
        svcUrl: "http://svc.cl",
        infoUserRefresh: 5 * 60 * 1000,
        minAmountCharge: 1000
    },
    test: {
        version: '1.1.0',
        baseURL: 'https://comsvc.aldev.cl:8101/',
        managerURL: 'https://comsvc.aldev.cl:8101/',
        socketURL: 'wss://comsvc.aldev.cl:8101/',
        svcURL: 'https://comsvc.aldev.cl:8101',
        wallet: {
            policesPDF: 'https://bucket-svc-public-assets.s3.amazonaws.com/production/Terminos_condiciones_Wallet.pdf'
        },
        recaptchaKey: "6Lf1TU8jAAAAAL3a44BNhXFsfV5GNdMhzo6EWdaI",
        facebookClientId: "1065617621491040",
        facebookCallback: "usuarios/facebook",
        googleClientId: "1034242160510-u4k5ckpj68rgpbv29o9ekpus98m0ct91.apps.googleusercontent.com",
        googleCallback: "usuarios/google",
        googleScope: "https://www.googleapis.com/auth/userinfo.profile",
        microsoftClientId: "6f4afa15-0441-44ad-9c1e-206402aa64ee",
        microsoftCallback: "usuarios/microsoft",
        hostBackend: "https://apiservice.svc.cl:8101/",
        svcUrl: "http://svc.cl",
        infoUserRefresh: 5 * 60 * 1000,
        minAmountCharge: 1000
    },
    production: {
        version: '1.1.0',
        baseURL: 'https://apiservice.svc.cl:8101/',
        managerURL: 'https://apiservice.svc.cl:8101/',
        socketURL: 'wss://apiservice.svc.cl:8101/',
        svcURL: 'https://apiservice.svc.cl:8101',
        wallet: {
            policesPDF: 'https://bucket-svc-public-assets.s3.amazonaws.com/production/Terminos_condiciones_Wallet.pdf'
        },
        recaptchaKey: "6LeVqCcjAAAAAPm0n8cux4aup5rDvdGxushVXl5a",
        facebookClientId: "1065617621491040",
        facebookCallback: "usuarios/facebook",
        googleClientId: "1034242160510-u4k5ckpj68rgpbv29o9ekpus98m0ct91.apps.googleusercontent.com",
        googleCallback: "usuarios/google",
        googleScope: "https://www.googleapis.com/auth/userinfo.profile",
        microsoftClientId: "6f4afa15-0441-44ad-9c1e-206402aa64ee",
        microsoftCallback: "usuarios/microsoft",
        hostBackend: "https://apiservice.svc.cl:8101/",
        svcUrl: "http://svc.cl",
        infoUserRefresh: 5 * 60 * 1000,
        minAmountCharge: 1000
    },
    get: function (config = undefined) {
        const envConfig = this.env || 'production';
        if (!this[envConfig]) {
            console.log('bad config');
            throw new Error('Bad configuration');
        }
        if (config === undefined) {
            return this[envConfig];
        }
        return this[envConfig][config];
    }
};