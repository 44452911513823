import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ProductoHistorial from '../Componentes/productoHistorial';
import useNavbarStore from '../Contexts/NavBar/navbar';
import useSesionVMStore from '../Contexts/SesionVM/sesionVM';

function HistorialPago() {

    const { infoUsuario } = useSesionVMStore();
    const { setNavBarType } = useNavbarStore();
    const navigate = useNavigate();

    useEffect(() => {
        setNavBarType(1)
    }, [])

    return (
        <div className="HistorialPago">
            <div className='mx-4 py-4'>
                <h2 className='titulo-historial'>Pedidos Anteriores</h2>
                <div className='historial-productos'>
                    <ProductoHistorial 
                        fechaCompra={"20 jul"}
                        producto={"Susumo Limonada Arándano 500 cc"}
                        valorProducto={"1.000"}
                        numeroMaquina={"00000"}
                        lugar={"Ejemplo"}
                    />
                    <ProductoHistorial 
                        fechaCompra={"20 jul"}
                        producto={"Susumo Limonada Arándano 500 cc"}
                        valorProducto={"1.000"}
                    />
                    <ProductoHistorial 
                        fechaCompra={"20 jul"}
                        producto={"Susumo Limonada Arándano 500 cc"}
                        valorProducto={"1.000"}
                    />
                    <ProductoHistorial 
                        fechaCompra={"20 jul"}
                        producto={"Susumo Limonada Arándano 500 cc"}
                        valorProducto={"1.000"}
                    />
                </div>
            </div>
        </div>
    );
}

export default HistorialPago;