import '../App.css';
import iconoX from '../Componentes/iconoX.svg';
import { useForm } from "react-hook-form";

function CrearNuevaContraseña() {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const processSubmit = data => console.log(data);
    
    return (
        <div className="CrearNuevaContraseña background-color">
            <div className='container'>
                <div className='text-start my-4 ms-3'>
                    <h1 className='crear-contraseña'>Crea una nueva contraseña</h1>
                </div>
                <form onSubmit={handleSubmit(processSubmit)}>
                <div className='row mx-3 mt-5'>
                        <span className='input-nueva-contraseña p-0 col-12'>Nueva Contraseña (mínimo 8 caracteres)</span>
                        <div className='input-group input-con-borde bg-white col-12'>
                            <input type="password" className="input-con-borde2 border-0 col-11" placeholder='Ingrese su contraseña' {...register("password", { required: true, pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/ })} />
                            {errors.password?.type === "required" && (
                                <span className="input-group-text p-0 m-auto col-1 border-0 bg-white"><img src={iconoX} alt='icono error' className='bg-white' /> </span>
                            )}
                        </div>
                        {errors.password?.type === "required" && (
                            <span className='input-nueva-contraseña2 p-0 col-12'>Contraseña es requerido</span>
                        )}
                        {errors.password?.type === "pattern" && (
                            <span className='input-nueva-contraseña2 p-0 col-12'>La contraseña debe contener mínimo 8 caracteres con al menos una letra minúscula, una mayúscula y un número.</span>
                        )}
                    </div>
                    <div className='row mx-3 mt-4'>
                        <span className='input-nueva-contraseña p-0 col-12'>Repetir Nueva Contraseña</span>
                        <div className='input-group input-con-borde bg-white col-12'>
                            <input type="password" className="input-con-borde2 border-0 col-11" placeholder='Ingrese su contraseña' {...register("confirmPassword", {
                                required: true, validate: (val) => {
                                    if (watch('password') !== val) {
                                        return "Las contraseñas no coinciden";
                                    }
                                }
                            })} />
                            {errors.password?.type === "required" && (
                                <span className="input-group-text p-0 m-auto col-1 border-0 bg-white"><img src={iconoX} alt='icono error' className='bg-white' /> </span>
                            )}
                        </div>
                        {errors.password?.type === "required" && (
                            <span className='input-nueva-contraseña2 p-0 col-12'>Repita su contraseña</span>
                        )}
                        <span className='input-nueva-contraseña2 p-0 col-12' id='no-coincide'>{errors.confirmPassword?.message}</span>
                    </div>
                    <div className='row mx-3 mt-5 mb-5'>
                        <div className=' text-center mb-3'>
                            <button type="submit" className="btn text-white px-4 py-3" id='boton-rojo2'>
                                <span className=''>Restablecer contraseña</span> 
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default CrearNuevaContraseña;