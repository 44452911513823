
function ProductoHistorial({fechaCompra, producto, valorProducto, lugar, numeroMaquina}) {

    return (
        <div className="ProductoHistorial">
            <h4 className="fecha-historial">
                <span className="linea-fecha">{fechaCompra}</span>
            </h4>
            <div className="row g-0">
                <div className="col-3">
                    <div className="historial-imagen-producto">
                    </div>
                </div>
                <div className="col-9">
                    <div className="historial-info-producto vstack">
                        <div className="historial-font-producto">
                            {producto}
                        </div>
                        <div className="historial-font2-producto">
                            ${valorProducto}
                        </div>
                        <div className="historial-font2-producto">
                            Maquina #{numeroMaquina}
                        </div>
                        <div className="historial-font2-producto">
                            {lugar}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductoHistorial;