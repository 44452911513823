import logosvc from './logosvc.svg';
import logosvcnaranja from './logosvcnaranja.svg'
import '../App.css';
import { useNavigate } from 'react-router-dom';
import useCurrentStateStore from '../Contexts/CurrentState/currentState';

function NavbarQr() {

    const navigate = useNavigate();
    const { ok, setOk } = useCurrentStateStore();

    return (
        <div className="Navbar">
            {/* TODO : en caso de tener sesion con maquina, debe dirigir a pagina de seleccionar producto o producto seleccionado */}
            <nav className={`navbar ${ok ? "transicion-qr-blanco" : "transicion-qr-naranja"}`}>
                <div className="container">
                    <div className='text-decoration-none col-12 text-center' onClick={() => navigate('/')}>
                        <a className="navbar-brand m-0" onClick={() => setOk(false)}>
                            <img src={ok?logosvcnaranja:logosvc} alt="" width="40" height="40" />
                        </a>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default NavbarQr;