import '../App.css';
import { Link } from 'react-router-dom';
import flecha from './flecha.svg'
import useSocketEventStore from '../Contexts/SocketEvent/socketEvent';

function BotonError({ props }) {

    const { message } = useSocketEventStore();

    const botonPrincipal = {}
    if (props && props.report) {
        botonPrincipal.texto = 'Reportar error'
        botonPrincipal.redirigir = '/reportar-error' 
    } else {
        botonPrincipal.texto = 'Reportar error'
        botonPrincipal.redirigir = '/reportar-error' 
    }

    const botonSecundario = {}
    if (props && props.retry) {
        botonSecundario.texto = 'Reintentar'
        if(message){
            if(message.sale.productselected){
                botonSecundario.redirigir = '/producto-seleccionado'
            } else if (!message.sale.productselected && message) {
                botonSecundario.redirigir = '/seleccionar'
            } else if (!message.sale.productselected && !message){
                botonSecundario.redirigir = '/'
            } else {
                botonSecundario.redirigir = '/'
            }
        } else {
            botonSecundario.redirigir = '/'
        }
    } else if(props && props.cancel) {
        botonSecundario.texto = 'Cancelar'
        botonSecundario.redirigir = '/'
    } else if (props && props.backHome) {
        botonSecundario.texto = <span><img src={flecha} className="mb-1 me-3" />Volver al inicio</span>
        botonSecundario.redirigir = '/'
    } else {
        botonSecundario.texto = <span><img src={flecha} className="mb-1 me-3" />Volver al inicio</span>
        botonSecundario.redirigir = '/'
    }

    return (
        <>
            <div className='container-botones-error'>
                <div className='text-center pt-4'>
                    <Link to={botonPrincipal.redirigir}>
                        <button type="button" className="btn text-white" id='boton-reintentar'>{botonPrincipal.texto} </button>
                    </Link>
                </div>
                <div className='pb-4 pt-3  text-center'>
                    <Link to={botonSecundario.redirigir}>
                        <button type="button" className="btn" id='cancelar-compra'>{botonSecundario.texto} </button>
                    </Link>
                </div>
            </div>
        </>
    );
}

export default BotonError;