import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { GoogleOAuthProvider } from "@react-oauth/google";
import { config } from '../Config/config';

export default function AppProviders({ children }) {
    const keyRecaptcha = config.get('recaptchaKey');
    const googleClientId = config.get('googleClientId');
    return (
        <GoogleOAuthProvider clientId={googleClientId}>
            <GoogleReCaptchaProvider reCaptchaKey={keyRecaptcha} language='es-CL'>
                {children}
            </GoogleReCaptchaProvider>
        </GoogleOAuthProvider>
    );
}