import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { QrReader } from 'react-qr-reader';
import iconoScan from '../Componentes/iconoScan.svg';
import NavbarSvcGris from '../Componentes/navbarSvcGris';
import ReCaptchaComponent from '../Componentes/recaptcha';
import useNavbarStore from '../Contexts/NavBar/navbar';
import useSocketEventStore from '../Contexts/SocketEvent/socketEvent';
import { useFinalizarSesionVM } from '../Hooks/finalizarSesionVM';
import useReCaptchaStore from '../Contexts/reCaptcha/reCaptcha';

function LectorQr() {
    const { token } = useReCaptchaStore();
    const { message } = useSocketEventStore();
    const { setNavBarType } = useNavbarStore();
    const [qrData, setQrData] = useState();
    const navigate = useNavigate();
    const { finalizarSesion } = useFinalizarSesionVM();

    function obtenerMachineNumber() {
        try {
            let machineNumberObtenido = qrData.split('?').pop().split('&').find(param => param.startsWith('vm=')).split('=').pop();
            machineNumberObtenido ? navigate('/conectando', { state: { data: { machineNumber: machineNumberObtenido, recaptcha: token } } }) : console.log('no existe machine number')
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        setNavBarType(4);
        if (qrData) {
            obtenerMachineNumber();
        }
    }, [qrData])

    useEffect(() => {
        if (message) {
            finalizarSesion();
        }
    }, [message])

    return (
        <>
            <NavbarSvcGris />
            <div className='LectorQR text-center'>
                <h1 className='titulo-lectorqr mt-3'>
                    Escanea el QR
                    <br />
                    de la vending
                </h1>
                <div className='qr-container mt-4 d-flex justify-content-center'>
                    <ReCaptchaComponent/>
                    <QrReader
                        onResult={(result, error) => {
                            if (!!result) {
                                return setQrData(result?.text);
                            }

                            if (!!error) {
                                console.info(error);
                            }

                            /* El navegador no tiene acceso a la camara o microfono */
                            if (error.name === "NotAllowedError" || error.name === "NotFoundError") {
                                return alert('¡Para escanear el codigo qr, debe permitir el acceso a la camara!')
                            }

                            /* La camara esta siendo usada por otra aplicacion (Zoom, Webex) o pestaña (Google Meet, Messenger Video) */
                            if (error.name === "NotReadableError" || error.name === "AbortError") {
                                return alert('¡Su camara esta siendo usada por otra aplicacion, por favor cierrela e intente nuevamente!')
                            }
                        }}
                        constraints={{ facingMode: 'environment', aspectRatio: 1 }}
                        className={'qr'}
                    />
                    <img src={iconoScan} className='icono-scaner' />
                </div>
                <div className='footer-qr'>
                    <div className='row pt-4 ms-1 me-1 mb-3 text-center'>
                        <div className='col-6'>
                            <button type="button" className="btn" id='boton-reportar-qr' onClick={() => navigate('/reportar-error')}>
                                Reportar error
                            </button>
                        </div>
                        <div className='col-6'>
                            <Link to="/" className='text-decoration-none text-white'>
                                <button type="button" className="btn" id='boton-cancelar-qr'>Cancelar</button>
                            </Link>
                        </div>

                    </div>
                </div>
                {/* <p>{qrData}</p> */}
            </div>
        </>
    );
};

export default LectorQr;
