import '../App.css';
import { useEffect } from 'react';
import useNavbarStore from '../Contexts/NavBar/navbar';
import NavbarSvcGris from '../Componentes/navbarSvcGris';
import iconoOK from '../Componentes/iconoOk.svg'
import { Link } from 'react-router-dom';

function ReporteErrorEnviado() {

    const { setNavBarType } = useNavbarStore();
    useEffect(() => {
        setNavBarType(4)
    },[])

    return (
        <>
            <NavbarSvcGris />
            <div className="CalificacionEnviada">
                <div className='container text-center'>
                    <img src={iconoOK} className='mt-3' />
                    <h1 className='titulo-reporte-enviado text-center mt-4'>¡Enviado!</h1>
                    <h2 className='gracias-reporte-error text-center mt-4'>
                        Muchas gracias <br /> por tu opinión
                    </h2>
                    <h3 className='subtitulo-califica-experiencia text-center my-4'>
                        Seguiremos trabajando <br /> para mejorar tu experiencia <br /> de compra
                    </h3>
                </div>
                <div className='footer-reportar-error'>
                    <div className='row pt-4 ms-1 me-1 mb-4 text-center'>
                        <div className='col-12'>
                            <Link to="/" className='text-decoration-none text-white'>
                                <button type="submit" className="btn volver-inicio-error">
                                    Volver al inicio
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReporteErrorEnviado;