import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useCallback, useEffect } from 'react';
import useReCaptchaStore from '../Contexts/reCaptcha/reCaptcha';

const ReCaptchaComponent = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { setToken } = useReCaptchaStore();

    const DURACION_CAPTCHA = 120000;

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha('yourAction');
        setToken(token);
    }, [executeRecaptcha]);
    // refrescar captcha
    useEffect(() => {
        handleReCaptchaVerify();
        const intervalId = setInterval(() => {
            handleReCaptchaVerify();
        }, DURACION_CAPTCHA);
        return () => clearInterval(intervalId);
    }, [handleReCaptchaVerify]);

    return <button onClick={handleReCaptchaVerify} className='d-none' id='recaptcha-component'>Verify recaptcha</button>;
};

export default ReCaptchaComponent;