const currencyFormat = (value) => {
    if (typeof value !== "number") return "$0";

    return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
        minimumFractionDigits: 0,
    }).format(value);
};

const groupByDate = (dataArray) => {
    return dataArray.reduce((accumulator, currentItem) => {
        const date = new Date(currentItem.confirm_date).toISOString().split('T')[0];;            

        if (!accumulator[`${date}T00:00:00.000Z`]) {
            accumulator[`${date}T00:00:00.000Z`] = [];
        }

        accumulator[`${date}T00:00:00.000Z`].push(currentItem);
        return accumulator;
    }, {});
};

const dateToISO = (fecha, meses = 0) => {
    try {
        const currentDate = new Date(fecha);
        currentDate.setMonth(currentDate.getMonth() + meses);
        return currentDate.toISOString().split('T')[0];
    } catch (error) {
        
    }
    
};

const formatDateString = (dateObj) => {
    try {
        const day = dateObj.getDate().toString().padStart(2, '0');
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const year = dateObj.getFullYear();
        const hours = dateObj.getHours().toString().padStart(2, '0');
        const minutes = dateObj.getMinutes().toString().padStart(2, '0');
        return `${day}-${month}-${year} ${hours}:${minutes}`;
    } catch (error) {
        return "Invalid Date"
    }
}

const formatDate = (date) => {
    const isoDate = date.toISOString();
    const dateWithoutTimezone = isoDate.split('T')[0];
    const [ year, month, day] = dateWithoutTimezone.split('-');
    const monthNames = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    return  `${(`${day}`).padStart(2, '0')}-${monthNames[Number(month - 1)]}`;
};

export { currencyFormat, groupByDate, dateToISO, formatDate, formatDateString }