import { create } from "zustand";
import axios from "axios";

const useSesionVMStore = create((set, get) => ({
  sessionID: null,
  authorization: null,
  infoUsuario: null,
  sessionVM: null,

  setSessionID: (newSessionID) => {
    if (newSessionID) {
      axios.defaults.headers.common['sessionid'] = newSessionID.sessionId;
      set({ sessionID: newSessionID });
    } else {
      delete axios.defaults.headers.common['sessionid'];
      set({ sessionID: null });
    }
  },

  // Establecer token de autorización y ajustar las cabeceras por defecto de axios
  setAuthorization: (newToken) => {
    axios.defaults.headers.common['authorization'] = `Bearer ${newToken}`;
    localStorage.setItem('authorization', newToken);
    set({ authorization: newToken });
  },

  // Establecer sessionVM
  setSessionVM: (sessionVM) => {
    set({ sessionVM });
  },

  setInfoUsuario: (infoUsuario) => {
    set({ infoUsuario });
  },

  verificarAuthorizationLocal: () => {
    let token = localStorage.getItem('authorization');
    if (!token) return false;
    if (token.startsWith("Bearer ")) {
        token = token.slice(7);
    }
    return token;
},
  
  verificarAuthorizationEstado: () => {
    const { authorization } = get();
    const authorizationState = authorization ? authorization : false;
    return authorizationState
  },

  verificarSessionIDEstado: () => {
    const { sessionID } = get();
    const sessionIDState = sessionID ? sessionID : false;
    return sessionIDState
  },

  deleteAuthorization: () => {
    delete axios.defaults.headers.common['authorization'];
    localStorage.removeItem('authorization');
    set({ authorization: null });
  }

}));

export default useSesionVMStore;