import { useEffect } from 'react';
import ContenedorInfoUsuario from '../Componentes/contenedorInfoUsuario';
import iconoEmail from '../Componentes/iconoEmail.svg';
import user from '../Componentes/user.svg'
import iconoPwd from '../Componentes/iconoPwd.svg'
import iconoTelefono from '../Componentes/iconoTelefono.svg'
import iconoDireccion from '../Componentes/iconoDireccion.svg'
import iconoRut from '../Componentes/iconoRut.svg'
import { convertirFecha } from '../Utils/metodos/metodos.js';
import useNavbarStore from '../Contexts/NavBar/navbar';
import { useInfoUsuario } from '../Hooks/InfoUsuario';

function EditarCuenta() {

    const { data: { data: infoUsuario} } = useInfoUsuario();
    const { setNavBarType } = useNavbarStore();

    useEffect(() => {
        setNavBarType(1)
    }, [])

    return (
        <div className="EditarCuenta">
            <div className='mx-4 py-4'>
                <h5 className='editar-cuenta2'>Editar cuenta</h5>
                {/* <div className='contenedor-img'>
                    <div className='img-cuenta'>
                        <div className='editar-img-cuenta'><img src={pen} className='img-pen' /></div>
                    </div>
                </div> */}
                <div className='informacion-cuenta vstack gap-3'>
                    <ContenedorInfoUsuario
                        etiquetaSup={'Nombre'}
                        etiquetaInf={infoUsuario.names}
                        infoEdit={'names'}
                        icono={user}
                     />
                    <ContenedorInfoUsuario
                        etiquetaSup={'Apellido'}
                        etiquetaInf={infoUsuario.lastname}
                        infoEdit={'lastname'}
                        icono={user}
                    />
                    <ContenedorInfoUsuario
                        etiquetaSup={'Correo Electronico'}
                        etiquetaInf={infoUsuario.email}
                        infoEdit={'email'}
                        icono={iconoEmail}
                    />
                    <ContenedorInfoUsuario
                        etiquetaSup={'Contraseña'}
                        etiquetaInf={'xxxxxxxxxx'}
                        infoEdit={'pwd'}
                        icono={iconoPwd}
                    />
                    <ContenedorInfoUsuario
                        etiquetaSup={'Numero teléfonico'}
                        etiquetaInf={infoUsuario.phone}
                        infoEdit={'phone'}
                        icono={iconoTelefono}
                    />
                    <ContenedorInfoUsuario
                        etiquetaSup={'Dirección'}
                        etiquetaInf={infoUsuario.address}
                        infoEdit={'address'}
                        icono={iconoDireccion}
                    />
                    <ContenedorInfoUsuario
                        etiquetaSup={'RUT'}
                        etiquetaInf={infoUsuario.rut}
                        infoEdit={'rut'}
                        icono={iconoRut}
                    />
                    <ContenedorInfoUsuario
                        etiquetaSup={'Fecha de nacimiento'}
                        etiquetaInf={convertirFecha(infoUsuario.birthdate)}
                        infoEdit={'birthdate'}
                        icono={user}
                    />
                </div>
            </div>
        </div>
    );
}

export default EditarCuenta;