import './App.css';
import AppRoutes from './appRoutes';
import { useMutation } from '@tanstack/react-query';
import { obtenerDeviceID } from './Utils/requests';
import useDeviceIDStore from './Contexts/DeviceId/deviceID';
import { useEffect } from 'react';
import NavbarController from './Componentes/navbarController';
import useSesionVMStore from './Contexts/SesionVM/sesionVM';
import LoadingModal from './Componentes/modals/loading';

function App() {

  const mutation = useMutation(obtenerDeviceID);
  const {verificarDeviceIDLocal, verificarDeviceIDEstado, establecerDeviceID, generarDeviceID} = useDeviceIDStore();
  const {verificarAuthorizationLocal, verificarAuthorizationEstado, setAuthorization} = useSesionVMStore();

  const verificarDeviceID = async () => {
    let deviceID = verificarDeviceIDLocal() || verificarDeviceIDEstado();
  
    if (deviceID) {
      establecerDeviceID(deviceID);
      return
    }

    deviceID = generarDeviceID();
    const data = { deviceId: deviceID };
    try {
      const result = await mutation.mutateAsync(data);
      deviceID = result.deviceId;
      establecerDeviceID(deviceID);
    } catch (error) {
      // el deviceID ya se estableció a generarDeviceID
    }
  };

  const verificarAuthorization = () => {
    let authorization = verificarAuthorizationLocal() || verificarAuthorizationEstado();
    
    if (!authorization || authorization === null) {return
    } else {
      setAuthorization(authorization);
    }
    
  }

  useEffect(()=> {
    verificarDeviceID();
    verificarAuthorization();
  },[])

  return (
    <>
      <NavbarController />
      <LoadingModal/>
      <AppRoutes />
    </>
  );
}

export default App;
