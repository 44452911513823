import '../App.css';
import { useEffect } from 'react';
import NavbarSvcGris from '../Componentes/navbarSvcGris';
import iconoOk from '../Componentes/iconoOk.svg'
import useNavbarStore from '../Contexts/NavBar/navbar';

function PagoAceptado() {

    const { setNavBarType } = useNavbarStore();
    useEffect(() => {
        setNavBarType(4)
    },[])

    return (
        <>
            <NavbarSvcGris />
            <div className='PagoAceptado'>
                <div className='contenedor-pago-aceptado text-center'>
                    <h1 className='texto-pago-aceptado'>
                        Pago 
                        <br/>
                        aceptado
                    </h1>
                    <img src={iconoOk} className='mt-5'/>
                </div>
            </div>
        </>
    );
}

export default PagoAceptado;
