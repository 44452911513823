import '../App.css';
import { useNavigate } from 'react-router-dom';

function ContenedorInfoUsuario({ etiquetaSup, etiquetaInf = '', location = {}, infoEdit, icono }) {

    const navigate = useNavigate();

    return (
        <div className='elemento-info-cuenta mx-3' onClick={() => location !== '/editar' ? navigate('/editar', { state: { sup: etiquetaSup, inf: etiquetaInf, edit: infoEdit, icon: icono } }):null}>
            <div className='row m-2'>
                <div className='col-2 contenedor-icono-usuario'>
                    <img src={icono} className='img-user me-2' />
                </div>
                <div className='columna-info col-10 vstack'>
                    <span className='etiqueta-info'>
                        {etiquetaSup}
                    </span>
                    <span className='etiqueta-info-cuenta'>
                        {etiquetaInf}
                    </span>
                </div>
            </div>
        </div>
    );
}

export default ContenedorInfoUsuario;