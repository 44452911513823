import { create } from "zustand";

const useLoadingModalStore = create((set, get) => ({
  isModalOpen: false,
  message: null,
  qtyRenders: 0,
  open: (message = "Cargando...") => {
    const { qtyRenders } = get();
    return set({ isModalOpen: true, message, qtyRenders: qtyRenders + 1 });
  },
  close: () => {
    const { qtyRenders } = get();
    if (qtyRenders > 0) set({ qtyRenders: qtyRenders - 1 }); 
    return set({ isModalOpen: false, qtyRenders: 0 });
  }
}));

export default useLoadingModalStore;
