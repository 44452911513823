import axios from "axios";
import { config } from "../Config/config";

const obtenerDeviceID = async (deviceId) => {
    const response = await axios.post(config.get('baseURL') + "vm/id-dispositivo", deviceId);
    return response.data;
};

const obtenerInfoUsuario = async () => {
    const response = await axios.get(config.get('baseURL') + "usuarios/info");
    return response.data;
};

const iniciarSesion = async ({ credenciales, token }) => {
    axios.defaults.headers.common['authorization'] = `Basic ${credenciales}`;
    const response = await axios.post(config.get('baseURL') + "usuarios/iniciarsesion", { recaptcha: token });
    return response.data;
  };

const post = async (url, body, headers) => {
    return new Promise((resolve, reject) => {
        axios.post(url, body, headers)
        .then((response) => {
            return resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
};

export {
    post,
    obtenerDeviceID,
    obtenerInfoUsuario,
    iniciarSesion
};