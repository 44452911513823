import iconoX from './iconoX.svg'
import { useNavigate } from 'react-router-dom'
import { useForm } from "react-hook-form";
import ReCaptchaComponent from './recaptcha';
import useReCaptchaStore from '../Contexts/reCaptcha/reCaptcha';

function NoPuedesEscanear() {
    const { token } = useReCaptchaStore();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();

    function processSubmit(data) {
        navigate('/conectando', {state:{data}});
        console.log(data)
    }

    return (
        <div className="ContenedorEscanear mx-3">
            <ReCaptchaComponent/>
            <form onSubmit={handleSubmit(processSubmit)}>
                <div className='container ContenedorEscanear bg-white p-3'>
                    <p className='pregunta'>¿No puedes Escanear?</p>
                    <p className='text-muted'>Escribe abajo el código de la máquina vending para conectar</p>
                    <div className="input-group inputHomeCodigo">
                        <input type="text" className="form-control inputHomeCodigo border-0" placeholder="ejemplo: 00003" id='inputPrueba' {...register("machineNumber", { required: true })} />
                        {errors.codigoMaquina?.type === "required" && (
                            <span className="input-group-text inputHomeCodigo border-0" id="basic-addon1"><img src={iconoX} alt='icono error' /> </span>
                        )}
                    </div>
                    {errors.codigoMaquina?.type === "required" && (
                        <p className="errorCodigoVending mt-1 mb-1">El código ingresado no es válido</p>
                    )}
                    <div className="text-center">
                        <button type="submit" className="btn buttonColor text-white mt-3" id='boton-conectar-home' /* onClick={() => crearSocket('1_180', '1c1442po72g000000')} */>
                            Conectar
                        </button>
                    </div>
                </div>
                <div className='text-center py-5' onClick={() => navigate('/lista-ayuda')}>
                    <p className='pregunta'>¿Necesitas ayuda?</p>
                </div>
            </form>
        </div>
    );
}

export default NoPuedesEscanear;