import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useHttp } from "../Hooks/httpHelpers";
import useLoadingModalStore from "../Contexts/loadingModal/loadingModal";

//modalss
import ChargeMoney from '../Componentes/modals/chargeMoney';
import CreateWallet from '../Componentes/modals/createWallet';

import { GENERAL_STATES, STATUS_WALLET } from "../Utils/enums";
import { currencyFormat } from "../Utils/generalMethods";
import "../App.css";
import iconoX from "../Componentes/iconoX.svg";

import { config } from "../Config/config";

function Wallet({ infoUser }) {
    const http = useHttp();
    const navigate = useNavigate();
    const loadingModal = useLoadingModalStore((state) => ({ open: state.open, close: state.close}));
    
    const [wallet, setWallet] = useState({
        state: GENERAL_STATES.LOADING,
        status: undefined,
        message: undefined,
        balance: undefined
    });

    // Manejador de modales
    const [isModalOpenCreateWallet, setModalCreateWallet] = useState(false);
    const [isModalOpenDepositWallet, setModalDepositWallet] = useState(false);

    const toggleModalCreateWallet = () => setModalCreateWallet(prev => !prev);
    const toggleModalDepositWallet = () => setModalDepositWallet(prev => !prev);
    
    const responseCreateWallet = (res) => {
        findUserWallet();
    }

    const responseDepositWallet = (res) => {}

    const findUserWallet = async () => {
        try {
            loadingModal.open("Cargando billetera...");
            if(!infoUser?.id) throw "No se obtuvo la información de usuario.";

            const { status, data } = await http.POST(config.get("baseURL") + "wallet/exist", { userId: infoUser.id });;
            if (status !== 200) throw "Error al solicitar información de la billetera.";
            if (!data?.success) throw { message: data?.message, status: STATUS_WALLET.DISABLED};

            setWallet({
                state: GENERAL_STATES.FINISH,
                message: undefined,
                status: (data?.walletId && typeof data?.walletId === 'number') ? STATUS_WALLET.NO_CREDIT : STATUS_WALLET.NO_EXIST,
                balance: data?.balance 
            });
        } catch (error) {
            console.error(error);
            setWallet({ state: GENERAL_STATES.ERROR, message: error?.message, status: error?.status });
        } finally {
            loadingModal.close();
        }
    };

    useEffect(() => {
        findUserWallet();
    }, []);
    
    if(!infoUser) return null;

    return (
        <>
            { (wallet.state !== GENERAL_STATES.LOADING) && 
                <>
                    <div className="row d-flex justify-content-between align-items-center mb-3">
                        <div className="col">
                            <h1 className="btn-title-black d-block my-auto">Wallet</h1>
                        </div>
                        
                        { (wallet.status === STATUS_WALLET.NO_CREDIT) &&
                            <button className='btn col btn-agregar' onClick={() => navigate('/list-movements')}>
                                Ver movimientos
                            </button>
                        }
                    </div>
                    <div className="row">

                        { (wallet.state === GENERAL_STATES.ERROR) && 
                        <div className="col-12 px-0 mx-0 border border-danger">
                                <div className="p-3  mx-0 mb-2 box-wallet d-flex flex-column justify-content-center align-items-center">
                                    <img src={iconoX} width="45" className="mb-3" />
                                    <h4 className="text-center">{wallet.message || "Nuestra billetera digital, se encuentra temporalmente inactiva."}</h4>
                                </div>
                            </div>
                        }

                        { (wallet.state === GENERAL_STATES.FINISH) && 
                            <div className="col-12 px-0">
                                <div className="p-3 mb-2 box-wallet">
                                
                                    { wallet.status === STATUS_WALLET.DISABLED &&
                                        <>
                                            <img src={iconoX} width="45" className="mb-3" />
                                            <h4 className="text-center">{wallet.message || "La billetera digital ha sido deshabilitada."}</h4>
                                        </>
                                    }

                                    { wallet.status === STATUS_WALLET.NO_EXIST &&
                                        <>
                                            <h4>¿ Aun no tienes tu wallet ?</h4>
                                            <button
                                                className="btn-wallet"
                                                onClick={toggleModalCreateWallet}
                                            >
                                                ¡Obtenla aquí!
                                            </button>
                                            <CreateWallet 
                                                isOpen={isModalOpenCreateWallet} 
                                                toggle={toggleModalCreateWallet} 
                                                entryData={{ infoUser }}
                                                responseData={responseCreateWallet}
                                            />
                                        </>
                                    }

                                    { wallet.status === STATUS_WALLET.NO_CREDIT &&
                                        <>
                                            <h3>Saldo disponible</h3>
                                            <p>{currencyFormat(wallet.balance)}</p>
                                            <button 
                                                className="btn-wallet"
                                                onClick={toggleModalDepositWallet}
                                                >
                                                Cargar saldo
                                            </button>
                                            <ChargeMoney
                                                isOpen={isModalOpenDepositWallet} 
                                                toggle={toggleModalDepositWallet} 
                                                entryData={{
                                                    infoUser,
                                                    returnURL: '/metodos-pago'
                                                }}
                                                responseData={responseDepositWallet}
                                            />
                                        </>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </>
            }
        </>
    );
}

export default Wallet;
