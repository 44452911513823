import React, { useState, useRef, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useLoadingModalStore from "../Contexts/loadingModal/loadingModal";

import filtro from "../Componentes/filtro.svg";
import iconoX from "../Componentes/iconoX.svg";
import flechaNaranja from '../Componentes/flechaNaranja.svg';
import axios from 'axios';
import { post } from '../Utils/requests.js';

import { config } from '../Config/config';
import { useHttp } from '../Hooks/httpHelpers';
import useSesionVMStore from '../Contexts/SesionVM/sesionVM';
import useErrorStore from '../Contexts/Error/error';
import { useInfoUsuario } from '../Hooks/InfoUsuario';
import { GENERAL_STATES, STATUS_WALLET } from "../Utils/enums";
import { currencyFormat, dateToISO, groupByDate, formatDateString, formatDate } from "../Utils/generalMethods";

const ListMovements = () => {
    const { authorization } = useSesionVMStore();
    const { setError } = useErrorStore();
    const http = useHttp();
    const navigate = useNavigate();
    const loadingModal = useLoadingModalStore((state) => ({ open: state.open, close: state.close}));
    const buttonFilterForm = useRef(null);
    const userInfoResponse = useInfoUsuario();
    const infoUser = userInfoResponse?.data?.data;

    const [toggleLoading, setToggleLoading] = useState(false);
    const [pagination, setPagination] = useState({ 
        offset: 0, 
        limit: 5, 
        total: 0, 
        currentPage: 1, 
        totalPages: 1, 
        fromDate: undefined, 
        toDate: undefined
    });

    const [wallet, setWallet] = useState({
        state: GENERAL_STATES.LOADING,
        status: undefined,
        message: undefined,
        balance: undefined,
        id: undefined
    });

    const [transactions, setTransactions] =useState({
        state: GENERAL_STATES.LOADING,
        message: undefined,
        data: {}
    });

    const { register: registerFilter, handleSubmit: submitFilter, formState: { errors: errorsFilter }, getValues, setValue } = useForm();

    const filterMovements = async () => {
        const { fromDate, toDate } = await getValues();

        setTransactions({...transactions, state: GENERAL_STATES.LOADING });
        setPagination({
            ...pagination, 
            fromDate: fromDate.length !== 0 ? fromDate : undefined, 
            toDate: toDate.length !== 0 ? toDate : undefined
        });
        setToggleLoading(!toggleLoading);
        buttonFilterForm.current.click();
    };

    const validateFromDate = (value) => {
        try {
            if(!value) return true;
            const _fromDate = new Date(value);
            const fromDateStr = _fromDate.toISOString().split('T')[0];

            const _today = new Date();
            const todayStr = _today.toISOString().split('T')[0];

            if (!(_fromDate instanceof Date)) return "Fecha invalida.";

            if(fromDateStr > todayStr) return 'No puede ser superior a hoy.';

            const toDateValue = getValues('toDate');
            if(!toDateValue) return true;

            const _toDate = new Date(toDateValue);
            const toDateStr = _toDate.toISOString().split('T')[0];

            if(fromDateStr > toDateStr) return "No puede ser superior a fecha final.";
            return true;
        } catch (error) {
            return "Error en la selección de fechas."
        }
    };
    
    const validateToDate = (value) => {
        try {
            if(!value) return true;

            const _toDate = new Date(value);
            if (!(_toDate instanceof Date)) return 'Fecha invalida.';

            const _today = new Date();
            const todayStr = _today.toISOString().split('T')[0];
            const toDateStr = _toDate.toISOString().split('T')[0];

            if(toDateStr > todayStr) return 'No puede ser superior a hoy.';

            const fromDateValue = getValues('fromDate');
            if(!fromDateValue) return true;

            const _fromDate = new Date(fromDateValue);
            const fromDateStr = _fromDate.toISOString().split('T')[0];

            if(toDateStr < fromDateStr) return 'No puede ser anterior a fecha inicial.';
            return true;
        } catch (error) {
            return "Error en la selección de fechas."
        }
    };

    const listMovements = async (userWalletId) => {
        try {
            loadingModal.open("Cargando transacciones...");
            if(!userWalletId) throw { message: "No se obtuvo la información de la billetera.", status: GENERAL_STATES.ERROR}
            if(!infoUser?.id) throw "No se obtuvo la información de usuario.";
            const { status, data } = await http.POST(config.get("baseURL") + "wallet/movements", { userWalletId, limit: pagination.limit, offset: pagination.offset, fromDate: pagination.fromDate, toDate: pagination.toDate });
            if(status !== 200) throw { message: data?.message, status: GENERAL_STATES.ERROR};
            
            const { offset, limit, total, rows, fromDate, toDate } = data;
            const formatArray = groupByDate(rows);
            
            setPagination({
                ...pagination,
                offset,
                total,
                currentPage: Math.ceil((offset + limit) / limit),
                totalPages: Math.ceil(total / limit),
                fromDate,
                toDate
            }); 

            setTransactions({ 
                data: formatArray, 
                state: GENERAL_STATES.FINISH, 
                message: undefined
            });

        } catch (error) {
            setError({
                errorTitle: 'Error Listar Movimientos',
                reason: 'Ocurrio un error al obtener los movimientos para la fecha y/o pagina actual.',
                report: true,
                backHome: true
            });   
        } finally {
            loadingModal.close();
        }
    };

    const findUserWallet = async () => {
        let userWalletId;
        try {
            loadingModal.open("Cargando billetera...");
            if(!(infoUser || {}).id) throw "No se obtuvo la información de usuario.";

            const { status, data } = await http.POST(config.get("baseURL") + "wallet/exist", { userId: infoUser.id });;
            if (status !== 200) throw "Error al solicitar información de la billetera.";
            if (!data?.success) throw { message: data?.message, status: STATUS_WALLET.DISABLED};

            setWallet({
                state: GENERAL_STATES.FINISH,
                message: undefined,
                status: (data?.walletId && typeof data?.walletId === 'number') ? STATUS_WALLET.NO_CREDIT : STATUS_WALLET.NO_EXIST,
                id: data?.walletId,
                balance: data?.balance 
            });
            userWalletId = data?.walletId
        } catch (error) {
            console.error(error);
            setWallet({ state: GENERAL_STATES.ERROR, message: error?.message, status: error?.status });
        } finally {
            loadingModal.close();
            await listMovements(userWalletId);
        }
    };
    
    const changePage = (change, current) => {
        if(change === pagination.currentPage) return undefined;
        setTransactions({...transactions, state: GENERAL_STATES.LOADING });
        const pag = pagination;
        setPagination({...pagination, currentPage: change, offset: Math.round(pag.limit * ( change - 1 ))});
        setToggleLoading(!toggleLoading);
    };

    useEffect(() => {
        if(!authorization) return navigate('/');
        findUserWallet();
    }, [infoUser]);

    useEffect(() => {
        if(!authorization) return navigate('/');
        listMovements(wallet?.id);
    }, [toggleLoading]);
    
    return (
        <div className="movements bg-white m-0  py-3 px-2">
            <div className='row mx-0'>
                <div className="col-6">
                    <h1 className="font-title">Movimientos</h1>
                </div>
                <div className='col-6 text-end'>
                    <a
                        data-bs-toggle="collapse" 
                        href="#collapseFormFilter" 
                        role="button" 
                        aria-expanded="false" 
                        aria-controls="collapseFormFilter"
                        ref={buttonFilterForm}
                    >
                        <img src={filtro} alt="icono filtrar" />
                    </a>
                </div>

                {/** Formulario filtro */}
                <div className='col-12'>
                    <div className="collapse" id="collapseFormFilter">
                        <div className="card card-body border-0">
                            <form onSubmit={submitFilter(filterMovements)} className="form d-flex flex-column mb-3">
                                
                                <div className="col-12 mb-3">
                                    <label htmlFor="fomDate" className="form-label">Desde</label>
                                    <input 
                                        type="datetime-local" 
                                        className="form-control" 
                                        placeholder="name@example.com"
                                        name="fromDate"
                                        {...registerFilter("fromDate", {
                                            validate: value => validateFromDate(value)
                                        })}
                                    />
                                    {errorsFilter.fromDate && <small className="form-input-error"> {errorsFilter.fromDate.message} </small> }

                                </div>
                                
                                <div className="col-12 mb-4">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Hasta</label>
                                    <input 
                                        type="datetime-local" 
                                        className="form-control" 
                                        placeholder="name@example.com"
                                        name="toDate"
                                        {...registerFilter("toDate", {
                                            validate: value => validateToDate(value)
                                        })}
                                    />
                                    {errorsFilter.toDate && <small className="form-input-error"> {errorsFilter.toDate.message} </small> }
                                
                                </div>
                                
                                <div className='col-12'>
                                    <input type="submit" className="btn-font btn-svc-primary" value="Filtrar"/>
                                </div>
                            
                            </form>
                        </div>
                    </div>
                </div>

                { (wallet.state !== GENERAL_STATES.LOADING) && 
                    <>
                    {/** Saldo Billetera */}
                    <div className="col-12 my-4">
                        <h2 className="font-small-light">Saldo disponible</h2>
                        <p className="font-large-bold ">{currencyFormat(wallet.balance)}</p>
                    </div>

                    { (transactions.state === GENERAL_STATES.LOADING) &&
                        <div>
                            <h3>Un momento...</h3>
                        </div>
                    }

                    { (transactions.state === GENERAL_STATES.FINISH) && 
                        <>
                            {/** Rango de fechas visualizadas */}
                            { (pagination.toDate || pagination.fromDate ) && 
                                <div className='col-12 px-0 mb-5'>
                                    <p className='px-2 mb-1'>Mostrando resultados</p>
                                    { pagination.fromDate && <span className="badge rounded-pill bg-light text-dark">{formatDateString(new Date(pagination.fromDate))}</span>} 
                                    &nbsp;a&nbsp;
                                    { pagination.toDate && <span className="badge rounded-pill bg-light text-dark">{formatDateString(new Date(pagination.toDate))}</span>}
                                </div>
                            }

                            {/** Transacciones */}
                            { ((Object.entries(transactions?.data || {})  || []).length !== 0) ? (
                                
                                Object.entries(transactions?.data).map((transaction, index) => { return (
                                    <div key={index} className='mb-3' >
                                        <div className="col-12 flex-grow-0 box-space-date mb-1">
                                            <h3 className="font-small-normal">{formatDate(new Date(transaction[1][0].movement_date))}</h3>
                                            <hr />
                                        </div>

                                        { (transaction[1] || []).map((movement, i) => { return (
                                            <div key={i} className='col-12'>
                                                <div className="box-transaction mb-3 d-flex justify-content-between align-items-center">
                                                    <div className="box-img-transaction d-flex justify-content-center align-content-center">
            
                                                        { movement.type === 'cobro' ? (
                                                                <svg className="align-self-center" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 30 30" fill="none">
                                                                    <path d="M1.875 3.75L28.125 3.75C29.1605 3.75 30 2.91053 30 1.875C30 0.839466 29.1605 0 28.125 0H1.875C0.839466 0 0 0.839467 0 1.875C0 2.91054 0.839466 3.75 1.875 3.75Z" fill="#FE5E41"/>
                                                                    <path d="M16.2122 7.31953C15.8854 7.04226 15.4622 6.87501 15.0001 6.875C15 6.875 15.0001 6.875 15.0001 6.875C14.478 6.875 14.0057 7.08838 13.6657 7.43268L6.17423 14.9242C5.63798 15.4604 5.47757 16.2669 5.76778 16.9675C6.05799 17.6682 6.74169 18.125 7.50005 18.125H13.125L13.125 28.125C13.125 29.1605 13.9645 30 15 30C16.0355 30 16.875 29.1605 16.875 28.125L16.875 18.125H22.5001C23.2584 18.125 23.9421 17.6682 24.2323 16.9675C24.5225 16.2669 24.3621 15.4604 23.8259 14.9242L16.3259 7.42417C16.2893 7.38761 16.2514 7.35271 16.2122 7.31953Z" fill="#FE5E41"/>
                                                                </svg>
                                                            ) : (
                                                                <svg className="align-self-center" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 30 30" fill="none">
                                                                    <path d="M15 0C16.0355 0 16.875 0.839466 16.875 1.875V11.875H22.5001C23.2584 11.875 23.9421 12.3318 24.2323 13.0325C24.5225 13.7331 24.3621 14.5396 23.8259 15.0758L16.3259 22.5758C16.288 22.6137 16.2487 22.6498 16.208 22.684C15.8818 22.9592 15.4602 23.125 15 23.125C14.478 23.125 14.0057 22.9117 13.6658 22.5674L6.17423 15.0758C5.63798 14.5396 5.47757 13.7331 5.76778 13.0325C6.05799 12.3318 6.74169 11.875 7.50005 11.875H13.125L13.125 1.875C13.125 0.839466 13.9645 0 15 0Z" fill="#06D6A0"/>
                                                                    <path d="M1.875 26.25C0.839466 26.25 0 27.0895 0 28.125C0 29.1605 0.839466 30 1.875 30L28.125 30C29.1605 30 30 29.1605 30 28.125C30 27.0895 29.1605 26.25 28.125 26.25L1.875 26.25Z" fill="#06D6A0"/>
                                                                </svg>
                                                            )
                                                        }
                                    
                                                    </div>
                                                    <div className="ps-2 d-flex flex-column justify-content-center flex-grow-1">
                                                        <h3 className="font-title-transaction d-inline-block m-0 mb-1">{movement.description || movement.type}</h3>
                                                        <p className="font-detail-transaction d-inline-block m-0">saldo: {currencyFormat(movement.remaining_amount)}</p>
                                                    </div>
                                                    <div>
                                                        <p className="my-auto font-small-bold">{currencyFormat((movement.type === 'cobro' ? (-1) : (1)) * movement.amount)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )})}
                                    </div>
                                )})) : (
                                    <div className='text-center mb-5'>
                                        <img src={iconoX} alt="" width={40} className='mb-3' />
                                        <p>No existen transacciones para el periodo comprendido.</p>
                                    </div>
                                )
                            }
                            
                            {/* Pagination */}
                            { ((Object.entries(transactions?.data || {}) || []).length !== 0 || pagination.totalPages > 0) &&
                                <div className='col-12'>
                                    <nav aria-label="Page navigation example">
                                        <ul className="pagination justify-content-end">
                                            <li className={`page-item ${pagination.currentPage === 1 && "disabled" }`}>
                                                <a className="page-link" aria-label="Previous" onClick={() => changePage(1, pagination.currentPage)}>
                                                    Primero
                                                </a>
                                            </li>
                                            { pagination.currentPage !== 1 && 
                                                <li className="page-item">
                                                    <a className="page-link" onClick={() => changePage(pagination.currentPage - 1, pagination.currentPage)}>{pagination.currentPage - 1}</a>
                                                </li>
                                            }
                                            <li className="page-item disabled current">
                                                <a className="page-link bg-info">{pagination.currentPage}</a>
                                            </li>
                                            { pagination.currentPage !== pagination.totalPages && 
                                                <li className="page-item">
                                                    <a className="page-link" onClick={() => changePage(pagination.currentPage + 1, pagination.currentPage)}>{pagination.currentPage + 1}</a>
                                                </li>
                                            }
                                            <li className={`page-item ${pagination.currentPage === pagination.totalPages && "disabled" }`}>
                                                <a className="page-link" aria-label="Next" aria-disabled="true" onClick={() => changePage(pagination.totalPages, pagination.currentPage)}>
                                                    Ultimo
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            }
                       </>
                    }
                    </>
                }
            </div>
            <div className='row mx-0'>
                <div className='p-3'>
                    <div className='volver-tarjetas mx-4 my-5'>
                        <button className='btn p-0 boton-volver-tarjetas' onClick={() => navigate('/metodos-pago')}>
                            <img src={flechaNaranja} className='me-3 my-auto' /> <span className='volver-tarjetas-font'>Volver</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListMovements;