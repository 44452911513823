import { useCallback } from 'react';
import useSocketEventStore from '../Contexts/SocketEvent/socketEvent';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import useSesionVMStore from '../Contexts/SesionVM/sesionVM';
import useWebSocket from '../Hooks/webSocket';
import { config } from '../Config/config';

export const useFinalizarSesionVM = () => {

    const { setMessage, setSale } = useSocketEventStore();
    const { setSessionID } = useSesionVMStore();
    const location = useLocation();
    const navigate = useNavigate();
    const webSocket = useWebSocket(navigate, location);

  const finalizarSesion = useCallback(async () => {
    try {
      await axios.post(config.get('baseURL') + 'vm/finalizar').catch(err => console.error(err));
      setSessionID();
      setMessage();
      setSale();
      sessionStorage.removeItem("socketEventStore");
      webSocket.close();
      navigate('/');
    } catch (error) {
      console.error("Error finalizando la sesión con vm:", error);
    }
  }, []);

  return {
    finalizarSesion
  };
}