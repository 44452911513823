import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import flechaBlanca from '../Componentes/flechaBlanca.svg'
import useNavbarStore from '../Contexts/NavBar/navbar';
import useInfoAyudaStore from '../Contexts/InfoAyuda/infoAyuda';

function InfoAyuda() {

    const navegar = useNavigate();

    const { infoAyuda } = useInfoAyudaStore();

    const { setNavBarType } = useNavbarStore();

    useEffect(() => {
        setNavBarType(1)
    },[])

    return (
        <div className="Ayuda">
            <div className='mx-4'>
                <h1 className='titulo-ayuda pt-4'>Ayuda</h1>
                <h2 className='titulo-info-ayuda mt-4'>{infoAyuda.nombreTema}</h2>
                <p className='listado-temas'>
                    {infoAyuda.contenidoTema}
                </p>
            </div>
            <div className='footer-ayuda text-center mb-4'>
                <button className='btn boton-naranja-despacho' onClick={() => navegar(-1)}>
                    <img src={flechaBlanca} alt='volver' className='me-1'/>Volver
                </button>
            </div>
        </div>
    );
}

export default InfoAyuda;