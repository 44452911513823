import { useEffect } from 'react';
import '../App.css';
import iconoOk from '../Componentes/iconoOk.svg'
import Spinner from '../Componentes/spinner';
import { useNavigate, useLocation } from 'react-router-dom'
import jwt_decode from "jwt-decode";
import useCurrentStateStore from '../Contexts/CurrentState/currentState';
import { useHttp } from '../Hooks/httpHelpers';
import useSesionVMStore from '../Contexts/SesionVM/sesionVM';
import useDeviceIDStore from '../Contexts/DeviceId/deviceID';
import useErrorStore from '../Contexts/Error/error';
import useSocketEventStore from '../Contexts/SocketEvent/socketEvent';
import { useVerificarStatus } from '../Hooks/verificarStatus';
import useWebSocket from '../Hooks/webSocket';
import { config } from '../Config/config';

function ReiniciarSesion() {
    const { ok } = useCurrentStateStore();
    const { sessionID, setSessionID } = useSesionVMStore();
    const { deviceID } = useDeviceIDStore();
    const { setError } = useErrorStore();
    const { setMessage } = useSocketEventStore();
    const navigate = useNavigate();
    const location = useLocation();
    const http = useHttp();
    const verificarStatus = useVerificarStatus(navigate, location);
    const webSocket = useWebSocket(navigate, location);

    function obtenerDatosSesion() {
        try {
            let jwt = (window.location.href.split('k=')).pop();
            let jwtDecoded = jwt_decode(jwt);
            console.log(localStorage.getItem('deviceID'))
            setSessionID({ sessionId: jwtDecoded.sessionId });
        } catch (error) {
            navigate('/error')
        }
    }

    async function restaurarEstadoSesion() {
        try {
            let estadoSesion = await http.POST(config.get('baseURL') + 'vm/get-session')
            if (estadoSesion.data.success === true && estadoSesion.data.session){
                setMessage(estadoSesion.session)
            }
            if (estadoSesion.data.success === true && estadoSesion.data.session.state.status !== 'ended') {
                
                let socket = webSocket.connect(sessionID.sessionId, deviceID);
                console.log("socket reconexion",socket)
                if (!socket) {
                    setError({
                        errorTitle: 'Error de Conexión',
                        reason: 'No se pudo crear una conexión entre su dispositivo y la máquina expendedora',
                        report: true,
                        backHome: true
                    });
                    navigate('/error');
                    setSessionID();
                } 
                verificarStatus(estadoSesion.data.session);
            } else if (estadoSesion.data.success === true) {
                verificarStatus(estadoSesion.data.session);
            } else {
                navigate('/error');
                setSessionID();
            }
        } catch (error) {
            setError({
                errorTitle: 'Error de Conexión',
                reason: 'No se pudo crear una conexión entre su dispositivo y la máquina expendedora',
                report: true,
                backHome: true
            });
            navigate('/error')
        }
    }

    useEffect(() => {
        //setNavBarType(2);
        if (deviceID) {
            //console.log(jwt)
            obtenerDatosSesion();
        }
    }, [deviceID])

    useEffect(() => {
        //setNavBarType(2);
        if (sessionID) {
            //console.log(jwt)
            restaurarEstadoSesion();
        }
    }, [sessionID])

    return (
        <div className={`${ok ? "background-white" : "background-naranja"}`}>
            <div className='container text-center'>
                <div className={`col-12 contenedor-img-ok ${ok ? "" : "d-none"}`}>
                    <img src={iconoOk} width="100" height="100" className={`${ok ? "" : "d-none"}`} />
                </div>
                <h1 className={`titulo-conectando mx-auto ${ok ? "transicion-gris" : "transicion-naranja"}`}>
                    Cargando
                </h1>
                <h2 className={`subtitulo-conectando ${ok ? "d-none" : ""}`}>
                    Espere un momento...
                </h2>
                <div className={`pt-4 loader-container ${ok ? "d-none" : ""}`}>
                    <Spinner />
                </div>
                <div className='container botones-conectando'>
                    <div className={`row ${ok ? "d-none" : ""}`}>
                        <div className='col-6'>
                            <button type="button" className="btn btn-light" id='font-conectando' onClick={() => navigate('/reportar-error')}>Reportar error</button>
                        </div>
                        <div className='col-6'>
                            <button type="button" className="btn text-white ms-2 p-0" id='font-conectando2'>Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default ReiniciarSesion;