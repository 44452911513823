import { useNavigate } from 'react-router-dom';
import useErrorStore from '../Contexts/Error/error';
import useSesionVMStore from '../Contexts/SesionVM/sesionVM';
import { useCerrarSesion } from './cerrarSesion';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

export const useHttp = () => {
    
    const navigate = useNavigate();
    const { setError } = useErrorStore();
    const { setAuthorization, setSesionVM, authorization } = useSesionVMStore();
    const { cerrarSesion } = useCerrarSesion();

    const handleResponse = (respuesta) => {
        if (respuesta.headers.authrefresh) {
            setAuthorization(respuesta.headers.authrefresh);
        }
        if (respuesta.headers.sessionInfo) {
            setSesionVM(jwt_decode(respuesta.headers.sessionInfo));
        }
        if (respuesta.data.errorTitle) {
            setError(respuesta.data);
            navigate('/error');
        }
        return respuesta;
    };

    const handleError = (error) => {
        console.log(error)
        if(error.code && error.code === "ERR_NETWORK") {
            setError({
                errorTitle: 'Error de conexión',
                reason: 'No se pudo establecer conexión con nuestros servicios. Por favor verifique el estado de su red',
                report: true,
                backHome: true
            });
        } else {
            if (error.response && error.response.headers.logout) {
                cerrarSesion();
                return;
            }
            if (error.response && error.response.data.errorTitle) {
                setError(error.response.data);
                navigate('/error');
            } else {
                setError({
                    errorTitle: 'Error de conexión',
                    reason: 'No se pudo establecer conexión con nuestros servicios. Por favor verifique el estado de su red',
                    report: true,
                    backHome: true
                });
            }

        }
        return Promise.resolve(error);
    };

    const httpRequest = (method, url, data) => {
        return new Promise((resolve, reject) => {

            const headers = {};

            if (authorization) {
                headers["authorization"] = `Bearer ${authorization}`
            }
            
            axios({ method, url, data, headers })
                .then(respuesta => {
                    try {
                        resolve(handleResponse(respuesta));
                    } catch (error) {
                        console.error(error);
                        reject(error);
                    }
                })
                .catch(error => {
                    handleError(error);
                    reject(error)
                });
        });
    };

    return {
        GET: (url) => httpRequest('GET', url),
        POST: (url, data) => httpRequest('POST', url, data),
        PUT: (url, data) => httpRequest('PUT', url, data),
        DELETE: (url, data) => httpRequest('DELETE', url, data)
    };
};
