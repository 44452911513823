import '../App.css';

function NavbarProcesoCompra({props}) {

    return (
        <div className="NavbarProcesoCompra">
            <div className="progress mt-3 mx-5" style={{height: "7px"}} >
                <div className="progress-bar" role="progressbar" aria-label="Basic example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" id={props} ></div>
            </div>
        </div>
    );
}

export default NavbarProcesoCompra;