import '../App.css';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import flecha from '../Componentes/flecha.svg'
import useNavbarStore from '../Contexts/NavBar/navbar';

function PagoRealizado() {

    const { setNavBarType } = useNavbarStore();
    const location = useLocation();

    useEffect(() => {
        setNavBarType(1);
    }, [])

    return (
        <div className="PagoRealizado">
            <div className='container img-producto' /* style={{ backgroundImage: `url(${sale ? sale.productSelected.imgUrl : null})` }} */></div>
            <div className='info-producto'>
                <h2 className='fecha-pedido-realizado pt-2 mx-3 mb-0'>Pedido del 26/jul/2022 a las 18:30 hrs.</h2>
                <h1 className='titulo-producto mx-3 py-2'>Susumo Limonada Menta-Jengibre 500 cc</h1>
                <div className='row mx-3 pb-3' id='row-producto'>
                    <div className='col-2 info-final p-0'>
                        Precio
                    </div>
                    <div className='col-10 precio-numero p-0 lh-1'>
                        $"1.000"
                    </div>
                </div>
                <div className='cupon-seleccionado mx-auto'>
                    <div className='nombre-cupon'>
                        ejemplo
                    </div>
                    <div className='descuento-cupon'>
                        <span className='precio-numero'></span> de descuento
                    </div>
                    <div className='validez-cupon'>
                        Úsalo antes del
                    </div>
                </div>
                <div className='vstack mx-3 mt-2 info-final'>
                    <div className='my-1'>
                        Maquina: <span>#000000</span>
                    </div>
                    <div className='my-1'>
                        Lugar: <span>Cerrillos</span>
                    </div>
                </div>
                <div className='contenedor-inferior-realizado'>
                    <div className='pagar'>
                        <div className='row mx-3 py-2 py-4'>
                            <div className='volver-realizado'>
                                <img src={flecha} className='me-3' /> <span className='volver-realizado-font'>Volver</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PagoRealizado;
