import logosvc from './logosvc.svg';
import '../App.css';
import { Link, useNavigate, NavLink } from "react-router-dom";
import menu from './menu.svg'
import useSesionVMStore from '../Contexts/SesionVM/sesionVM';

function Navbar() {

    const { authorization } = useSesionVMStore();
    const navigate = useNavigate();
    return (
        <div className="Navbar">
            <nav className="navbar NavbarColor">
                <div className="container">
                    <Link to="/" className='text-decoration-none text-white'>
                        <span className="navbar-brand">
                            <img src={logosvc} alt="logo" width="40" height="40" />
                        </span>
                    </Link>
                    {!authorization ? <button type="button" className="btn buttonColor text-white border border-white rounded-pill">
                        <Link to="/login" className='text-decoration-none text-white d-flex'>
                            Iniciar Sesión
                        </Link>
                    </button> : <span>
                            <img src={menu} alt="menu" width="30" height="30" onClick={() => navigate('/cuenta')} />
                        </span>}
                </div>
            </nav>
        </div>
    );
}

export default Navbar;