import '../App.css';
import iconoX from '../Componentes/iconoX.svg'
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import iconoOk from '../Componentes/iconoOk.svg'
import ReCaptchaComponent from '../Componentes/recaptcha';
import { useHttp } from '../Hooks/httpHelpers';
import eyeOpen from '../Utils/img/ojo_1.svg'
import eyeClosed from '../Utils/img/ojo_2.svg'
import useReCaptchaStore from '../Contexts/reCaptcha/reCaptcha';
import { config } from '../Config/config';

function Registrarse() {

    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const http = useHttp();
    const { token } = useReCaptchaStore();
    const [registroExitoso, setRegistroExitoso] = useState();
    const [cargando, setCargando] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showRetryPassword, setRetryShowPassword] = useState(false);
    const navigate = useNavigate();
    let hoy = new Date().toISOString().split('T')[0];

    async function processSubmit(data) {
        try {
            document.getElementById('abrir-modal').click();
            let registrarUsuario = await http.POST(config.get('baseURL') + 'usuarios/registrar', {...data, phone: data.pais+data.numeroTel, recaptcha: token})
            if (registrarUsuario.data.error === false) {
                setCargando(false);
                setRegistroExitoso(true);
            } else {
                setCargando(false);
                setRegistroExitoso(false)
                document.getElementById('recaptcha-component').click();
            }
        } catch (error) {
            navigate('/error')
        }
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    const toggleRetryPasswordVisibility = () => {
        setRetryShowPassword(!showRetryPassword);
    }
    
    return (
        <div className="Registrarse background-color">
            <ReCaptchaComponent />
            <div className='container'>
                <div className='text-center my-4 pt-1'>
                    <h1 className='titulo-registro'>Regístrate</h1>
                </div>
                <form onSubmit={handleSubmit(processSubmit)}>
                    <div className='row mx-3 mt-5'>
                        <span className='input-nueva-contraseña p-0 col-12'>Nombre</span>
                        <div className='input-group input-con-borde bg-white col-12'>
                            <input type="text" className="input-con-borde2 border-0 col-11" placeholder='Nombre' {...register("names", { required: true })} />
                            {errors.names?.type === "required" && (
                                <span className="input-group-text p-0 m-auto col-1 border-0 bg-white"><img src={iconoX} alt='icono error' className='bg-white' /> </span>
                            )}
                        </div>
                        {errors.names?.type === "required" && (
                            <span className='input-nueva-contraseña2 p-0 col-12'>Nombre es requerido</span>
                        )}
                    </div>
                    <div className='row mx-3 mt-4'>
                        <span className='input-nueva-contraseña p-0 col-12'>Apellido</span>
                        <div className='input-group input-con-borde bg-white col-12'>
                            <input type="text" className="input-con-borde2 border-0 col-11" placeholder='Apellido' {...register("lastname", { required: true })} />
                            {errors.lastname?.type === "required" && (
                                <span className="input-group-text p-0 m-auto col-1 border-0 bg-white"><img src={iconoX} alt='icono error' className='bg-white' /> </span>
                            )}
                        </div>
                        {errors.lastname?.type === "required" && (
                            <span className='input-nueva-contraseña2 p-0 col-12'>Apellido es requerido</span>
                        )}
                    </div>
                    <div className='row mx-3 mt-4'>
                        <span className='input-nueva-contraseña p-0 col-12'>Contraseña (mínimo 8 caracteres)</span>
                        <div className='input-group input-con-borde bg-white col-12'>
                            <input type={showPassword ? "text" : "password"}  className="input-con-borde2 border-0 col-11" placeholder='Ingrese su contraseña' {...register("pwd", { required: true, pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/ })} />
                            {errors.pwd?.type === "required" ? (
                                <img src={iconoX} alt='icono error' className='bg-white eye-icon' />
                            ) : (
                                <button type='button' onClick={togglePasswordVisibility} className="input-group-text p-0 m-auto col-1 border-0 bg-white eye-icon">
                                    <img src={showPassword ? eyeOpen : eyeClosed} alt='Mostrar/Ocultar contraseña'/>
                                </button>
                            )}
                        </div>
                        {errors.pwd?.type === "required" && (
                            <span className='input-nueva-contraseña2 p-0 col-12'>Contraseña es requerido</span>
                        )}
                        {errors.pwd?.type === "pattern" && (
                            <span className='input-nueva-contraseña2 p-0 col-12'>La contraseña debe contener mínimo 8 caracteres con al menos una letra minúscula, una mayúscula y un número.</span>
                        )}
                    </div>
                    <div className='row mx-3 mt-4'>
                        <span className='input-nueva-contraseña p-0 col-12'>Repetir Contraseña</span>
                        <div className='input-group input-con-borde bg-white col-12'>
                            <input type={showRetryPassword ? "text" : "password"} className="input-con-borde2 border-0 col-11" placeholder='Ingrese su contraseña' {...register("confirmPassword", {
                                required: true, validate: (val) => {
                                    if (watch('pwd') !== val) {
                                        return "Las contraseñas no coinciden";
                                    }
                                }
                            })} />
                            {errors.pwd?.type === "required" ? (
                                <img src={iconoX} alt='icono error' className='bg-white eye-icon' />
                            ) : (
                                <button type='button' onClick={toggleRetryPasswordVisibility} className="input-group-text p-0 m-auto col-1 border-0 bg-white eye-icon">
                                    <img src={showRetryPassword ? eyeOpen : eyeClosed} alt='Mostrar/Ocultar contraseña'/>
                                </button>
                            )}
                        </div>
                        {errors.pwd?.type === "required" && (
                            <span className='input-nueva-contraseña2 p-0 col-12'>Repita su contraseña</span>
                        )}
                        <span className='input-nueva-contraseña2 p-0 col-12' id='no-coincide'>{errors.confirmPassword?.message}</span>
                    </div>
                    <div className='row mx-3 mt-4'>
                        <span className='input-nueva-contraseña p-0 col-12'>Fecha de Nacimiento</span>
                        <div className='input-group input-con-borde bg-white col-12'>
                            <input type="date" className={`input-con-borde2 border-0 ${errors.birthdate?.type === "required" ? "col-11" : "col-12" } text-muted`} placeholder='DD/MM/AA' max={hoy} {...register("birthdate", { required: true })} />

                            {errors.birthdate?.type === "required" && (
                                <span className={`input-group-text col-1 border-0 bg-white eye-icon`}><img src={iconoX} alt='icono error' className='bg-white' /> </span>
                            )}
                        </div>
                        {errors.birthdate?.type === "required" && (
                            <span className='input-nueva-contraseña2 p-0 col-12'>Fecha de nacimiento es requerido</span>
                        )}
                    </div>
                    <div className='row mx-3 mt-4'>
                        <span className='input-nueva-contraseña p-0 col-12'>Correo Electrónico</span>
                        <div className='input-group input-con-borde bg-white col-12'>
                            <input type="text" className="input-con-borde2 border-0 col-11" placeholder='ejemplo@correo.com' {...register("email", { required: true, pattern: /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/ })} />
                            {errors.email?.type === "required" && (
                                <span className="input-group-text p-0 m-auto col-1 border-0 bg-white"><img src={iconoX} alt='icono error' className='bg-white' /> </span>
                            )}
                        </div>
                        {errors.email?.type === "required" && (
                            <span className='input-nueva-contraseña2 p-0 col-12'>Correo electrónico es requerido</span>
                        )}
                        {errors.email?.type === "pattern" && (
                            <span className='input-nueva-contraseña2 p-0 col-12'>Correo electrónico no valido</span>
                        )}
                    </div>
                    <div className='row mx-3 mt-4 mb-5'>
                        <span className='input-nueva-contraseña p-0 col-12'>Número Teléfonico</span>
                        <div className='input-group w-25 ps-0'>
                            <select className="form-select form-select-sm text-muted py-0 pe-0 ps-2" aria-label="Default select example" defaultValue={0} id='select-registro' {...register("pais")}>
                                <option value="+56">+56</option>
                                <option value="+51">+51</option>
                                <option value="+54">+54</option>
                                <option value="+57">+57</option>
                                <option value="+593">+593</option>
                                <option value="+55">+55</option>
                                <option value="+591">+591</option>
                                <option value="+58">+58</option>
                            </select>
                        </div>
                        <div className='input-group input-con-borde bg-white w-75'>
                            <input type="number" className="input-con-borde2 border-0 ms-2 registrarse" placeholder='Numero teléfonico' {...register("numeroTel", { required: true })} />

                        </div>
                    </div>
                    <div className='row mx-3 mt-5'>
                        <div className='text-center mb-5'>
                            <button type="submit" className="btn text-white px-5 py-3" id='boton-rojo2'>
                                <span className=''>Registrarse</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <button type='button' className='btn' data-bs-toggle="modal" data-bs-target="#modalCambioProducto" id='abrir-modal'></button>
            <div className="modal" id="modalCambioProducto" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content mx-auto" id='contenedor-modal-pago'>
                        <div className="modal-body modal-body-color">
                            {cargando ? <div className='row text-center'>
                                <div className='mensaje-modal-cargando mx-auto col-12'>
                                    Cargando...
                                </div>
                                <div className='col-12 mt-2'>
                                    <div className="spinner-grow color-spinner-grow" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div> : null}
                            {registroExitoso && !cargando ? <div className='row text-center'>
                                <div className='row g-0'>
                                    <div className='icono-modal col-12'>
                                        <img src={iconoOk} width="40" height="40"/>
                                    </div>
                                    <div className='mensaje-registro-modal my-3 mx-auto col-12'>
                                        Registro Exitoso
                                    </div>
                                    <div className='botones-modal col-12'>
                                        <button type="button" className="btn boton-home-modal" data-bs-dismiss="modal" onClick={() => navigate('/activar-cuenta')}>Continuar</button>
                                    </div>
                                </div>
                            </div> : null}
                            {!registroExitoso && !cargando ? <div className='row text-center'>
                                <div className='row g-0'>
                                    <div className='icono-modal col-12'>
                                        <img src={iconoX} width="40" height="40"/>
                                    </div>
                                    <div className='mensaje-registro-modal my-3 mx-auto col-12'>
                                        Registro Fallido
                                    </div>
                                    <div className='botones-modal col-12'>
                                        <button type="button" className="btn boton-home-modal" data-bs-dismiss="modal" onClick={() => navigate('/')}>Cancelar</button>
                                        <button type="button" className="btn boton-iniciar-modal" data-bs-dismiss="modal" onClick={() => {setCargando(true); setRegistroExitoso()}}>Reintentar</button>
                                    </div>
                                </div>
                            </div>: null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Registrarse;