import '../App.css';
import iconoX from '../Componentes/iconoX.svg'
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from 'axios';
import { Buffer } from 'buffer';
import { useState } from 'react';
import ReCaptchaComponent from '../Componentes/recaptcha';
import LoginButtons from '../Componentes/3rdLogin/loginButtons';
import useSesionVMStore from '../Contexts/SesionVM/sesionVM';
import { useHttp } from '../Hooks/httpHelpers';
import eyeOpen from '../Utils/img/ojo_1.svg'
import eyeClosed from '../Utils/img/ojo_2.svg'
import { config } from '../Config/config';
import useReCaptchaStore from '../Contexts/reCaptcha/reCaptcha';

function InicioDeSesion() {

    const http = useHttp();
    const { setAuthorization, setInfoUsuario } = useSesionVMStore();
    const { token } = useReCaptchaStore();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [cargando, setCargando] = useState(true);
    const [mensajeError, setMensajeError] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    async function processSubmit(data) {
        try {
            document.getElementById('abrir-modal').click();
            let credencialesUsuario = encodeBase64(data.email.toLowerCase(), data.pwd)
            axios.defaults.headers.common['authorization'] = `Basic ${credencialesUsuario}`;

            let iniciarSesion = await http.POST(config.get('baseURL') + 'usuarios/iniciarsesion', { recaptcha: token });
            if (iniciarSesion.status === 200) {
                try {
                    axios.defaults.headers.common['authorization'] = `Bearer ${iniciarSesion.data.body}`;
                    localStorage.setItem('authorization', `Bearer ${iniciarSesion.body}`);
                    let infoUsuarioObtenida = await http.GET(config.get('baseURL') + 'usuarios/info');
                    if (infoUsuarioObtenida.status !== 200) {
                        setCargando(false);
                        setMensajeError("Error al obtener información de su cuenta");
                        document.getElementById('recaptcha-component').click();
                    } else {
                        setAuthorization(iniciarSesion.data.body);
                        document.getElementById('cerrar-modal').click();
                        setInfoUsuario(infoUsuarioObtenida.data);
                        const params = new URLSearchParams(window.location.search);
                        const redirect = params.get('redirect');
                        const decodeURI = decodeURIComponent(redirect);
                        navigate(redirect ? decodeURI : '/');
                    } 
                } catch (error) {
                    console.log("err", error)
                    let errorMessage = `Ocurrió un error al iniciar sesión ${error?.message}`
                    if(error.response.status && error.response.status===401 && error.response.data.message) {
                        errorMessage = error.response.data.message
                    }
                    setCargando(false);
                }
            } else {
                setCargando(false);
                setMensajeError("Usuario y/o Constraseña Incorrecta");
                document.getElementById('recaptcha-component').click();
            }
        } catch (error) {
            console.error("Error al iniciar sesión:", error);
            let errorMessage = `Ocurrió un error al iniciar sesión ${error?.message}`
            if(error.response.status && error.response.status===401 && error.response.data.message) {
                errorMessage = error.response.data.message
            }
            setCargando(false);
            setMensajeError(errorMessage);
            document.getElementById('recaptcha-component').click();
        }
    }

    function encodeBase64(email, password) {
        let token = email + ":" + password;
        let hash = Buffer.from(token).toString('base64');
        return hash
    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    return (
        <div className="InicioDeSesion background-color">
            <ReCaptchaComponent />
            <div className='text-center my-4'>
                <h1 className='titulo-inicio-Sesion'>Inicia sesión</h1>
            </div>
            <div className='container pb-1'>
                <form onSubmit={handleSubmit(processSubmit)}>
                    <div className='row mx-3 mt-4'>
                        <span className='texto-input p-0 col-12'>Correo Electrónico</span>
                        <div className='input-group input-con-borde bg-white col-12 mt-2'>
                            <input type="text" className="input-con-borde2 border-0 col-11" placeholder='ejemplo@correo.com' {...register("email", { required: true, pattern: /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/ })} />
                            {errors.email?.type === "required" && (
                                <span className="input-group-text p-0 m-auto col-1 border-0 bg-white"><img src={iconoX} alt='icono error' className='bg-white' /> </span>
                            )}
                        </div>
                        {errors.email?.type === "required" && (
                            <span className='input-nueva-contraseña2 p-0 col-12'>Correo electrónico es requerido</span>
                        )}
                        {errors.email?.type === "pattern" && (
                            <span className='input-nueva-contraseña2 p-0 col-12'>Correo electrónico no valido</span>
                        )}
                    </div>
                    <div className='row mx-3 mt-4'>
                        <span className='texto-input col-3 p-0'>Contraseña</span>
                        <Link to="/restablecer-password" className='col-9 text-end'><span className='texto-input p-0 underline'>¿Olvidaste tu contraseña?</span></Link>

                        <div className='input-group input-con-borde bg-white col-12 mt-2'>
                            
                            <input
                                type={showPassword ? "text" : "password"}
                                className="input-con-borde2 border-0 col-11"
                                placeholder='Ingrese su contraseña'
                                {...register("pwd", { required: true, pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/ })}
                            />
                            {errors.pwd?.type === "required" ? (
                                <img src={iconoX} alt='icono error' className='bg-white' />
                            ) : (
                                <button type='button' onClick={togglePasswordVisibility} className='input-group-text p-0 m-auto col-1 border-0 bg-white eye-icon'>
                                    <img src={showPassword ? eyeOpen : eyeClosed} alt='Mostrar/Ocultar contraseña' />
                                </button>
                            )}
                        </div>
                        {errors.email?.type === "required" && (
                            <span className='input-nueva-contraseña2 p-0 col-12'>Su contraseña es requerida</span>
                        )}
                        {errors.pwd?.type === "pattern" && (
                            <span className='input-nueva-contraseña2 p-0 col-12'>Contraseña invalida</span>
                        )}
                    </div>
                    <div className='row mx-3 mt-5'>
                        <div className='col-12 text-center'>
                            <button type="submit" className="btn text-white py-3" id='boton-rojo'>
                                Ingresar
                            </button>
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <span className='col-12 text-center text-muted texto-input'>¿No tienes una cuenta?</span>
                        <Link to="/registro" className='text-center'>
                            <span className='underline pregunta texto-input mt-1'>
                                Regístrate
                            </span>
                        </Link>
                    </div>
                </form>
            </div>
            <LoginButtons />
            <button type='button' className='btn' data-bs-toggle="modal" data-bs-target="#modalCambioProducto" id='abrir-modal'></button>
            <div className="modal" id="modalCambioProducto" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content mx-auto" id='contenedor-modal-pago'>
                        <div className="modal-body modal-body-color">
                            {cargando ? <div className='row text-center'>
                                <div className='mensaje-modal-cargando mx-auto col-12'>
                                    Cargando...
                                </div>
                                <div className='col-12 mt-2'>
                                    <div className="spinner-grow color-spinner-grow" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                                <button type="button" className="btn" data-bs-dismiss="modal" id='cerrar-modal'></button>
                            </div> : <div className='row text-center'>
                                <div className='row g-0'>
                                    <div className='icono-modal col-12'>
                                        <img src={iconoX} width="40" height="40"/>
                                    </div>
                                    <div className='mensaje-registro-modal my-3 mx-auto col-12'>
                                        {mensajeError}
                                    </div>
                                    <div className='botones-modal col-12'>
                                        <button type="button" className="btn boton-home-modal" data-bs-dismiss="modal" onClick={() => navigate('/')}>Cancelar</button>
                                        <button type="button" className="btn boton-iniciar-modal" data-bs-dismiss="modal" onClick={() => {setCargando(true); setMensajeError();}}>Reintentar</button>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InicioDeSesion;