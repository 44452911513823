import useSocketEventStore from '../Contexts/SocketEvent/socketEvent';
import useErrorStore from '../Contexts/Error/error';
import useSesionVMStore from '../Contexts/SesionVM/sesionVM';
import FormatoSession from '../Utils/formatoSession';
import { compararObjetos } from '../Utils/metodos/metodos';

export const useVerificarStatus = (navigate, location) => {

    const { setMessage, setSale } = useSocketEventStore();
    const { setSessionID } = useSesionVMStore();
    const { setError } = useErrorStore();

    const verificarStatus = (sesion) => {
        switch (sesion.state.status) {
            case "ended":
                if (sesion.report || sesion.experience) {
                    navigate('/')
                } else {
                    if (sesion.state.error && sesion.state.error !== '') {
                        if (sesion.report) {
                            navigate('/')
                            break;
                        }
                        const errorObj = {
                            error: sesion.state.error,
                            message: sesion.state.message,
                            ...(sesion.state.flags || {})
                        }
                        navigate('/error', { state: errorObj })
                    } else if (!sesion.experience) {
                        navigate('/despacho-producto')
                    } else {
                        //TODO Verificar si ya califico o reporto error
                        navigate('/');
                    }
                }
                break;
            case "dispatching":
                navigate('/retirar-producto')
                break;
            case "StandBy - Enabled":
                let estructuraCorrectaProduct = compararObjetos(FormatoSession.sale.productSelected, sesion.sale.productSelected);
                let productoVacio = Object.values(sesion.sale.productSelected).every(x => x === null || x === '');
                if (estructuraCorrectaProduct && !productoVacio) {
                    setSale(sesion.sale)
                    if (location.pathname === '/seleccionar' || location.pathname ==='/conectando') {
                        navigate('/producto-seleccionado')
                    } else {
                        navigate('/producto-seleccionado')
                    }
                } else {
                    navigate('/seleccionar')
                    console.log('estructura de producto incorrecta')
                }
                break;
            case "paying":
                // TODO que hacer con estado de paying
                break;
            case "error-page":
                setError({
                    errorTitle: sesion.state.error,
                    reason: sesion.state.message,
                    cancel: sesion.state.flags.cancel,
                    backHome: sesion.state.flags.home,
                    report: sesion.state.flags.report,
                    retry: sesion.state.flags.retry
                });
                console.log(sesion)
                if(sesion.state.typeError === 'TIMEOUT') {
                    setSessionID();
                    setMessage();
                    setSale();
                }
                navigate('/error');
                break;
            default:
                navigate('/')
                break;
        }
    }

    return verificarStatus;
};