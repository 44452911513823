import { create } from 'zustand';
import axios from 'axios';

const useDeviceIDStore = create((set, get) => ({
  deviceID: null,

  establecerDeviceID: (deviceID) => {
    set({ deviceID });
    localStorage.setItem('deviceID', deviceID);
    axios.defaults.headers.common['userdeviceid'] = deviceID;
  },
  
  generarDeviceID: () => {
    const deviceIDlocal = `${Number(((Math.random() * 100000000000000000000).toFixed(0).split('').concat((new Array(20)).fill(0))).slice(0, 20).join('')).toString(36)}${(Date.now()).toString(36)}`
    return deviceIDlocal
  },
  
  verificarDeviceIDLocal: () => {
    const deviceIDLocalStorage = localStorage.getItem('deviceID') ? localStorage.getItem('deviceID') : false;
    return deviceIDLocalStorage
  },
  
  verificarDeviceIDEstado: () => {
    const { deviceID } = get();
    const deviceIDState = deviceID ? deviceID : false;
    return deviceIDState
  }
}));

export default useDeviceIDStore;