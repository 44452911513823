import '../App.css';
import { useEffect } from 'react';
import vendingRetiro from '../Componentes/vendingRetiro.svg';
import NavbarProcesoCompra from '../Componentes/navbarProcesoCompra';
import { useNavigate } from 'react-router-dom';
import useNavbarStore from '../Contexts/NavBar/navbar';
import useSesionVMStore from '../Contexts/SesionVM/sesionVM';

function RetirarProducto() {

    const { sessionID } = useSesionVMStore();
    let navigate = useNavigate();
    
    const { setNavBarType } = useNavbarStore();
    useEffect(() => {
        if(sessionID){
            setNavBarType(3);
        } else {
            navigate('/')
        }
    },[])

    const porcentajeBarra = 'barra-progreso-3'

    return (
        <>
            <NavbarProcesoCompra props={porcentajeBarra} />
            <div className="SeleccioneUnProducto">
                <div className='container'>
                    <h2 className='pasos text-center mt-4'>PASO 3</h2>
                    <h1 className='titulo-pasos text-center mt-2'>Retira</h1>
                    <div className='Vending d-flex justify-content-center align-items-end mt-5 pb-5'>
                        <img src={vendingRetiro} />
                    </div>
                    <div className='text-center mt-4'>
                        <h3 className='texto-retiro mx-auto'>
                            Retire su producto de la maquina
                        </h3>
                        <h4 className='texto-bandeja mx-auto'>
                            Recuerda revisar
                            <br/>
                            BANDEJA!!!
                        </h4>
                        <h5 className='mt-3 reportar-error-retiro' onClick={() => navigate('/reportar-error')}>
                            Reportar error
                        </h5>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RetirarProducto;
