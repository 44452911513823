import React, { useState, useRef, useEffect } from "react";
import useSesionVMStore from "../../Contexts/SesionVM/sesionVM";
import useErrorStore from "../../Contexts/Error/error";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useHttp } from "../../Hooks/httpHelpers";
import { useInfoUsuario } from "../../Hooks/InfoUsuario";
import { config } from "../../Config/config";

import "../../App.css";
import iconoWallet from "../iconoWallet.svg";
import iconoX from "../iconoX.svg";

const CreateWallet = ({ isOpen, toggle, entryData, responseData }) => {
    const { authorization, setInfoUsuario } = useSesionVMStore();
    const { setError } = useErrorStore();
    const http = useHttp();
    const userInfoResponse = useInfoUsuario();
    const infoUsuario = userInfoResponse;
    
    const navigate = useNavigate();
    const policesPDF = config.get("wallet").policesPDF;

    const { register: registerTerms, handleSubmit: submitTerms, formState: { errors: errorsTerms } } = useForm();
    
    const inputRef = useRef(null);
    const closeButtonRef = useRef(null);
    
    const [statusModal, setStatusModal] = useState({
        loading: false,
        error: undefined
    });

    const infoUser = infoUsuario?.data?.data || {} ;

    useEffect(() => {
        if(isOpen) inputRef.current.click();
        if(!infoUser.id) return setStatusModal({ loading: false, error: "No se obtuvo la información del usuario. Si el error persiste contacta a nuestro equipo SVC."});
    }, [isOpen]);

    const createWallet = async () => {
        try {
            setStatusModal({ ...statusModal, loading: true});

            const { status: statusNewWallet, data: dataNewWallet } = await http.POST(config.get("baseURL") + "wallet/new", { userId: infoUser.id });
            if (statusNewWallet !== 200) throw "Servicio no disponible";
            responseData({ data: { walletId: dataNewWallet?.walletId }, success: true, message: "Billetera creada exitosamente."});
            closeButtonRef.current.click();
        } catch (err) {
            closeButtonRef.current.click();
            setError({
                errorTitle: 'Error de Conexión',
                reason: 'No se pudo crear la billetera digital',
                report: true,
                backHome: true
            });
            navigate("/error");
        } finally {
            setStatusModal({...statusModal, loading: false});
        }
    };

    return (
        <>
            <button type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#createWalletModal" ref={inputRef}>
                Open create wallet modal
            </button>
            <div className="modal fade" id="createWalletModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 font-svc-secondary" id="termsAndConditionsLabel">
                                ¡Crea Tu Billetera Digital!
                            </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={toggle} ref={closeButtonRef}></button>
                        </div>
                        <div className="modal-body">
                            {
                                statusModal.loading ? ( 
                                    <div class="my-5 d-flex justify-content-center">
                                        <div class="spinner-border" role="status" style={{width: "3rem", height: "3rem", color: "#FE5E41"}}>
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                ) : (
                                    statusModal.error ? (
                                        <>
                                            <img
                                                src={iconoX}
                                                width="50"
                                                alt=""
                                                className="my-5 mx-auto d-block"
                                            />
                                            <p
                                                className="font-svc-third"
                                                style={{ textAlign: "justify" }}
                                            >
                                                {statusModal.error}
                                            </p>
                                        </>
                                    ) : (
                                        <>
                                            <img
                                                src={iconoWallet}
                                                width="50"
                                                alt=""
                                                className="my-5 mx-auto d-block"
                                            />
                                            <p
                                                className="font-svc-third"
                                                style={{ textAlign: "justify" }}
                                            >
                                                Bienvenido(a) al proceso de creación de tu billetera
                                                digital. Aquí podrás pagar tus productos SVC de
                                                manera segura y eficiente.
                                            </p>
                                            <p className="font-svc-secondary">Beneficios:</p>
                                            <ul className="font-svc-third">
                                                <li>Transacciones instantáneas.</li>
                                                <li>Rapidez efectuando pagos.</li>
                                                <li>Acceso 24/7 desde cualquier dispositivo</li>
                                                <li>Ver tus movimientos.</li>
                                            </ul>
                                            <form onSubmit={submitTerms(createWallet)}>
                                                <div className="form-check mb-4">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="accept"
                                                        name="accept"
                                                        {...registerTerms("accept", { required: true })}
                                                    />
                                                    <label
                                                        className="form-check-label terms-label"
                                                        htmlFor="accept"
                                                    >
                                                        He leído y acepto los{" "}
                                                        <a href={policesPDF} target="_blank">
                                                            terminos y condiciones
                                                        </a>
                                                        .
                                                    </label>
                                                    <small className="form-input-error">
                                                        {errorsTerms.accept &&
                                                            " Este campo es requerido"}
                                                    </small>
                                                </div>
                                                <div className="d-flex justify-content-end gap-4">
                                                    <button
                                                        type="reset"
                                                        className="btn-font btn-svc-secondary"
                                                        data-bs-dismiss="modal"
                                                        id="close-modal-terms"
                                                    >
                                                        Volver
                                                    </button>
                                                    <input
                                                        type="submit"
                                                        className="btn-font btn-svc-primary"
                                                        value="Crear"
                                                    />
                                                </div>
                                            </form>
                                        </>
                                    )
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CreateWallet;