import React, { useState, useRef, useEffect } from "react";

import "../../App.css";

import iconoX from "../iconoX.svg";
import iconoOk from "../iconoOk.svg";

const GeneralInfo = ({ isOpen, toggle, entryData }) => {
   
    const inputRef = useRef(null);

    useEffect(() => {
        if(isOpen) inputRef.current.click();
    }, [isOpen]);

    return (
        <>
            <button type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#generalInfoModal" ref={inputRef}>
                Open general info modal
            </button>
            <div className="modal fade" id="generalInfoModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                    <div className="modal-body">
                        <img src={entryData.success ? iconoOk : iconoX } width="40" height="40" className="d-block mx-auto"/>
                        <div className='mensaje-registro-modal my-3 mx-auto col-12'>
                            { entryData.message}
                        </div>
                        <div className='botones-modal col-12'>
                            <button type="button" className="btn boton-iniciar-modal2 mx-auto d-block" data-bs-dismiss="modal" id="close-state-modal" onClick={toggle}>Cerrar</button> 
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default GeneralInfo;