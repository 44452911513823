import '../App.css';
import { set, useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom'
import ReCaptchaComponent from '../Componentes/recaptcha';
import { useState } from 'react';
import iconoX from '../Componentes/iconoX.svg'
import { useHttp } from '../Hooks/httpHelpers';
import useReCaptchaStore from '../Contexts/reCaptcha/reCaptcha';
import { config } from '../Config/config';

function RestablecerContraseña() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { token } = useReCaptchaStore();
    const [cargando, setCargando] = useState();
    const navigate = useNavigate();
    const http = useHttp();

    async function processSubmit(data){
        try {
            setCargando(true);
            document.getElementById('modal-restablecer-contraseña').click();
            let recuperarContraseña = await http.POST(`${config.get('baseURL')}usuarios/recuperarpwd?email=${data.email.toLowerCase()}`, { recaptcha: token});
            if(recuperarContraseña.status !== 200) {
                setCargando();
            } else {
                document.getElementById('cerrar-modal').click();
                navigate('/restablecer-password2')
            }
        } catch (error) {
            console.log("error al intentar recuperar contraseña:", error)
            setCargando();
        }
    }

    return (
        <div className="RestablecerContraseña background-color">
            <ReCaptchaComponent />
            <div className='text-center mt-4'>
                <h1 className='titulo-inicio-Sesion fs-4 my-0'>Restablecer contraseña</h1>
                <p className='texto-restablecer-contraeña mx-auto my-0 py-0'>Ingresa tu correo donde te daremos un link para restablecer tu contraseña</p>
            </div>
            <div className='container'>
                <form onSubmit={handleSubmit(processSubmit)}>
                    <div className='row mx-3'>
                        <input type="text" className="input-con-borde col-12" placeholder='ejemplo@correo.com' {...register("email", { required: true, pattern: /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/ })}/>
                        {errors.email?.type === "required" && (
                        <span className='col-12 correo-no-valido'>Correo electrónico es requerido</span>
                        )}
                        {errors.email?.type === "pattern" && (
                        <span className='col-12 correo-no-valido'>El correo ingresado no es válido</span>
                        )}
                    </div>
                    <div className='row mx-3 mt-2'>
                        <div className='col-12 text-center'>
                            <button type="submit" className="btn text-white py-2" id='boton-rojo'>
                                Enviar
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <button type='button' className='btn' data-bs-toggle="modal" data-bs-target="#modalRestablecerContraseña" id='modal-restablecer-contraseña'></button>
            <div className="modal" id="modalRestablecerContraseña" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content mx-auto" id='contenedor-modal-pago'>
                        <div className="modal-body modal-body-color">
                            <div className='row text-center'>
                                {cargando ?
                                    <div className='row g-0'>
                                        <div className='mensaje-modal-cargando mx-auto col-12'>
                                            Cargando...
                                        </div>
                                        <div className='col-12 mt-2'>
                                            <div className="spinner-grow color-spinner-grow" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                        <button type='button' className='btn' data-bs-dismiss="modal" data-bs-target="#modalMetodosPago" id='cerrar-modal'></button>
                                    </div>
                                : <div className='row g-0'>
                                <div className='icono-modal col-12'>
                                    <img src={iconoX} width="40" height="40" />
                                </div>
                                <div className='mensaje-registro-modal my-3 mx-auto col-12'>
                                    Email invalido
                                </div>
                                <div className='botones-modal col-12'>
                                    <button type="button" className="btn boton-home-modal" data-bs-dismiss="modal" id='cerrar-modal'>Cerrar</button>
                                    <button type="button" className="btn boton-iniciar-modal2" data-bs-dismiss="modal">Reintentar</button>
                                </div>
                            </div>} 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RestablecerContraseña;