import { useNavigate } from "react-router-dom";
import useSesionVMStore from "../Contexts/SesionVM/sesionVM";
import axios from 'axios'; // Make sure you've imported axios
import useSocketEventStore from "../Contexts/SocketEvent/socketEvent";
import { useQueryClient } from "@tanstack/react-query";
import { config } from "../Config/config";

export const useCerrarSesion = () => {
    const navigate = useNavigate();
    const { setSessionID, setInfoUsuario, deleteAuthorization } = useSesionVMStore();
    const { setSale, setMessage } = useSocketEventStore();
    const queryClient = useQueryClient();

    const cerrarSesion = async () => {
      try {
        await axios.post(config.get('baseURL') + 'usuarios/cerrarsesion');
        setSessionID();
        deleteAuthorization();
        setInfoUsuario();
        setSale();
        setMessage();
        queryClient.removeQueries('infoUsuario');
        navigate('/');
      } catch (error) {
        console.error('Error al cerrar sesión:', error);
      }
    };
  
    return { cerrarSesion };
};