import '../App.css';
import Navbar from './navbar';
import NavbarQr from './navbarQr';
import useNavbarStore from '../Contexts/NavBar/navbar';

function NavbarController() {

    const { navBarType } = useNavbarStore();

    return (
        navBarType===1?<Navbar/>
        :navBarType===2?<NavbarQr/>:<div></div>
        )
}

export default NavbarController;