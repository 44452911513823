class WebSocketManager {
    constructor(onMessage, onError, onOpen, onClose) {
        this.onMessageCallback = onMessage;
        this.onErrorCallback = onError;
        this.onOpenCallback = (event) => {
            this.reconnectionAttempts = 0;
            onOpen(event);
        };
        this.onCloseCallback = (event) => {
            if (!event.wasClean && this.reconnectionAttempts < 5) {
                const delays = [2000, 5000, 10000, 20000, 40000];
                const delay = delays[this.reconnectionAttempts];

                console.log(`Intento ${this.reconnectionAttempts + 1 === 5 ? "final" : this.reconnectionAttempts + 1} para reconectar en ${delay / 1000} segundos...`);
                
                setTimeout(() => {
                    this.connect(this.socketURL, this.sessionId, this.deviceId);
                }, delay);

                this.reconnectionAttempts++;

            } else {
                onClose(event);
            }
        };

        this.socket = null;
        this.reconnectionAttempts = 0;
    }

    connect(socketURL, sessionId, deviceId) {

        this.socketURL = socketURL;
        this.sessionId = sessionId;
        this.deviceId = deviceId;

        this.close();

        this.socket = new WebSocket(`${socketURL}${deviceId}/${sessionId}`);
        
        // Asignar escuchadores de eventos
        this.socket.onopen = this.onOpenCallback;
        this.socket.onerror = (errorEvent) => {
            this.onErrorCallback(errorEvent);
        };
        this.socket.onmessage = this.onMessageCallback;
        this.socket.onclose = this.onCloseCallback;
    }

    close() {
        if (this.socket && this.socket.readyState !== WebSocket.CLOSED) {
            this.socket.close();
        }
    }
}

export default WebSocketManager;