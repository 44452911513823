import '../App.css';
import flecha from '../Componentes/flecha.svg'
import { Link } from 'react-router-dom';

function CuentaPorActivar() {
    return (
        <div className="CuentaPorActivar">
            <div className='container'>
                <div className='row'>
                    <div className='text-center col-12'>
                        <h2 className='top-text'>Un último paso</h2>
                    </div>
                    <div className='text-center mt-3 col-12'>
                        <h1 className='titulo-inicio-Sesion'>Activa tu cuenta de cliente</h1>
                    </div>
                    <div className='text-center mt-5 col-12'>
                        <p className='enviado-texto px-3'>Te enviamos un correo electrónico con un link de confirmación para que actives tu cuenta</p>
                    </div>
                    <div className='text-center mt-5 col-12'>
                        <Link to="/" className='d-flex justify-content-center'>
                            <img src={flecha} className='mb-3'/><p className='enviado-texto text-decoration-underline px-2'>Volver al inicio</p>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CuentaPorActivar;