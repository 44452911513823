import '../App.css';
import { useEffect } from 'react';
import iconoError from '../Componentes/iconoError.svg';
import BotonError from '../Componentes/botonError';
import useNavbarStore from '../Contexts/NavBar/navbar';
import useCurrentStateStore from '../Contexts/CurrentState/currentState';
import useErrorStore from '../Contexts/Error/error';

function Error() {

    const { setOk } = useCurrentStateStore();
    const { setNavBarType } = useNavbarStore();
    const { error, setError } = useErrorStore();
    const url = window.location.href;
    
    const getLoginProviderFromUrl = (url) => {
        if (url.includes('err=login')) {
            try {
                return url.split('?').pop().split('&').find(param => param.startsWith('pr=')).split('=').pop();
            } catch (error) {
                console.error(error);
            }
        }
        return null;
    }
    
    const loginProvider = getLoginProviderFromUrl(url);
    
    useEffect(() => {
        if(url.includes('err=login')) {
            setError({
                errorTitle: 'Error al iniciar sesión',
                reason: `No fue posible iniciar sesión con ${loginProvider}`
            })
        }
        setNavBarType(2);
        setOk(true);
    }, [])

    return (
        <div className='Error'>
            <div className='container'>
                <div className='pt-5 col-12 text-center'>
                    <img src={iconoError} className="mx-auto" />
                </div>
                <h1 className='titulo-error mt-5'>
                    {error?.errorTitle ? error.errorTitle :'Ha ocurrido un error'}
                </h1>
                <h2 className='mensaje-de-error text-center pt-4 mx-auto'>
                    {error?.reason ? error.reason : 'Lo sentimos mucho. Si lo deseas puedes reportar este error' }
                </h2>
            </div>
            <BotonError props={error} />
        </div>
    );
}

export default Error;