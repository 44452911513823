import '../App.css';
import { useEffect } from 'react';
import iconoOk from '../Componentes/iconoOk.svg'
import { useNavigate } from 'react-router-dom';
import useNavbarStore from '../Contexts/NavBar/navbar';
import useSesionVMStore from '../Contexts/SesionVM/sesionVM';
import useSocketEventStore from '../Contexts/SocketEvent/socketEvent';

function ProductoDespachado() {

    const { sale } = useSocketEventStore();
    const { sessionID } = useSesionVMStore();
    const { setNavBarType } = useNavbarStore();
    const navigate = useNavigate();

    useEffect(() => {
        if(sessionID){
            setNavBarType(4);
        } else {
            navigate('/');
        }
    },[])

    return (
        <div className='ProductoDespachado'>
            <div className='container text-center'>
                <img src={iconoOk} width='65' height='65' className='mt-5' />
                <h1 className='texto-despacho-producto mx-auto mt-4'>
                    Tu producto ha sido despachado con éxito!
                </h1>
                <div className='detalle-producto-depachado'>
                    <h2 className='texto-detalle-producto mb-3'>
                        Detalle de tu producto:
                    </h2>
                    <div className='row gx-2'>
                        <div className='img-producto-despachado col-6 text-end mt-1'>
                            <div className='container img-producto-despachado' style={{ backgroundImage: `url(${sale ? sale.productSelected.imgUrl : null})` }}></div>
                        </div>
                        <div className='col-6 my-auto'>
                            <p className='info-producto-despachado text-start'>
                                {sale?.productSelected.name}
                                <br />
                                <span>
                                {`$${sale?.total}`}
                                </span>    
                            </p>
                        </div>
                    </div>
                </div>
                <div className='botones-producto-despachado mt-5'>
                    <div className='row gy-3'>
                        <div className='col-12'>
                            <button className='btn boton-naranja-despacho mt-3' onClick={() => navigate('/')}>
                                Volver a comprar
                            </button>
                        </div>
                        <div className='col-12'>
                            <button className='btn boton-blanco-despacho' onClick={() => navigate('/calificar-experiencia')}>
                                Calificar experiencia
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductoDespachado;
