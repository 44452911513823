import { useState, useEffect } from 'react';
import useSocketEventStore from '../Contexts/SocketEvent/socketEvent';
import { useVerificarStatus } from './verificarStatus';
import WebSocketManager from '../Utils/webSocketManager';
import FormatoSession from '../Utils/formatoSession';
import { compararObjetos } from '../Utils/metodos/metodos';
import { config } from '../Config/config';

function useWebSocket(navigate, location) {
    const { setSale } = useSocketEventStore();
    const verificarStatus = useVerificarStatus(navigate, location);
    const [webSocketManager, setWebSocketManager] = useState(null);

    const onMessage = (event) => {
        const _data = JSON.parse(JSON.parse(JSON.stringify(event.data)))
        let estructuraCorrectaSession = compararObjetos(FormatoSession, _data);
        if (estructuraCorrectaSession && _data.state.status) {
            if (window.location.href.includes('/reportar-error') || window.location.href.includes('/calificar-experiencia')) {
                setSale(_data.sale)
            } else {
                if (_data.state.status === 'ended' && ['APP-CANCEL', 'TIMEOUT', 'SESSION-ERROR'].includes(_data.state.typeError)){
                    console.log('sesion terminada por TIMEOUT! O APPCANCEL! o SESIONERROR!')
                } else {
                    verificarStatus(_data)
                }
            }
        } else {
            console.log('estructura correcta incorrecta de sesion, mensaje no guardado')
        }

    };

    const onError = (error) => {
        console.error("Socket error:", error);
    };

    const onOpen = (e) => {
        console.log("Socket opened:", e);
    };

    const onClose = (event) => {
        console.log("Socket closed:", event);
    };
    

    const connect = (sessionId, deviceId) => {
        const manager = new WebSocketManager(onMessage, onError, onOpen, onClose);
        try {
            manager.connect(config.get('socketURL'), sessionId, deviceId);
            setWebSocketManager(manager);
            return true;
        } catch (error) {
            console.log("error socket", error);
            return false;
        }
    };

    const close = () => {
        if (webSocketManager) {
            webSocketManager.close();
        }
    };

    useEffect(() => {
        return () => {
            if (webSocketManager) {
                webSocketManager.close();
            }
        };
    }, [webSocketManager]);

    return {
        connect,
        close
    };
}

export default useWebSocket;