import React from "react";
import useLoadingModalStore from "../../Contexts/loadingModal/loadingModal";
import { Modal } from "react-bootstrap";

const LoadingModal = () => {
  const {status, message, close} = useLoadingModalStore((state) => ({ status: state.isModalOpen, message: state.message, close: state.close }));
  
  if (!status) return null;

  return (
    <>
      <Modal show={status} onHide={close}backdrop="static"
        keyboard={false} centered>
        <Modal.Body className="my-3">
          <div className="row text-center">
            <div className="mensaje-modal-cargando mx-auto col-12">
              {message || "cargando..."}
            </div>
            <div className="col-12 mt-2">
              <div className="spinner-grow color-spinner-grow spinner-border-sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoadingModal;
