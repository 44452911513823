import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';

import '../App.css';
import iconoOk from '../Componentes/iconoOk.svg'
import useNavbarStore from '../Contexts/NavBar/navbar';

const SuccessPayments = () => {
    const { setNavBarType } = useNavbarStore();
    const [transactionData, setTransactionData] = useState(null);

    let navigate = useNavigate();

    const formatearMonedaCLP = (valor) => {
        return new Intl.NumberFormat('es-CL', {
          style: 'currency',
          currency: 'CLP',
          minimumFractionDigits: 0,
        }).format(valor);
    };

    const returnBack = () => {
        const decodeURI = decodeURIComponent(JSON.parse(transactionData.metadata).backURL || "");
        navigate(decodeURI || '/');
    }

    const padWithZeros = (num, length) => num.toString().padStart(length, '0');
    
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        setTransactionData({
            buyOrder: params.get('buyOrder'),
            amount: params.get('amount'),
            status: params.get('status'),
            movement_id: params.get('movement_id'),
            wallet_id: params.get('wallet_id'),
            metadata: params.get('metadata'),
            payment_method: params.get('payment_method')
        });

        setNavBarType(2);
    },[]);

    return (
        <>
            <div className='row m-0 d-flex flex-column align-items-center'>
                <div className='col-12 col-md-8  section-payments'>
                    <img src={iconoOk} width='65' height='65' className='m-5 mx-auto d-block'/>
                    <h1 className='font-svc-primary mb-5 text-center'>Pago autorizado</h1>
                    <p className='font-svc-third'>¡Felicidades! Tu transacción ha sido procesada y completada con éxito.</p>
                    <p className='font-svc-secondary mb-2'>Detalles transacción: </p>
                    <br></br>
                    <table className='table-payments table table-striped'>
                        <tbody>
                            <tr>
                                <th scope='row'> Orden de compra</th>
                                <td>{(transactionData || {}).buyOrder}</td>
                            </tr>
                            <tr>
                                <th scope="row">Monto</th>
                                <td>{formatearMonedaCLP((transactionData || {}).amount)}</td>
                            </tr>
                            <tr>
                                <th scope="row">Estado </th>
                                <td>{(transactionData || {}).status}</td>
                            </tr>
                            <tr>
                                <th scope="row">Metódo pago </th>
                                <td>{(transactionData || {}).payment_method}</td>
                            </tr>
                            <tr>
                                <th scope="row"> Id. movimiento</th>
                                <td>{padWithZeros(Number((transactionData || {}).movement_id), 10)}</td>
                            </tr>
                            <tr>
                                <th scope="row"> Id. billetera</th>
                                <td>{padWithZeros(Number((transactionData || {}).wallet_id), 10)}</td>
                            </tr>
                        </tbody>
                    </table>
                    <button style={{ textAlign: "center", marginTop: "18px", fontSize: "18px", fontWeight: "700", lineHeight: "33px", color: "#FE5E41", backgroundColor: "#ffffff", borderColor: "#FE5E41", borderRadius: "20px"}} className='btn btn-primary mx-auto text-center' onClick={() => returnBack()} >{JSON.parse((transactionData || {}).metadata || "{}").buttonMsg || "Volver atrás"} </button>
                </div>
            </div>
        </>
    );
};

export default SuccessPayments;