import logosvcnaranja from './logosvcnaranja.svg'
import '../App.css';

function NavbarSvcGris() {

    return (
        <div className="Navbar">
            <nav className='navbar navbarGris'>
                <div className="container">
                    <a className="navbar-brand m-0 mx-auto">
                        <img src={logosvcnaranja} alt="" width="40" height="40" />
                    </a>
                </div>
            </nav>
        </div>
    );
}

export default NavbarSvcGris;