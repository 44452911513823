import '../App.css';
import { useEffect, useState } from 'react';
import iconoBilletera from '../Componentes/iconoBilletera.svg'
import iconoBilleteraPlomo from '../Componentes/iconoBilleteraPlomo.svg'
import flechaNaranja from '../Componentes/flechaNaranja.svg'
import iconoX from '../Componentes/iconoX.svg'
import { useForm } from "react-hook-form";
import NavbarProcesoCompra from '../Componentes/navbarProcesoCompra';
import { useLocation, useNavigate } from 'react-router-dom';
import ReCaptchaComponent from '../Componentes/recaptcha';
import useNavbarStore from '../Contexts/NavBar/navbar';
import useDeviceIDStore from '../Contexts/DeviceId/deviceID';
import { useHttp } from '../Hooks/httpHelpers';
import useSesionVMStore from '../Contexts/SesionVM/sesionVM';
import { useInfoUsuario } from "../Hooks/InfoUsuario";
import { useVerificarStatus } from '../Hooks/verificarStatus';

//modals
import ChargeMoney from '../Componentes/modals/chargeMoney';
import CreateWallet from '../Componentes/modals/createWallet';
import GeneralInfo from '../Componentes/modals/generalInfo';

import useLoadingModalStore from "../Contexts/loadingModal/loadingModal";
import useSocketEventStore from '../Contexts/SocketEvent/socketEvent';
import useErrorStore from '../Contexts/Error/error';
import { config } from '../Config/config';
import useReCaptchaStore from '../Contexts/reCaptcha/reCaptcha';
import useWebSocket from '../Hooks/webSocket';

function MetodosDePago() {
    const { register, handleSubmit, formState: { errors }, watch, getValues, setValue } = useForm();
    const { sessionID, authorization, setSessionVM, setSessionID, setInfoUsuario } = useSesionVMStore();
    const { token } = useReCaptchaStore();
    const { deviceID } = useDeviceIDStore();
    const { sale, setMessage } = useSocketEventStore();
    const { setError } = useErrorStore();

    const loadingModal = useLoadingModalStore((state) => ({ open: state.open, close: state.close}));
    const userInfoResponse = useInfoUsuario();
    const infoUser = userInfoResponse?.data?.data;
    
    const location = useLocation(); 
    const navigate = useNavigate();
    const webSocket = useWebSocket(navigate, location);
    const verificarStatus = useVerificarStatus(navigate, location);
    
    const http = useHttp();
    const { setNavBarType } = useNavbarStore();
    const params = new URLSearchParams(window.location.search);
    const _sessionId = params.get('sessionId');
    const _deviceId = params.get('deviceId'); 

    const [paymentMethods, setPaymentMethods] = useState([]);
    const [cards, setCards] = useState([]);

    const [partialDepositForm, setPartialDepositForm] = useState({ paymentMethodId: null, cardId: null});

    // Manejador de modales
    const [isModalOpenCreateWallet, setModalCreateWallet] = useState(false);
    const [isModalOpenDepositWallet, setModalDepositWallet] = useState(false);
    const [isModalOpenGeneralInfo, setModalGeneralInfo] = useState({ state: false, success: null, message: null});

    const toggleModalCreateWallet = () => setModalCreateWallet(prev => !prev);
    const toggleModalDepositWallet = () => setModalDepositWallet(prev => !prev);
    const toggleModalGeneralInfo = () => setModalGeneralInfo({...isModalOpenGeneralInfo, state: !isModalOpenGeneralInfo.state });

    const porcentajeBarra = 'barra-progreso-2'
    const [diffPrice, setDiffPrice] = useState(0);
    const [payingWithWallet, setPayingWithWallet] = useState(false);
    const [wallet, setWallet] = useState({ state: false, walletId: null, error: false, balance: null, methodId: null });
    const watchPaymentMethod = watch();

    const getUserInfo = async () => {
        try {
            const token = localStorage.getItem('authorization');
            if(!token) throw "Error Sesion";
            const { status, data } = await http.GET(config.get('baseURL') + 'usuarios/info');
            if (status !== 200) throw { message: "Servicio no disponible" };
            setInfoUsuario(data);
            return data;
        } catch (error) {
            setWallet({ state: false, error: true, balance: null });
            return undefined;
        } 
    };

    useEffect(() => {
        const myPromise = new Promise(async (resolve, reject) => {
            console.debug("Primer ingreso a metodos de pago");    
            if(!sessionID?.sessionId && !_sessionId) return navigate('/');
            if(authorization && !infoUser) await userInfoResponse.refetch();
            setNavBarType(3);
            await findUserWallet();
            console.debug(typeof localStorage.getItem('authorization'));
            if(!authorization || userInfoResponse?.data) await findPaymentMethods();
            resolve();
        });
        myPromise.finally(() => {});
    }, [sale])

    useEffect(() => {
        if(_sessionId) setSessionID({ sessionId: _sessionId});
        if(localStorage.getItem('authorization') && !infoUser) userInfoResponse.refetch();
    }, [_sessionId]);

    useEffect(() => {
        if(localStorage.getItem('authorization') && !infoUser) userInfoResponse.refetch();
        if (_sessionId && sessionID) {
            restaurarEstadoSesion();
        }
    }, [sessionID])

    const changePartialDepositForm = (methodId, cardId) => {
        setPartialDepositForm({ methodId, cardId});
        setValue("paymentMethodId", methodId);
        setValue("cardId", cardId);    
    };

    const findPaymentMethods = async () => {
        try {
            const { status: statusPaymentMethod, data: dataPaymentMethod } = await http.GET(config.get('baseURL') + 'pago/metodos');
            if(statusPaymentMethod !== 200) throw "Pagos no disponibles";

            setPaymentMethods((dataPaymentMethod || {}).metodosPago || []);

            if(!authorization) return;

            const { status: statusCards, data: dataCards } = await http.GET(config.get('baseURL') + 'tarjeta/listar');
            if(statusCards !== 200) return;

            setCards((dataCards || {}).body || []);
        } catch (err) {
            navigate('/error'); 
        }
    }
    
    const responseCreateWallet = (res) => {

        //setModalGeneralInfo({ state: true, success: res.success, message: res.message});
        if(res.success === true) {
            findUserWallet();
        } else {
            setPaymentMethods(prev => (prev || []).filter(metodo => metodo.name !== 'wallet'))
        }
    }

    const handleInputDeposit = (event) => {
        const [name, value] = event.target;
        setPartialDepositForm({...partialDepositForm, [name]: value });
        setValue(name, value);
    }

    const responseDepositWallet = (res) => {
        setModalGeneralInfo({ state: true, success: res.status === 200 ? true : false, message: "Pago realizado exitoso."});
    };

    async function processSubmit() {
        try {
            const values = getValues();
            if( !(values instanceof Object) || (!values?.cardId && !values?.paymentMethodId)) throw { message: "Error al iniciar pago" };
            if(!values?.paymentMethodId && values.hasOwnProperty('cardId') && !isNaN(Number(values.cardId))) {
                loadingModal.open("Pagando con tarjeta registrada...");
                const { status, data } = await http.POST(config.get('baseURL') + 'tarjeta/pagar', {tarjetaId: values.cardId});
                if(status !== 200) throw { message: "Error al pagar con Oneclick" };
                return;
            }
            const methodUsed = (paymentMethods || []).find(method => method.id === values.paymentMethodId);

            if(methodUsed?.name.toLowerCase() !== 'wallet') {
                loadingModal.open(`Pagando con ${methodUsed.name.toLowerCase()}...`);
                const { status: statusPayment, data: dataPayment } = await http.POST(config.get('baseURL') + methodUsed?.path_endpoint, { email: getValues('email'), recaptcha: token });
                
                if(statusPayment !== 200) return navigate('/error');

                return window.location.href = config.get('baseURL') + dataPayment?.redirectUrl;
            }
            
            if(!authorization || !infoUser) return navigate("/login?redirect=" + encodeURIComponent(`/metodo-pago?sessionId=${sessionID?.sessionId}&deviceId=${localStorage.getItem('deviceID')}`) );
            if (!wallet.state) return setModalCreateWallet(true);
            if (wallet.balance < sale.total) return setModalDepositWallet(true);
            setPayingWithWallet(true);
            loadingModal.open("Pagando con billetera digital...");
            return await payWithWallet(); 
        } catch (error) {
            setError({
                errorTitle: 'Error de Pago',
                reason: error?.message || 'No se pudo iniciar el pago',
                report: true,
                backHome: true
            });
            navigate('/error');
        } finally {
            loadingModal.close();
        }
    }

    const currencyFormat = (value) => {
        if (typeof value !== "number") return "$0";

        return new Intl.NumberFormat("es-CL", {
            style: "currency",
            currency: "CLP",
            minimumFractionDigits: 0,
        }).format(value);
    };

    

    const findUserWallet = async () => {
        try {
            let user; 
            if(!(infoUser || {}).id) { 
                user = await getUserInfo();

            } else {
                user = infoUser;
            }

            const { status, data } = await http.POST(config.get("baseURL") + "wallet/exist", { userId: user.id });
            if (status !== 200) throw { message: "Servicio no disponible"};
            if(!(data || {}).success) throw { message: (data || {}).message };

            setWallet({
                state: data?.walletId ? true : false,
                walletId: data?.walletId,
                error: false,
                balance: data?.balance,
                response: true,
                message: "Balance recuperado"
            });

            const diff = sale?.total - data?.balance;
            setDiffPrice(isNaN(Number(diff)) ? 0 : (diff > config.get("minAmountCharge") ? diff : config.get("minAmountCharge") ));
        } catch (error) {
            setWallet({ state: false, error: true, balance: null, message: "Nuestro servicio está temporalmente inactivo. Apreciamos tu comprensión mientras trabajamos en resolverlo.", response: true });
        }
    };

    const payWithWallet = async () => {
        try {            
            const { status, data } = await http.POST(config.get("baseURL") + "wallet/new-charge", { userWalletId: (wallet || {}).walletId, email: getValues('email') });
        } catch (err) {
            setError({
                errorTitle: 'Error de Pago',
                reason: 'No se pudo realizar el cobro con la billetera digital',
                report: true,
                backHome: true
            });
            navigate("/error");
        }
    };

    async function restaurarEstadoSesion() {
        try {
            let estadoSesion = await http.POST(config.get('baseURL') + 'vm/get-session')
            if (estadoSesion.data.success === true && estadoSesion.data.session){
                setMessage(estadoSesion.session)
            }
            if (estadoSesion.data.success === true && estadoSesion.data.session.state.status !== 'ended') {  
                let socket = webSocket.connect(sessionID.sessionId, deviceID);
                if (!socket) {
                    setError({
                        errorTitle: 'Error de Conexión',
                        reason: 'No se pudo crear una conexión entre su dispositivo y la máquina expendedora',
                        report: true,
                        backHome: true
                    });
                    navigate('/error');
                    setSessionID();
                } 
                verificarStatus(estadoSesion.data.session);
            } else if (estadoSesion.data.success === true) {
                verificarStatus(estadoSesion.data.session);
            } else {
                navigate('/error');
                setSessionID();
            }
        } catch (error) {
            setError({
                errorTitle: 'Error de Conexión',
                reason: 'No se pudo crear una conexión entre su dispositivo y la máquina expendedora',
                report: true,
                backHome: true
            });
            navigate('/error')
        }
    }

    return (
        <>
            <NavbarProcesoCompra props={porcentajeBarra} />
            <div className="SeleccioneUnProducto">
                <ReCaptchaComponent/>
                <form onSubmit={handleSubmit(processSubmit)}>
                    <div className='container'>
                        <h2 className='pasos text-center mt-4'>PASO 2</h2>
                        <h1 className='titulo-pasos text-center mt-2'>Paga</h1>
                        <div className='mt-4 mb-4'>
                            <h3 className='mx-auto' id='texto-metodo-pago'>
                                Selecciona tu metodo de pago
                            </h3>
                        </div>
                        {!authorization ? <div className='row mx-auto mt-4 mb-3'>
                            <span className='email-pago p-0 col-12 mb-2'>Ingresa tu correo electrónico</span>
                            <div className='input-group input-con-borde bg-white col-12 mb-1' >
                                <input type="text" className="input-con-borde2 border-0 col-11" placeholder='ejemplo@correo.com' {...register("email", { required: ((!authorization && !infoUser && !(watchPaymentMethod.paymentMethodId === ((paymentMethods || []).find(method => method?.name?.toLowerCase() === 'wallet'))?.id) )? true : false), pattern: /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/ })} />
                                {errors.email?.type === "required" && (
                                    <span className="input-group-text p-0 m-auto col-1 border-0 bg-white"><img src={iconoX} alt='icono error' className='bg-white' /> </span>
                                )}
                            </div>
                            {errors.email?.type === "required" && (
                                <span className='input-nueva-contraseña2 p-0 col-12'>Correo electrónico es requerido</span>
                            )}
                            {errors.email?.type === "pattern" && (
                                <span className='input-nueva-contraseña2 p-0 col-12'>Correo electrónico no valido</span>
                            )}
                        </div> : null}

                        <button type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#loadingModal" id="open-loadingModal"></button>
                        <div className='metodos-pago container'>
                        { !((paymentMethods || []).length > 0 || (cards || []).length > 0) && <div className="loaderPago">Loading...</div> }

                            { paymentMethods.map((method, index) => {
                                if(method.name === 'wallet') {
                                    return ( <div key={index}>
                                        <div className={`row input-group-text my-2 ${partialDepositForm.methodId == method.id && partialDepositForm.cardId == null ? "metodo-seleccionado" : "metodo" }`} onClick={() => changePartialDepositForm(method.id, null)}>
                                            <div className='col-2'>
                                                <img src={partialDepositForm.methodId === method.id && partialDepositForm.cardId === null ? iconoBilletera : iconoBilleteraPlomo} className='d-block m-auto' width='25' height='25' />
                                            </div>
                                            <div className='col text-start'>
                                                <label className="p-0 m-0" htmlFor={index}>
                                                    <p className='my-0'>Billetera digital</p>
                                                    <p className='amount' style={{ fontSize: '16px'}}>
                                                        { (authorization && infoUser && wallet.state === true) ? "Saldo: " + currencyFormat(wallet.balance) : "Saldo: $0" }
                                                        { (wallet.state === true && wallet.balance < (sale || {}).total) && <span className='text-danger'> + ${sale.total - wallet.balance}</span>}
                                                    </p>
                                                </label>
                                            </div>
                                            <div className='col-2'>
                                                <input
                                                    className="input-svc-payments form-check-input"
                                                    type="radio"
                                                    name="paymentMethodId"
                                                    value={method.id}  
                                                    checked={partialDepositForm.methodId == method.id && partialDepositForm.cardId == null}
                                                    onChange={handleInputDeposit}
                                                />
                                            </div>
                                        </div>
                                    </div> )
                                }
                                if(method.name.toLowerCase() === 'oneclick') return null;
                                return ( <div key={index}>
                                    <div className={`row input-group-text my-2 ${partialDepositForm.methodId == method.id && partialDepositForm.cardId == null ? "metodo-seleccionado" : "metodo" }`} onClick={() => changePartialDepositForm(method.id, null)}>
                                        <div className='col-2'>
                                            <img src={partialDepositForm.methodId === method.id && partialDepositForm.cardId === null ? iconoBilletera : iconoBilleteraPlomo} className='d-block m-auto' width='25' height='25' />
                                        </div>
                                        <div className='col text-start'>
                                            <label className="p-0 m-0" htmlFor={index}>
                                                <p className='my-0'>
                                                    {method.name}
                                                </p>  
                                            </label>
                                        </div>
                                        <div className='col-2'>
                                            <input
                                                className="input-svc-payments form-check-input"
                                                type="radio"
                                                name="paymentMethodId"
                                                value={method.id}  
                                                checked={partialDepositForm.methodId == method.id && partialDepositForm.cardId == null}
                                                onChange={handleInputDeposit}
                                            />
                                        </div>
                                    </div>
                                </div> )
                            })}

                            { cards.map((card, index) => {   
                                return ( <div key={index}>
                                    <div className={`row input-group-text my-2 ${partialDepositForm.methodId == null && partialDepositForm.cardId == card.id ? "metodo-seleccionado" : "metodo" }`} onClick={() => changePartialDepositForm(null, card.id)}>
                                        <div className='col-2'>
                                            <img src={partialDepositForm.methodId === null && partialDepositForm.cardId === card.id ? iconoBilletera : iconoBilleteraPlomo} className='d-block m-auto' width='25' height='25' />
                                        </div>
                                        <div className='col text-start'>
                                            <label className="p-0 m-0" htmlFor={index}>
                                                <p className='my-0'>
                                                    {card.payment_type} {(card.card_info).slice(-6)}
                                                </p>  
                                            </label>
                                        </div>
                                        <div className='col-2'>
                                            <input
                                                className="input-svc-payments form-check-input"
                                                type="radio"
                                                name="cardId"
                                                value={card.id} 
                                                checked={partialDepositForm.methodId == null && partialDepositForm.cardId == card.id}
                                                onChange={handleInputDeposit}
                                            />
                                        </div>
                                    </div>
                                </div> )       
                                
                            })}        
                        </div>
                    </div>
                    <div className='footer-metodo-pago'>
                        <div className='text-center my-4' id='reportar-error-pago'>
                            <span onClick={() => navigate('/reportar-error')}>Reportar error</span>
                        </div>
                        <div className='footer-vending-pago pb-2'>
                            <div className='row pt-4 ms-1 me-1 mb-3 text-center'>
                                <div className='col-5'>
                                    <button type="button" className="btn boton-blanco" onClick={() => navigate('/producto-seleccionado')}>
                                        <img src={flechaNaranja} className='me-3 mb-1' />Volver
                                    </button>
                                </div>
                                <div className='col-7'>
                                    <button className='btn boton-naranja' disabled={ payingWithWallet || (!(partialDepositForm || {}).methodId && !(partialDepositForm || {}).cardId ) }>
                                        {
                                            !payingWithWallet ? (
                                                (watchPaymentMethod instanceof Object && watchPaymentMethod.cardId === null && watchPaymentMethod.paymentMethodId === ((paymentMethods || []).find(method => method?.name?.toLowerCase() === 'wallet')).id) ? (
                                                    (authorization && infoUser) ? (
                                                        wallet.state ? (
                                                            (wallet.balance >= (sale || {}).total) ? "Pagar" : "Cargar Saldo"
                                                        ) : "Crear wallet"
                                                    ) : "Inicie Sesión"
                                                ) : "Pagar"
                                            ) : "Pagando..."
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <button type='button' className='btn d-none' data-bs-toggle="modal" data-bs-target="#modalCambioProducto" id='abrir-modal'></button>
                <button type='button' className='btn d-none' data-bs-toggle="modal" data-bs-target="#modalOneClick" id='abrir-modal-oneclick'></button>
            </div>
            <div className="modal" id="modalCambioProducto" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content mx-auto" id='contenedor-modal-pago'>
                        <div className="modal-body modal-body-color">
                            <div className='row text-center'>
                                <div className='mensaje-modal-cargando mx-auto col-12'>
                                    Cargando...
                                </div>
                                <div className='col-12 mt-2'>
                                    <div className="spinner-grow color-spinner-grow" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                                <button type='button' className='btn' data-bs-dismiss="modal" data-bs-target="#modalCambioProducto" id='cerrar-modal'></button>
                            </div>       
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal" id="modalOneClick" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content mx-auto" id='contenedor-modal-pago'>
                        <div className="modal-body modal-body-color">
                            <div className='row text-center'>
                                <div className='titulo-modal-oneclick mx-auto col-12'>
                                    OneClick
                                </div>
                                <div className='mensaje-modal-oneclick mt-2'>
                                    {authorization ? 'Puedes pagar tus compras con solo un click registrando tu tarjeta con OneClick de Transbank. Regístrala desde tú página de métodos de pago en tu perfil': 'Puedes pagar tus compras con solo un click registrando tu tarjeta con OneClick de Transbank. Crea tu cuenta de usuario y valida tu correo, luego registra una tarjeta desde tú página de métodos de pago en tu perfil'}
                                </div>
                                <div className='botones-modal col-12 mt-3'>
                                    <button type="button" className="btn boton-iniciar-modal" data-bs-dismiss="modal">Cerrar</button>
                                </div>
                                <button type='button' className='btn d-none' data-bs-dismiss="modal" data-bs-target="#modalCambioProducto" id='cerrar-modal-oneclick'></button>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
            <GeneralInfo
            isOpen={isModalOpenGeneralInfo.state}
            toggle={toggleModalGeneralInfo}
            entryData={{
                success: isModalOpenGeneralInfo.success,
                message: isModalOpenGeneralInfo.message
            }}
            />
            <CreateWallet 
                isOpen={isModalOpenCreateWallet} 
                toggle={toggleModalCreateWallet} 
                entryData={{ infoUser }}
                responseData={responseCreateWallet}
            />
            <ChargeMoney
                isOpen={isModalOpenDepositWallet} 
                toggle={toggleModalDepositWallet} 
                entryData={{
                    infoUser,
                    returnURL: encodeURIComponent(`/metodo-pago?sessionId=${sessionID?.sessionId}&deviceId=${localStorage.getItem('deviceID')}`),
                    amount: diffPrice
                }}
                responseData={responseDepositWallet}
            />
        </>
    );
}

export default MetodosDePago;