import '../App.css';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react';
import iconoX from '../Componentes/iconoX.svg'
import iconoOk from '../Componentes/iconoOk.svg'
import { useHttp } from '../Hooks/httpHelpers';
import eyeOpen from '../Utils/img/ojo_1.svg'
import eyeClosed from '../Utils/img/ojo_2.svg'
import { config } from '../Config/config';

function RestaurarContraseña() {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [estado, setEstado] = useState();
    const navigate = useNavigate();
    const http = useHttp();
    let url = window.location.href.includes('tk=');
    const [showPassword, setShowPassword] = useState(false)
    const [showRetryPassword, setShowRetryPassword] = useState(false)

    async function processSubmit(data){
        try {
            setEstado("cargando")
            let tokenPwd = window.location.href.split('?').pop().split('&').find(param => param.startsWith('tk=')).split('=').pop();
            document.getElementById('modal-restablecer-contraseña').click();
            let recuperarContraseña = await http.POST(`${config.get('baseURL')}usuarios/procesarpwd`, { ...data, token: tokenPwd});
            if(recuperarContraseña.data.success !== true) {
                setEstado("fallo")
            } else {
                setEstado("exito")
            }
        } catch (error) {
            navigate('/error');
        }
    }

    useEffect(() => {
        if(!url){
            navigate('/');
        }
    }, [])

    return (
        <div className="RestablecerContraseña background-color">
            <div className='text-center mt-4'>
                <h1 className='titulo-inicio-Sesion fs-4 my-0'>Ingresa tu nueva contraseña</h1>
            </div>
            <div className='container'>
                <form onSubmit={handleSubmit(processSubmit)}>
                    <div className='row mx-3 mt-2'>
                        <span className='input-nueva-contraseña p-0 col-12'>Contraseña (mínimo 8 caracteres)</span>
                        <div className='input-group input-con-borde bg-white col-12'>
                            <input type={showPassword ? "text" : "password"} className="input-con-borde2 border-0 col-11" placeholder='Ingrese su contraseña' {...register("pwd", { required: true, pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/ })} />
                            {errors.pwd?.type === "required" ? (
                                <span className="input-group-text p-0 m-auto col-1 border-0 bg-white"><img src={iconoX} alt='icono error' className='bg-white' /> </span>
                            ):
                            <button type='button' onClick={() => setShowPassword(!showPassword)} className='input-group-text p-0 m-auto col-1 border-0 bg-white eye-icon'>
                                <img src={showPassword ? eyeOpen : eyeClosed} alt='Mostrar/Ocultar contraseña' />
                            </button>
                            }
                        </div>
                        {errors.pwd?.type === "required" && (
                            <span className='input-nueva-contraseña2 p-0 col-12'>Contraseña es requerido</span>
                        )}
                        {errors.pwd?.type === "pattern" && (
                            <span className='input-nueva-contraseña2 p-0 col-12'>La contraseña debe contener mínimo 8 caracteres con al menos una letra minúscula, una mayúscula y un número.</span>
                        )}
                    </div>
                    <div className='row mx-3 mt-4'>
                        <span className='input-nueva-contraseña p-0 col-12'>Repetir Contraseña</span>
                        <div className='input-group input-con-borde bg-white col-12'>
                            <input type={showRetryPassword ? "text" : "password"} className="input-con-borde2 border-0 col-11" placeholder='Ingrese su contraseña' {...register("confirmPassword", {
                                required: true, validate: (val) => {
                                    if (watch('pwd') !== val) {
                                        return "Las contraseñas no coinciden";
                                    }
                                }
                            })} />
                            {errors.pwd?.type === "required" ? (
                                <span className="input-group-text p-0 m-auto col-1 border-0 bg-white"><img src={iconoX} alt='icono error' className='bg-white' /> </span>
                            ):
                            <button type='button' onClick={() => setShowRetryPassword(!showRetryPassword)} className='input-group-text p-0 m-auto col-1 border-0 bg-white eye-icon'>
                                <img src={showRetryPassword ? eyeOpen : eyeClosed} alt='Mostrar/Ocultar contraseña' />
                            </button>
                            }
                        </div>
                        {errors.pwd?.type === "required" && (
                            <span className='input-nueva-contraseña2 p-0 col-12'>Repita su contraseña</span>
                        )}
                        <span className='input-nueva-contraseña2 p-0 col-12' id='no-coincide'>{errors.confirmPassword?.message}</span>
                    </div>
                    <div className='row mx-3 mt-2'>
                        <div className='col-12 text-center'>
                            <button type="submit" className="btn text-white py-2" id='boton-rojo'>
                                Enviar
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <button type='button' className='btn' data-bs-toggle="modal" data-bs-target="#modalRestablecerContraseña" id='modal-restablecer-contraseña'></button>
            <div className="modal" id="modalRestablecerContraseña" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content mx-auto" id='contenedor-modal-pago'>
                        <div className="modal-body modal-body-color">
                            <div className='row text-center'>
                                {estado === "cargando" ?
                                    <div className='row g-0'>
                                        <div className='mensaje-modal-cargando mx-auto col-12'>
                                            Cargando...
                                        </div>
                                        <div className='col-12 mt-2'>
                                            <div className="spinner-grow color-spinner-grow" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                        <button type='button' className='btn' data-bs-dismiss="modal" data-bs-target="#modalMetodosPago" id='cerrar-modal'></button>
                                    </div>
                                : null}
                                {estado === "fallo" ?
                                    <div className='row g-0'>
                                        <div className='icono-modal col-12'>
                                            <img src={iconoX} width="40" height="40" />
                                        </div>
                                        <div className='mensaje-registro-modal my-3 mx-auto col-12'>
                                            No fue posible registrar su nueva contraseña
                                        </div>
                                        <div className='botones-modal col-12'>
                                            <button type="button" className="btn boton-home-modal" data-bs-dismiss="modal" id='cerrar-modal'>Cerrar</button>
                                            <button type="button" className="btn boton-iniciar-modal2" data-bs-dismiss="modal">Reintentar</button>
                                        </div>
                                    </div>
                                : null}
                                {estado === "exito" ?
                                    <div className='row g-0'>
                                        <div className='icono-modal col-12'>
                                            <img src={iconoOk} width="40" height="40" />
                                        </div>
                                        <div className='mensaje-registro-modal my-3 mx-auto col-12'>
                                            Su nueva contraseña ha sido registrada
                                        </div>
                                        <div className='botones-modal col-12'>
                                            <button type="button" className="btn boton-home-modal" data-bs-dismiss="modal" id='cerrar-modal'>Cerrar</button>
                                            <button type="button" className="btn boton-iniciar-modal2" data-bs-dismiss="modal" onClick={() => navigate('/login')}>Iniciar Sesión</button>
                                        </div>
                                    </div>
                                    : null}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RestaurarContraseña;