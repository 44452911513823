import GoogleLoginComponent from "./googleLogin";
import MicrosoftLoginComponent from "./microsoftLogin";
import FacebookLoginComponent from "./FacebookLogin";

function LoginButtons() {

    return (
        <>  
            <div className="mt-2 mb-5 mx-4">
                <h2 className="divider-login"><span className="divider-login-span">O inicia sesión con tú:</span></h2>
            </div>
            <div className='login-buttons-component'>
                {/* <GoogleLoginComponent /> descomentar cuando se verifique aplicacion de Google*/}
                <MicrosoftLoginComponent />
                {/* <FacebookLoginComponent />  descomentar cuando se active aplicacion de facebook*/}
            </div>
        </>
    );
}

export default LoginButtons;