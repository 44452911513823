import '../App.css';
import { useEffect } from 'react';
import vending from '../Componentes/vending.svg'
import pointer from '../Componentes/pointer.svg'
import NavbarProcesoCompra from '../Componentes/navbarProcesoCompra';
import { useNavigate, useLocation } from 'react-router-dom';
import useNavbarStore from '../Contexts/NavBar/navbar';
import useSocketEventStore from '../Contexts/SocketEvent/socketEvent';
import useSesionVMStore from '../Contexts/SesionVM/sesionVM';
import useCancelarSesion from '../Hooks/cancelarSesion';

function SeleccioneUnProducto() {
    const { message, setMessage } = useSocketEventStore();
    const { sessionID } = useSesionVMStore();
    const navigate = useNavigate();
    const location = useLocation();
    const { setNavBarType } = useNavbarStore();
    const cancelarSesion = useCancelarSesion(navigate);

    function verificarProductoState() {
        if (sessionID){
            if (message && message.sale.productSelected) {
                const productoVacio = Object.values(message.sale.productSelected).every(x => x === null || x === '');
                if (productoVacio === false) {
                    setMessage({...message})
                    navigate('/producto-seleccionado');
                } else {
                    setMessage({...message})
                    navigate('/seleccionar');
                }
            } else {
                navigate('/seleccionar')
            }
        } else {
            navigate('/')
        }
    }

    useEffect(() => {
        setNavBarType(3)
        verificarProductoState();
    }, [message])

    window.onpopstate = () => {
        if (location.pathname === '/seleccionar') {
            navigate('/seleccionar')
        }
    }

    const porcentajeBarra = 'barra-progreso-1'

    return (
        <>  
            <NavbarProcesoCompra props={porcentajeBarra} />
            <div className="SeleccioneUnProducto">
                <div className='container'>
                    <h2 className='pasos text-center mt-4'>PASO 1</h2>
                    <h1 className='titulo-pasos text-center mt-2'>Selecciona</h1>
                    <div className='text-center mt-4'>
                        <h3 className='mb-0 texto-pasos'>
                            Selecciona tu producto
                        </h3>
                        <h3 className='texto-pasos2'>
                            en la maquina vending
                        </h3>
                    </div>
                    <div className='Vending d-flex justify-content-center align-items-end mt-5 pb-5'>
                        <img src={vending} />
                        <img src={pointer} className='pointer-vending pointer-vending2' height="45" width="45" />
                    </div>
                </div>
                <div className='footer-vending'>
                    <div className='row pt-4 ms-1 me-1 mb-3 text-center'>
                        <div className='col-5'>
                            <button type="button" className="btn boton-blanco" onClick={cancelarSesion}>Cancelar</button>
                        </div>
                        <div className='col-7'>
                            <button type="button" className="btn boton-naranja " onClick={() => navigate('/reportar-error')}>Reportar error</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SeleccioneUnProducto;
