const GENERAL_STATES = Object.freeze({
    "LOADING": "LOADING",
    "ERROR": "ERROR",
    "FINISH": "FINISH"
});

const STATUS_WALLET = Object.freeze({
    "READY": "READY",
    "NO_EXIST": "NO_EXIST",
    "NO_CREDIT": "NO_CREDIT",
    "DISABLED": "DISABLED"
});

module.exports = {
    GENERAL_STATES,
    STATUS_WALLET
}