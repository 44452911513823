import { useQuery } from "@tanstack/react-query";
import useSesionVMStore from "../Contexts/SesionVM/sesionVM";
import { useHttp } from "./httpHelpers";
import { config } from "../Config/config";
import { useCerrarSesion } from './cerrarSesion';

export const useInfoUsuario = () => {

    const { cerrarSesion } = useCerrarSesion();
    const { GET } = useHttp();
    const { authorization } = useSesionVMStore();

    return useQuery(['infoUsuario'], () => GET(config.get('baseURL') + "usuarios/info"), {
        enabled: !!authorization,
        staleTime: config.get('infoUserRefresh'),
        onSuccess: data => {
            if(!data.data){
                cerrarSesion();
            }
            console.log("Exito al obtener info de usuario", data.data)
        },
        onError: (error) => {
            console.error('Error al obtener info de usuario:', error);
        },
    });
};