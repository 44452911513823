import '../App.css';
import { useEffect, useState } from 'react';
import flechaNaranja from '../Componentes/flechaNaranja.svg'
import { useForm } from "react-hook-form";
import NavbarSvcGris from '../Componentes/navbarSvcGris';
import { useNavigate, Link } from 'react-router-dom';
import iconoX from '../Componentes/iconoX.svg';
import iconoModal from '../Componentes/iconoModal.svg'
import ReCaptchaComponent from '../Componentes/recaptcha';
import useNavbarStore from '../Contexts/NavBar/navbar';
import { useHttp } from '../Hooks/httpHelpers';
import useSesionVMStore from '../Contexts/SesionVM/sesionVM';
import useSocketEventStore from '../Contexts/SocketEvent/socketEvent';
import { useInfoUsuario } from '../Hooks/InfoUsuario';
import useReCaptchaStore from '../Contexts/reCaptcha/reCaptcha';
import { config } from '../Config/config';

function ReportarError() {
    const { sessionID } = useSesionVMStore();
    const { token } = useReCaptchaStore();
    const { message } = useSocketEventStore();
    const navigate = useNavigate();
    const http = useHttp();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { setNavBarType } = useNavbarStore();
    const { data: { data: infoUsuario } } = useInfoUsuario();

    async function processSubmit(data) {
        try {
            let enviarReporteError = await http.POST(config.get('baseURL') + 'comentarios/reportar', infoReporteError(data))
            if (enviarReporteError.data.success) {
                navigate('/reporte-enviado');
            } else {
                document.getElementById('boton-modal-reportar').click();
            }
        } catch (error) {
            console.log(error);
            navigate('/error');
        }
    }

    const infoReporteError = (data) => {
        let reporte = {infoUser: {}}
        reporte.recaptcha = token;
        reporte.typeError = data.typeError;
        if(data.comment) reporte.comment = data.comment;
        if(data.email) reporte.infoUser.email = data.email;
        if(sessionID) reporte.sessionId = sessionID.sessionId;
        if(infoUsuario) {
            reporte.infoUser.names = infoUsuario.names;
            reporte.infoUser.lastname = infoUsuario.lastname;
            reporte.infoUser.email = infoUsuario.email;
            reporte.infoUser.phone = infoUsuario.phone;
        }

        return reporte
    }

    const [error, setError] = useState({
        seleccionado: false,
        errores: ''
    })

    function cambiarSeleccionado(index) {
        setError({ ...error, seleccionado: error.errores[index] })
    }

    async function obtenerListadoErrores() {
        let listaErrores = await http.GET(config.get('baseURL') + 'comentarios/opciones-error')
        if (listaErrores) {
            setError({ ...error, errores: listaErrores.data.data })
        } else {
            setError({
                ...error, errores: {
                    title: 'otro',
                    message: 'otro',
                    id: 1
                }
            })
        }
    }

    async function verificarSesion() {
        if (message && message.state.status === 'ended' || message && message.state.flags.report === true) {
            navigate('/')
        } else {
            obtenerListadoErrores();
        }
    }

    useEffect(() => {
        setNavBarType(3);
        verificarSesion();
    }, [])

    return (
        <>
            <NavbarSvcGris />
            <div className="ReportarError">
                <ReCaptchaComponent/>
                <form onSubmit={handleSubmit(processSubmit)}>
                    <div className='container'>
                        <h2 className='subtitulo-reportar text-center mt-2'>Reportanos un problema</h2>
                        <h1 className='problema-reportar-error text-center mt-2 mb-4'>¿Tuviste un problema con <br /> tu experiencia de compra? </h1>
                        {!infoUsuario ?<div className='row mx-auto mt-4'>
                            <span className='input-nueva-contraseña p-0 col-12'>Correo Electrónico</span>
                            <div className='input-group input-con-borde bg-white col-12 mb-3' >
                                <input type="text" className="input-con-borde2 border-0 col-11" placeholder='ejemplo@correo.com' {...register("email", { required: true, pattern: /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/ })} />
                                {errors.email?.type === "required" && (
                                    <span className="input-group-text p-0 m-auto col-1 border-0 bg-white"><img src={iconoX} alt='icono error' className='bg-white' /> </span>
                                )}
                            </div>
                            {errors.email?.type === "required" && (
                                <span className='input-nueva-contraseña2 p-0 col-12'>Correo electrónico es requerido</span>
                            )}
                            {errors.email?.type === "pattern" && (
                                <span className='input-nueva-contraseña2 p-0 col-12'>Correo electrónico no valido</span>
                            )}
                        </div> : null}
                        <div className='metodos-pago container'>
                            {error.errores ? error.errores.map((err, index) => {
                                if (err.title === 'otro') {
                                    return (
                                        <div className={`row input-group-text my-2 ${error.errores[index] === error.seleccionado ? 'otro-seleccionado' : 'otro'}`} key={index} for={index} onClick={() => cambiarSeleccionado(index)}>
                                            <input className="form-check-input mt-4 mb-2 ms-3 p-0 col-2" type="radio" name="radioMetodos" id={index} value={err.id} {...register("typeError", { required: true })} />
                                            <label className="text-start mt-4 mb-2 col-10 tipo-error" for={index} id='otro-span'>
                                                {err.title}
                                            </label>
                                            <div className='mb-3 p-0 col-12' >
                                                <textarea className={`form-control ${error.errores[index] === error.seleccionado ? "" : "d-none"}`} id="razon-calificacion2" rows="3" {...register("comment", { required: error.seleccionado.title === 'otro' ? true : false })} placeholder='Explícanos brevemente el problema que se presentó'></textarea>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className={`row input-group-text my-2 ${error.errores[index] === error.seleccionado ? 'metodo-seleccionado' : 'metodo'}`} key={index} for={index} onClick={() => cambiarSeleccionado(index)}>
                                            <input className="form-check-input mt-0 ms-3 p-0 col-2" type="radio" name="radioMetodos" id={index} value={err.id} {...register("typeError", { required: true })} />
                                            <label className="text-start col-10 tipo-error" for={index}>
                                                {err.title}
                                            </label>
                                        </div>
                                    )
                                }
                            }) : null}
                        </div>
                    </div>
                    <div className='footer-reportar-error2 pb-1 mt-5'>
                        <div className='row pt-4 ms-1 me-1 pb-2 text-center'>
                            <div className='col-5'>
                                <button type="button" className="btn boton-blanco" id='boton-cambiar-producto' onClick={() => navigate(-1)}>
                                    <img src={flechaNaranja} className='me-3 mb-1' />Volver
                                </button>
                            </div>
                            <div className='col-7'>
                                <button type="submit" className="btn boton-naranja ">Enviar</button>
                            </div>
                        </div>
                        <button type='button' className='btn' data-bs-toggle="modal" data-bs-target="#modalCambioProducto" id='boton-modal-reportar'>
                        </button>
                    </div>
                </form>
            </div>
            {/* modal */}
            <div className="modal fade" id="modalCambioProducto" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content mx-auto" id='contenedor-modal'>
                        <div className="modal-body">
                            <div className='row text-center'>
                                <div className='icono-modal col-12'>
                                    <img src={iconoModal} />
                                </div>
                                <div className='mensaje-modal mt-4 mx-auto col-12'>
                                    Ha ocurrido un problema y no se ha podido enviar el reporte
                                </div>
                                <div className='botones-modal col-12 mt-4'>
                                    <button type="button" className="btn boton-cancelar-modal" data-bs-dismiss="modal" onClick={() => navigate(-1) }>Cancelar</button>
                                    <button type="button" className="btn boton-volver-modal" data-bs-dismiss="modal">Reintentar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportarError;