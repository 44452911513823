import { create } from 'zustand';

const useCurrentStateStore = create((set, get) => ({
  ok: false,
  setOk: (status) => {
    const { ok } = get();
    if (status===ok) return
    set({ ok: status })
  }
}));

export default useCurrentStateStore;