import { useEffect } from 'react';
import '../App.css';
import iconoOk from '../Componentes/iconoOk.svg'
import Spinner from '../Componentes/spinner';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ReCaptchaComponent from '../Componentes/recaptcha';
import useNavbarStore from '../Contexts/NavBar/navbar';
import useCurrentStateStore from '../Contexts/CurrentState/currentState';
import useErrorStore from '../Contexts/Error/error';
import { useHttp } from '../Hooks/httpHelpers';
import useSesionVMStore from '../Contexts/SesionVM/sesionVM';
import useDeviceIDStore from '../Contexts/DeviceId/deviceID';
import { useVerificarStatus } from '../Hooks/verificarStatus';
import useWebSocket from '../Hooks/webSocket';
import useReCaptchaStore from '../Contexts/reCaptcha/reCaptcha';
import { config } from '../Config/config';

function Conectando() {
    //const { estado: { token }} = useValoresConfiguracion();
    const { ok, setOk } = useCurrentStateStore();
    const { setNavBarType } = useNavbarStore();
    const { setError } = useErrorStore();
    const { setSessionID } = useSesionVMStore();
    const { deviceID } = useDeviceIDStore();
    const { token } = useReCaptchaStore();
    const http = useHttp();
    const location = useLocation();
    const navigate = useNavigate();
    const webSocket = useWebSocket(navigate, location);
    const verificarStatus = useVerificarStatus(navigate, location);
    let url = window.location.href.includes('vm=')

    async function obtenerSessionID(props) {
        try {
            let sessionIDObtenido = await http.POST(config.get('baseURL') + 'vm/solicitar', {...props, recaptcha: token})
            if (sessionIDObtenido.status === 200) {
                setOk(true);
                let respuestaSocket = await webSocket.connect(sessionIDObtenido.data.sessionId, deviceID)
                if (!respuestaSocket) {
                    setSessionID();
                    setError({
                        errorTitle: 'Error de Conexión',
                        reason: 'No se pudo crear una conexión entre su dispositivo y la máquina expendedora',
                        report: true,
                        backHome: true
                    });
                    navigate('/error');
                } 
                setSessionID({ sessionId: sessionIDObtenido.data.sessionId });
                navigate('/seleccionar');
            } else if (sessionIDObtenido.data.success === false && sessionIDObtenido.data.sessionId && sessionIDObtenido.data.reloadSession) {
                setSessionID({ sessionId: sessionIDObtenido.sessionId });
                let respuestaSocket = webSocket.connect(sessionIDObtenido.data.sessionId, deviceID)
                if (respuestaSocket) {
                    let estadoSesion = await http.POST(config.get('baseURL') + 'vm/get-session');
                    if(estadoSesion.success === true) {
                        verificarStatus(estadoSesion.session);
                    } else {
                        navigate('/error');
                    }
                } else {
                    setError({
                        errorTitle: 'Error de Conexión',
                        reason: 'No se pudo crear una conexión entre su dispositivo y la máquina expendedora',
                        report: true,
                        backHome: true
                    });
                    navigate('/error');
                    setSessionID();
                }
            } else {
                console.log(sessionIDObtenido);
                if (sessionIDObtenido.response.data) {
                    setError(sessionIDObtenido.response.data)
                } else if (sessionIDObtenido.reason) {
                    setError(sessionIDObtenido)
                } 
                navigate('/error');
                setSessionID();
            }
        } catch (error) {
            setError({
                errorTitle: 'Error de Conexión',
                reason: 'No se pudo crear una conexión entre su dispositivo y la máquina expendedora',
                report: true,
                backHome: true
            });
            navigate('/error')
        }
    }

    /* si se ingresa a conectando mediante el lectorqr o ingresando machine number */
    useEffect(() => {
        setNavBarType(2);
        if (deviceID && !url) {
            obtenerSessionID(location.state.data);
            console.log('se ha ingresado por lectorqr o input')
        } else {
            console.log('se ha ingresado por url')
        }
    }, [])

    /* si se ingresa a conectando desde url */
    useEffect(() => {
        if (deviceID && token) {
            if (url) {
                let machineNumberURL = { machineNumber: window.location.href.split('?').pop().split('&').find(param => param.startsWith('vm=')).split('=').pop() }
                obtenerSessionID(machineNumberURL);
            } else {
                console.log('el url no contiene vm');
            }
        }
    }, [token])

    return (
        <div className={`${ok ? "background-white" : "background-naranja"}`}>
            {!token ? <ReCaptchaComponent /> : null}
            <div className='container text-center'>
                <div className={`col-12 contenedor-img-ok ${ok ? "" : "d-none"}`}>
                    <img src={iconoOk} width="100" height="100" className={`${ok ? "" : "d-none"}`} />
                </div>
                <h1 className={`titulo-conectando mx-auto ${ok ? "transicion-gris" : "transicion-naranja"}`}>
                    {ok ? 'Conexión exitosa' : 'Conectando a máquina'}
                </h1>
                <h2 className={`subtitulo-conectando ${ok ? "d-none" : ""}`}>
                    Espere un momento...
                </h2>
                <div className={`pt-4 loader-container ${ok ? "d-none" : ""}`}>
                    <Spinner />
                </div>
                <div className='container botones-conectando'>
                    <div className={`row ${ok ? "d-none" : ""}`}>
                        <div className='col-6'>
                            <button type="button" className="btn btn-light" id='font-conectando' onClick={() => navigate('/reportar-error')}>Reportar error</button>
                        </div>
                        <div className='col-6'>
                            <button type="button" className="btn text-white ms-2 p-0" id='font-conectando2' onClick={() => navigate('/')}>Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Conectando;