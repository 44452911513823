import { Route, Routes } from 'react-router-dom';
import Home from './Vistas/home';
import InicioDeSesion from './Vistas/inicioDeSesion'
import RestablecerContrasena from './Vistas/restablecerContrasena'
import RestablecerContrasena2 from './Vistas/restablecerContrasena2'
import CrearNuevaContrasena from './Vistas/crearNuevaContrasena'
import Registrarse from './Vistas/registrarse';
import Conectando from './Vistas/conectando';
import Error from './Vistas/error';
import SeleccioneUnProducto from './Vistas/seleccioneUnProducto';
import ProductoSeleccionado from './Vistas/productoSeleccionado';
import MetodosDePago from './Vistas/metodosDePago';
import CalificarExperiencia from './Vistas/calificarExperiencia';
import RetirarProducto from './Vistas/retirarProducto';
import ProductoDespachado from './Vistas/productoDespachado';
import PagoAceptado from './Vistas/pagoAceptado';
import LectorQr from './Vistas/lectorQr';
import CalificacionEnviada from './Vistas/calificacionEnviada';
import ReportarError from './Vistas/reportarError';
import ReporteErrorEnviado from './Vistas/reporteErrorEnviado';
import Ayuda from './Vistas/ayuda';
import InfoAyuda from './Vistas/infoAyuda';
import ReiniciarSesion from './Vistas/reiniciarSesion';
import CuentaUsuario from './Vistas/cuentaUsuario';
import EditarCuenta from './Vistas/editarCuenta';
import EditarCuenta2 from './Vistas/editarCuenta2';
import ValidarEmail from './Vistas/validacionEmail';
import HistorialPago from './Vistas/historialPago';
import PagoRealizado from './Vistas/pagoRealizado';
import MetodosPagoRegistrados from './Vistas/metodosPagoRegistrados';
import RestaurarContraseña from './Vistas/restaurarContraseña';
import SuccessPayments from './Vistas/successPayments';
import ErrorPayments from './Vistas/errorPayments.js';
import UnconfirmedPayments from './Vistas/unconfirmedPayments';
import CuentaPorActivar from './Vistas/cuentaPorActivar';
import ListMovements from './Vistas/listMovements';

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<InicioDeSesion />} />
            <Route path="/restablecer-password" element={<RestablecerContrasena />} />
            <Route path="/restablecer-password2" element={<RestablecerContrasena2 />} />
            <Route path="/crear-password" element={<CrearNuevaContrasena />} />
            <Route path="/registro" element={<Registrarse />} />
            <Route path="/conectando" element={<Conectando />} />
            <Route path="/error" element={<Error />} />
            <Route path="/seleccionar" element={<SeleccioneUnProducto />} />
            <Route path="/producto-seleccionado" element={<ProductoSeleccionado />} />
            <Route path="/metodo-pago" element={<MetodosDePago />} />
            <Route path="/retirar-producto" element={<RetirarProducto />} />
            <Route path="/despacho-producto" element={<ProductoDespachado />} />
            <Route path="/pago-aceptado" element={<PagoAceptado />} />
            <Route path="/lector-qr" element={<LectorQr />} />
            <Route path="/calificar-experiencia" element={<CalificarExperiencia />} />
            <Route path="/calificacion-enviada" element={<CalificacionEnviada />} />
            <Route path="/reportar-error" element={<ReportarError />} />
            <Route path="/reporte-enviado" element={<ReporteErrorEnviado />} />
            <Route path="/lista-ayuda" element={<Ayuda />} />
            <Route path="/info-ayuda" element={<InfoAyuda />} />
            <Route path="/restaurar-sesion" element={<ReiniciarSesion />} />
            <Route path="/cuenta" element={<CuentaUsuario />} />
            <Route path="/editar-cuenta" element={<EditarCuenta />} />
            <Route path="/editar" element={<EditarCuenta2 />} />
            <Route path="/historial-pago" element={<HistorialPago />} />
            <Route path="/pago-realizado" element={<PagoRealizado />} />
            <Route path="/validate" element={<ValidarEmail />} />
            <Route path="/metodos-pago" element={<MetodosPagoRegistrados />} />
            <Route path="/restore" element={<RestaurarContraseña />} />
            <Route path="/payment/success" element={<SuccessPayments />} />
            <Route path="/payment/error" element={<ErrorPayments />} />
            <Route path="/payment/unconfirmed" element={<UnconfirmedPayments />} />
            <Route path="/activar-cuenta" element={<CuentaPorActivar />} />
            <Route path="/list-movements" element={<ListMovements />} />
        </Routes>
    );
};

export default AppRoutes;