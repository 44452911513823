import '../App.css';
import ContenedorSuperior from '../Componentes/contenedorSuperior';
import NoPuedesEscanear from '../Componentes/noPuedesEscanear';
import { useEffect } from 'react';
import useNavbarStore from '../Contexts/NavBar/navbar';
import useCurrentStateStore from '../Contexts/CurrentState/currentState';
import useSesionVMStore from '../Contexts/SesionVM/sesionVM';
import useSocketEventStore from '../Contexts/SocketEvent/socketEvent';
import { useFinalizarSesionVM } from '../Hooks/finalizarSesionVM';
import axios from 'axios';

function Home() {

  //TODO preguntar al usuario si quiere finalizar su sesion, si el usuario tiene sessionID en memoria cada vez que entre a home
  const { sessionID, authorization, infoUsuario, setAuthorization } = useSesionVMStore();
  const { message } = useSocketEventStore();
  const { setNavBarType } = useNavbarStore();
  const { setOk } = useCurrentStateStore();
  let url = window.location.href;
  const { finalizarSesion } = useFinalizarSesionVM();
  
  useEffect(() => {
    setNavBarType(1);
    setOk(false);
    if (message || sessionID) {
      finalizarSesion();
    }
  }, [message])

  useEffect(() => {
    if (url.includes('tk=') && !authorization && !infoUsuario) {
      const tk = url.split('?').pop().split('&').find(param => param.startsWith('tk=')).split('=').pop();
      setAuthorization(tk)
    }
  }, [authorization])

  return (
    <div className="Home">
      <div className='contenedor-superior' id='fondo'>
        <ContenedorSuperior />
      </div>
      <div className='contenedor-inferior pt-3'>
        <NoPuedesEscanear />
      </div>
    </div>
  );
}

export default Home;