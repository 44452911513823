import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

const useSocketEventStore = create(
    persist(
      (set) => ({
        message: null,
        sale: null,
        setMessage: (messageData) => {
            set({ message: messageData });
        },
        setSale: (saleData) => {
            if (!saleData || saleData === null) return;
            set({ sale: saleData });
        }
      }),
      {
        name: 'socketEventStore',
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  );
  
export default useSocketEventStore;
  
