const FormatoSession = {
    "sessionId": "string",
    "deviceId": "string",
    "active": "boolean",
    "stateTs": "number",
    "report": "boolean",
    "experience": "boolean",
    "state": {
        "status": "string",
        "ts": "number",
        "message": "string",
        "error": "number",
        "flags": {
            "retry": "boolean",
            "cancel": "boolean",
            "home": "boolean",
            "report": "boolean"
        }
    },
    "userInfo": {
        "id": "number",
        "username": "string",
        "names": "string",
        "email": "string",
        "phone": "number"
    },
    "vmInfo": {
        "id": "number",
        "number": "number",
        "code": "string",
        "qr": "string",
        "region": "number",
        "country": "number",
        "address": "string",
        "comment": "string",
        "state": "string",
        "productSelected": {
            "id": "number",
            "slot": "number",
            "name": "string",
            "price": "number",
            "description": "string",
            "imgUrl": "string"
        }
    },
    "sale": {
        "buyOrder": "string",
        "saleOrder": "string",
        "ts": "number",
        "payment": {
            "type": "string",
            "token": "string",
            "returnUrl": "string",
            "response": "string",
            "done": "boolean"
        },
        "amount": "number",
        "discount": "number",
        "total": "number",
        "tax": "number",
        "discountId": "number",
        "productSelected": {
            "id": "number",
            "slot": "number",
            "name": "string",
            "price": "number",
            "description": "string",
            "imgUrl": "string",
            "ts": "number"
        }
    }
}

export default FormatoSession;