import '../App.css';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import iconoX from '../Componentes/iconoX.svg'
import iconoLupa from '../Componentes/iconoLupa.svg'
import iconoVerificar from '../Componentes/iconoVerificar.svg'
import iconoModal from '../Componentes/iconoModal.svg'
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from 'react-router-dom';
import ReCaptchaComponent from '../Componentes/recaptcha';
import useNavbarStore from '../Contexts/NavBar/navbar';
import useSocketEventStore from '../Contexts/SocketEvent/socketEvent';
import { useHttp } from '../Hooks/httpHelpers';
import useSesionVMStore from '../Contexts/SesionVM/sesionVM';
import useCancelarSesion from '../Hooks/cancelarSesion';
import useReCaptchaStore from '../Contexts/reCaptcha/reCaptcha';
import { config } from '../Config/config';

function ProductoSeleccionado() {
    const { sessionID } = useSesionVMStore();
    const { token } = useReCaptchaStore();
    const { sale } = useSocketEventStore();
    const http = useHttp();
    const { setNavBarType } = useNavbarStore();
    const navigate = useNavigate();
    const location = useLocation();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const cancelarSesion = useCancelarSesion(navigate);

    async function processSubmit(data) {
        /* aplicar cupon mediante codigo */
        let usarCupon = await http.POST(config.get('baseURL') + 'pago/cupon', { cupon: data.cupon, recaptcha: token })
        return usarCupon
    }
    async function aplicarCuponSeleccionado() {
        if (misCupones.seleccionado) {
            let usarCupon = await http.POST(config.get('baseURL') + 'pago/cupon', { cupon: misCupones.seleccionado.codigo, recaptcha: token })
            return usarCupon
        }
    }
    /* Estructura de cupones endpoint */
    const [misCupones, setMisCupones] = useState({
        seleccionado: null,
        cupones: null
    })

    function cambiarSeleccionado(index) {
        setMisCupones({ ...misCupones, seleccionado: misCupones.cupones[index] });
    }

    async function obtenerCupones() {
        try {
            let cuponesDisponibles = await http.GET(config.get('baseURL') + 'pago/cupon');
            console.log(cuponesDisponibles.data.cupones)
            setMisCupones({ ...misCupones, cupones: cuponesDisponibles.data.cupones })
            return true
        } catch (error) {
            console.error(error)
            return false
        }

    }

    window.onpopstate = () => {
        if (location.pathname === '/producto-seleccionado') {
            navigate('/producto-seleccionado')
        }
    }

    useEffect(() => {
        if(sessionID){
            aplicarCuponSeleccionado();
        }
    }, [misCupones.seleccionado])

    useEffect(() => {
        if(sessionID){
            setNavBarType(4);
            obtenerCupones();
        } else {
            navigate('/')
        }
    }, [sale])

    return (
        <div className="ProductoSeleccionado">
            <ReCaptchaComponent />
            <div className='container img-producto' style={{ backgroundImage: `url(${sale ? sale.productSelected.imgUrl : null})` }}>
                <button type='button' className='btn border-0 shadow-none p-0' data-bs-toggle="modal" data-bs-target="#modalCambioProducto2" id='boton-cambiar-producto'>
                    <img src={iconoX} width='25px' height='25px' alt='icono error' className='cancelar' />
                </button>
            </div>
            <div className='info-producto'>
                <h1 className='titulo-producto pt-4 mx-3'> {sale ? sale.productSelected.name : null} </h1>
                <div className='row mx-3' id='row-producto'>
                    <div className='col-2 precio p-0'>
                        Precio
                    </div>
                    <div className='col-2 precio-numero p-0 lh-1'>
                        {sale ? `$${sale.total}` : null}
                        <br />
                        {sale ? <span className='precio-descuento ms-1'> {sale.productSelected.price !== sale.total ? '$' + sale.productSelected.price : null}</span> : null}
                    </div>
                    <div className='col-8 text-end p-0'>
                        <button type='button' className='btn' data-bs-toggle="modal" data-bs-target="#modalCambioProducto" id='boton-cambiar-producto'>
                            Cambiar producto
                        </button>
                    </div>
                </div>
                <form name='codigo' onSubmit={handleSubmit(processSubmit)}>
                    <div className='cupones mt-2 mx-3'>
                        <div className="separador"><h2 className='promociones-descuentos mb-0'>Promociones y descuentos</h2></div>
                        <div className="input-group input-descuento mb-2">
                            <span className="input-group-text border-0 p-0 bg-white ms-2"><img src={iconoLupa} /></span>
                            <input type="text" className="form-control border-0 border-start-0 shadow-0" placeholder='Inserta código promoción' id='input-cupon' {...register("cupon", { required: true })} />
                            <button type='submit' className="input-group-text bg-naranja"><img src={iconoVerificar} width='20px' /></button>
                        </div>
                        {errors.cupon?.type === "required" && (
                            <span className='input-span mt-0'>Código no válido</span>
                        )}
                    </div>
                </form>
                <div className='mt-3 ms-3 me-3 scroller-cupones snaps-cupon'>
                    {misCupones.cupones && misCupones.cupones.length ? misCupones.cupones.map((cupon, index) => {
                        const fecha = new Date(cupon.fecha_expiracion);
                        const fechaFinal = `${fecha.getDate()}-${('0' + (fecha.getMonth()+1)).slice(-2)}-${fecha.getFullYear()}`;
                        return <div className={
                            misCupones.cupones[index].id &&
                                misCupones.cupones[index].id === (misCupones.seleccionado || {}).id ?
                                'cupon-seleccionado' : 'cupon'} key={index} onClick={() => cambiarSeleccionado(index)} >
                            <div className='nombre-cupon'>
                                {cupon.codigo}
                            </div>
                            <div className='descuento-cupon'>
                                <span className='precio-numero'>{cupon.tipo === 'amou' ? '$' : null}{cupon.descuento}{cupon.tipo === 'perc' ? '%' : null}</span> de descuento
                            </div>
                            <div className='validez-cupon'>
                                Úsalo antes del {fechaFinal}
                            </div>
                        </div>
                    }) : null}
                </div>
            </div>
            <div className='contenedor-inferior-producto'>
                <div className='problema text-center bg-white my-3'>
                    <span>¿Tienes un problema?</span>
                </div>
                <div className='pagar'>
                    <div className='row mx-3 py-2'>
                        <div className='total-pagar col-6 text-center'>
                            Total a pagar
                            <br />
                            <span className='col-6'>${sale?.total}</span>
                        </div>
                        <div className='col-6'>
                            <Link to="/metodo-pago" className='text-decoration-none text-white'>
                                <button className='btn boton-pago'>Pagar</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* modal */}
            <div className="modal fade" id="modalCambioProducto" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content mx-auto" id='contenedor-modal'>
                        <div className="modal-body">
                            <div className='row text-center'>
                                <div className='icono-modal col-12'>
                                    <img src={iconoModal} />
                                </div>
                                <div className='mensaje-modal mt-4 mx-auto col-12'>
                                    Para cambiar tu producto, selecciona uno nuevo en la
                                    maquina vending
                                </div>
                                <div className='botones-modal col-12 mt-4'>
                                    <button type="button" className="btn boton-cancelar-modal" data-bs-dismiss="modal">Cancelar</button>
                                    <button type="button" className="btn boton-volver-modal" data-bs-dismiss="modal">Volver</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="modalCambioProducto2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content mx-auto" id='contenedor-modal'>
                        <div className="modal-body">
                            <div className='row text-center'>
                                <div className='icono-modal col-12'>
                                    <img src={iconoModal} />
                                </div>
                                <div className='mensaje-modal mt-4 mx-auto col-12'>
                                    ¿Desea Cancelar su compra?
                                </div>
                                <div className='botones-modal col-12 mt-4'>
                                    <button type="button" className="btn boton-cancelar-modal" data-bs-dismiss="modal">Cancelar</button>
                                    <button type="button" className="btn boton-volver-modal" data-bs-dismiss="modal" onClick={cancelarSesion}>Si</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductoSeleccionado;
