import { useEffect, useState } from 'react';
import '../App.css';
import { useNavigate } from 'react-router-dom'
import flechaNaranja from '../Componentes/flechaNaranja.svg'
import iconoX from '../Componentes/iconoX.svg'
import iconoTarjeta from '../Componentes/iconoTarjeta.svg'
import iconoPregunta from '../Componentes/iconoPregunta.svg'
import iconoOk from '../Componentes/iconoOk.svg'
import jwt_decode from "jwt-decode";
import Wallet from './wallet';
import useNavbarStore from '../Contexts/NavBar/navbar';
import { useHttp } from '../Hooks/httpHelpers';
import useSesionVMStore from '../Contexts/SesionVM/sesionVM';
import useErrorStore from '../Contexts/Error/error';
import { config } from '../Config/config';

import useLoadingModalStore from "../Contexts/loadingModal/loadingModal";
import { useInfoUsuario } from "../Hooks/InfoUsuario";

function MetodosPagoRegistrados() {
    const { authorization  } = useSesionVMStore();
    const { setError } = useErrorStore();
    const [verificarJwt, setVerificarJwt] = useState();
    const [cargando, setCargando] = useState();
    const [eliminado, setEliminado] = useState();
    const { setNavBarType } = useNavbarStore();
    const navigate = useNavigate();
    const http = useHttp();

    const loadingModal = useLoadingModalStore((state) => ({ open: state.open, close: state.close}));
    const userInfoResponse = useInfoUsuario();
    const infoUser = userInfoResponse?.data?.data;
    const [wallet, setWallet]= useState("LOADING"); 
    const [misTarjetas, setMisTarjetas] = useState({
        seleccionado: null,
        tarjetas: []
    });
    const [loadWallet, setLoadWallet] = useState(false);
    const responseWallet = (res) => {
        console.debug("que me respondio la walletsita", res);
        setWallet(res);
    };

    function respuestaJWT() {
        try {
            obtenerTarjetas();
            let jwt = (window.location.href.split('t=')).pop();
            let construirJwt = jwt.split('.')[0];
            let jwtDecoded = jwt_decode(construirJwt, { header: true });
            if(jwtDecoded.success && jwtDecoded.success==true){
                setVerificarJwt(true);
                document.getElementById('modal-metodos-registrados').click();
            } else {
                setError({
                    errorTitle: 'No pudimos registrar su método de pago',
                    reason: jwtDecoded.message || 'Ha ocurrido un error mientras registrabamos su metodo de pago',
                    report: true,
                    backHome: true
                });
                navigate('/error');
            }
        } catch (error) {
            navigate('/error');
        }
    }

    async function registrarTarjeta() {
        try {
            loadingModal.open("Iniciando registro tarjeta...");
            let TBK = await http.GET(config.get('baseURL') + 'tarjeta/iniciar');
            if(TBK && TBK.data.url_webpay && TBK.data.token){
                window.location.href = `${TBK.data.url_webpay}?TBK_TOKEN=${TBK.data.token}`;
            } else {
                setError({
                    errorTitle: 'No pudimos registrar su metodo de pago',
                    reason: TBK.data.message || 'No fue posible iniciar el registro de su método de pago',
                    report: true,
                    backHome: true
                });
                navigate('/error');
            }
        } catch (error) {
            let message = "No es posible iniciar su registro de tarjeta"
            if(error && error.response && error.response.data && error.response.data.message){
                message = error.response.data.message
            }
            setError({
                errorTitle: 'No pudimos registrar su metodo de pago',
                reason: message,
                report: true,
                backHome: true
            });
            navigate('/error');
        }
    }

    function cambiarSeleccionado(index) {
        setMisTarjetas({ ...misTarjetas, seleccionado: misTarjetas.tarjetas[index] });
    }

    async function obtenerTarjetas(modal) {
        try {
            loadingModal.open();
            let tarjetas = await http.GET(config.get('baseURL') + 'tarjeta/listar');
            if(tarjetas && tarjetas.data.success === true){
                setMisTarjetas({ seleccionado: tarjetas.data.body[0], tarjetas: tarjetas.data.body });
                loadingModal.close();
            } else {
                //document.getElement
                loadingModal.close();
                
                setError({
                    errorTitle: 'Error al obtener sus métodos de pago',
                    reason: 'No fue posible obtener sus métodos de pago',
                    report: true,
                    backHome: true
                });
                navigate('/error');
            }     
        } catch (error) {
            //document.getElementById('cerrar-modal').click();
            navigate('/error')
        }
    }

    async function eliminarTarjeta() {
        try {
            setCargando(true);
            let tarjetaEliminada = await http.DELETE(config.get('baseURL') + `tarjeta/eliminar?tarjeta_id=${misTarjetas.seleccionado.id}`);
            if(tarjetaEliminada){
                setCargando();
                if(!cargando){
                    setEliminado(true);
                }
            }
        } catch (error) {
            navigate('/error');
        }
    }

    useEffect(() => {
        try {
            const myPromise = new Promise(async (resolve, reject) => {
                console.debug("cuantas veces se ejecuta");
                //loadingModal.open();     
                setNavBarType(1);
                if(!authorization) return navigate('/');
                const params = new URLSearchParams(window.location.search);
                const token = params.get('t');
                if(typeof token === 'string' && token.length > 0) return await respuestaJWT();
                await obtenerTarjetas(true);

                resolve();
              });


            myPromise.finally(() => {
                setLoadWallet(true);
            });
            
        } catch (error) {

        }
    }, [])

    return (
        <div className="MetodosPagoRegistrados pt-4">
            <div className='mx-4'>
                <div className='row'>
                    <h1 className='titulo-tarjetas col-8'>Tarjetas</h1>
                    <button className='btn col-4 btn-agregar' onClick={() => registrarTarjeta()}>
                        Agregar
                    </button>
                </div>
            </div>
            <div>
    </div>
            {misTarjetas.tarjetas.length ? <div className='mt-3 ms-4 me-4 scroller-tarjetas snaps-tarjeta'>
                {misTarjetas.tarjetas.map((tarjeta, index) => {
                    return <div className={
                        misTarjetas.tarjetas[index].id &&
                            misTarjetas.tarjetas[index].id === (misTarjetas.seleccionado || {}).id ?
                            'tarjeta-seleccionada contenedor-tarjeta' : 'tarjeta contenedor-tarjeta'} key={index} onClick={() => cambiarSeleccionado(index)} >
                        <div className='upper-tarjeta'>
                            <img src={iconoTarjeta} className='icono-tarjeta'/>
                            <div className='tipo-tarjeta'>{tarjeta.payment_type}</div>
                        </div>
                        <div className='numero-tarjeta'>
                            <div className='row'>
                                <div className='col-2'>****</div>
                                <div className='col-2'>****</div>
                                <div className='col-2'>****</div>
                                <div className='col-2'>{tarjeta.card_info.slice(-4)}</div>
                            </div>
                        </div>
                    </div>
                })}
            </div> : <div className='text-center mt-5'><span>No existen métodos de pago registrados</span></div>}
            <div className='info-tarjeta vstack mx-4 mt-4'>
                {misTarjetas.tarjetas.length ? <div className='etiqueta-info-tarjeta' >Información de tarjeta</div> : null}
                <div className='tipo-tarjeta2'> {misTarjetas.seleccionado ? misTarjetas.seleccionado.payment_type : null} </div>
            </div>
            <div className='p-4'>
                {loadWallet  && <Wallet infoUser={infoUser}/>}
            </div>
            
            <div className='p-3'>
                <div className=' m-4 py-4' data-bs-toggle="modal" data-bs-target="#modalMetodosPago" id='modal-metodos-registrados'>
                    <img src={iconoX} className='me-4' /><span className='eliminar-tarjetas-font'>Eliminar método de pago</span>
                </div>
                <div className='volver-tarjetas mx-4 my-5'>
                    <button className='btn p-0 boton-volver-tarjetas' onClick={() => navigate('/cuenta')}>
                        <img src={flechaNaranja} className='me-3 my-auto' /> <span className='volver-tarjetas-font'>Volver</span>
                    </button>
                </div>
            </div>
            <div className="modal" id="modalMetodosPago" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content mx-auto" id='contenedor-modal-pago'>
                        <div className="modal-body modal-body-color">
                            <div className='row text-center'>
                                {cargando ?
                                    <div className='row g-0'>
                                        <div className='mensaje-modal-cargando mx-auto col-12'>
                                            Cargando...
                                        </div>
                                        <div className='col-12 mt-2'>
                                            <div className="spinner-grow color-spinner-grow" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                        <button type='button' className='btn' data-bs-dismiss="modal" data-bs-target="#modalMetodosPago" id='cerrar-modal'></button>
                                    </div>
                                : null}
                                {!cargando ? 
                                    <div className='row g-0'>
                                    <div className='icono-modal col-12'>
                                        {!eliminado ? <img src={!verificarJwt ? iconoPregunta : iconoOk} width="40" height="40" /> : <img src={iconoOk} width="40" height="40" />}
                                    </div>
                                    {!eliminado ? <div className='mensaje-registro-modal my-3 mx-auto col-12'>
                                        {!verificarJwt ? '¿Quiere eliminar este método de pago?' : 'Su método de pago fue registrado exitosamente'}
                                        <br />
                                        {!verificarJwt ? <div className='mt-1' id='info-tarjeta-eliminar'>
                                            {misTarjetas.seleccionado ? misTarjetas.seleccionado.payment_type + ' ' + misTarjetas.seleccionado.card_info.slice(-4) : null}
                                        </div> : null}
                                    </div> : <div className='mensaje-registro-modal my-3 mx-auto col-12'>
                                        Método de pago eliminado exitosamente
                                    </div>}
                                    {!eliminado ? <div className='botones-modal col-12'>
                                        <button type="button" className="btn boton-home-modal" data-bs-dismiss="modal" onClick={() => verificarJwt ? setVerificarJwt() : null}>{!verificarJwt ? 'Cancelar' : 'Cerrar'}</button>
                                        {!verificarJwt ? <button type="button" className="btn boton-iniciar-modal2" onClick={() => eliminarTarjeta()}>Eliminar</button> :
                                            <button type="button" className="btn boton-iniciar-modal" data-bs-dismiss="modal" onClick={() => setVerificarJwt()}>Ok</button>
                                        }
                                    </div> : <div className='botones-modal col-12'>
                                        <button type="button" className="btn boton-home-modal" data-bs-dismiss="modal" onClick={() => {obtenerTarjetas(true); setEliminado()}}>Cerrar</button>
                                        <button type="button" className="btn boton-iniciar-modal2" data-bs-dismiss="modal" onClick={() => {obtenerTarjetas(true); setEliminado()}}>Ok</button>
                                    </div>}
                                </div> 
                                : null }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MetodosPagoRegistrados;